/* @flow */

import * as React from 'react';
import * as ramda from 'ramda';
import _ from 'underscore';

import {RelativeOverlay} from '../relative-overlay';
import {ListViewInfiniteLoading} from './list-view-infinite-loading';
import {type SpecialState} from './list-view';
import {renderSpecialState} from './helpers';

import './list-view-paginated.css';

type Props<M> = {|
    collection: M[],
    specialStates: SpecialState[],
    isLoading?: boolean,
    isUpdating?: boolean,
    renderRow: (item: M, index: number) => React.Node,
    getListViewRef?: (?HTMLElement) => void,
    hasNextPage: boolean,
    onFetchMoreRows: () => any,
    itemSpacing?: 8 | 16,
    listPadding?: 8 | 16,
    invertScrolling?: boolean,
|};

const findSpecialState = ramda.find((state: SpecialState) => {
    return state.shouldRender();
});

const LOAD_MORE_PIXEL_THRESHOLD = 250;

/**
 * This component has been deprecated in favor of ListViewPaginated, a functional component.
 * Keeping this around until the new version is better tested, but try to reach for that one!
 */
export class ListViewPaginatedDeprecated<M> extends React.Component<Props<M>, void> {
    listRef: ?HTMLElement;
    scrollHeightRef: number;
    scrollTopRef: number;

    static defaultProps = {
        collection: [],
        specialStates: [],
    };

    componentDidMount() {
        if (this.listRef) {
            this.listRef.addEventListener('scroll', this.handleScroll);
        }
    }

    componentDidUpdate(prevProps: Props<M>) {
        // first render with items should scroll to bottom
        if (
            this.props.invertScrolling &&
            this.listRef &&
            prevProps.collection.length === 0 &&
            this.props.collection.length > 0
        ) {
            this.listRef.scrollTo(0, 10000);

            return;
        }

        if (
            prevProps.collection.length !== this.props.collection.length &&
            this.listRef &&
            this.scrollTopRef !== undefined &&
            this.scrollHeightRef !== undefined &&
            this.scrollHeightRef !== this.listRef.scrollHeight
        ) {
            this.listRef.scrollTo(
                0,
                this.scrollTopRef + this.listRef.scrollHeight - this.scrollHeightRef
            );
        }
    }

    componentWillUnmount() {
        if (this.listRef) {
            this.listRef.removeEventListener('scroll', this.handleScroll);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div ref={this.setRefCallback} styleName='list-view-paginated'>
                    {this.renderBody()}
                </div>
                {this.props.isUpdating ? (
                    <RelativeOverlay>
                        <ListViewInfiniteLoading />
                    </RelativeOverlay>
                ) : undefined}
            </React.Fragment>
        );
    }

    setRefCallback = (listRef: ?HTMLElement) => {
        this.listRef = listRef;

        if (this.props.getListViewRef) {
            this.props.getListViewRef(listRef);
        }
    };

    handleScroll = _.debounce((e: Event) => {
        // $FlowIgnore these _do_ exist
        const {scrollHeight, offsetHeight, scrollTop} = e.target;

        this.scrollHeightRef = scrollHeight;
        this.scrollTopRef = scrollTop;

        if (
            this.props.invertScrolling &&
            scrollTop < LOAD_MORE_PIXEL_THRESHOLD &&
            this.props.hasNextPage &&
            !this.props.isLoading
        ) {
            this.props.onFetchMoreRows();
        }

        if (
            !this.props.invertScrolling &&
            scrollHeight - offsetHeight < scrollTop + LOAD_MORE_PIXEL_THRESHOLD &&
            this.props.hasNextPage &&
            !this.props.isLoading
        ) {
            this.props.onFetchMoreRows();
        }
    }, 100);

    renderBody = () => {
        const list = (
            <ul className={this.props.listPadding ? `pad-${this.props.listPadding}` : undefined}>
                {this.props.collection.map((item, index) => {
                    return (
                        <li
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={
                                this.props.itemSpacing && index !== this.props.collection.length - 1
                                    ? `mb-${this.props.itemSpacing}`
                                    : undefined
                            }
                        >
                            {this.props.renderRow(item, index)}
                        </li>
                    );
                })}
                {!this.props.invertScrolling && (this.props.isLoading || this.props.hasNextPage) ? (
                    <ListViewInfiniteLoading />
                ) : undefined}
            </ul>
        );

        const specialState = findSpecialState(this.props.specialStates);

        return specialState ? renderSpecialState(specialState) : list;
    };
}
