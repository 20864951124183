/* @flow */

import * as React from 'react';
import './inline-tabs.css';

export type Props = {
    name: string,
    selectedTab: string,
    // $FlowFixMe upgrading Flow to v0.110.1
    onChange: (newValue: $Subtype<string>) => void,
    isDark?: boolean,
    tabMinWidth?: number,
    children: React.Node,
};

// $FlowIgnore it doesn't know about context yet
export const InlineTabsContext = React.createContext('inlineTabs');

export class InlineTabs extends React.Component<Props> {
    render() {
        const {name, onChange, selectedTab, isDark, tabMinWidth} = this.props;

        return (
            <InlineTabsContext.Provider value={{name, onChange, selectedTab, isDark, tabMinWidth}}>
                <div styleName='inline-tabs' role='tablist'>
                    {this.props.children}
                </div>
            </InlineTabsContext.Provider>
        );
    }
}
