/* @flow */

import * as React from 'react';

import {getRemoveConfirmationTextFromChannel} from 'nutshell-core/channels/helpers';

import type {ChannelType} from '../../channels/types';
import {SectionItem} from '../section-item';
import {ChannelIconWithName} from '../../channels';
import {SourcesSectionContent} from './sources-section-content';
import type {Source} from './types';

type Props = {|
    channel: ?ChannelType,
    sources: Source[],
    sourceOptions: Source[],
    onRemoveChannel: (channel: number) => Promise<*>,
    onRemoveSource: (sourceId: string) => any,
    onAddSource: (sourceId: string) => any,
    canAccessSetup: boolean,
|};

export function SourcesSection(props: Props) {
    const {
        channel,
        sources,
        sourceOptions,
        onAddSource,
        onRemoveSource,
        onRemoveChannel,
        canAccessSetup,
    } = props;

    return (
        <SectionItem
            sectionEntity='sources'
            itemHeaderComponent={<ChannelIconWithName channel={channel} />}
            content={
                <SourcesSectionContent
                    channel={channel}
                    sources={sources}
                    sourceOptions={sourceOptions}
                    onAddSource={onAddSource}
                    onRemoveSource={onRemoveSource}
                    canAccessSetup={canAccessSetup}
                />
            }
            removeConfirmationText={getRemoveConfirmationTextFromChannel(channel)}
            onClickRemove={onRemoveChannel}
        />
    );
}
