/* @flow */

import * as React from 'react';
import type {CoreEntityType} from 'nutshell-core/types';

import getClassesMixin from '../../mixins/get-classes';
import InputAjaxSearch from '../../base/input-ajax-search';
import {SearchResultItem} from './search-result-item';
import {SearchHeader} from './search-header';
import {SearchResultsEmptyView} from './empty-view';

type Props = {
    placeholder?: string,
    focusOnMount?: boolean,
    updateQueryOnSelect?: boolean,
    onSelect: (?Object) => any,
    types?: CoreEntityType[],
    hierarchyTypeFilter?: string,
};

export class EntitySearch extends React.Component<Props> {
    getClasses = getClassesMixin;

    render() {
        const classes = this.getClasses('ui-entity-search');

        return (
            <div className={classes}>
                <InputAjaxSearch
                    placeholder={this.props.placeholder}
                    popoverClass='entity'
                    header={SearchHeader}
                    emptyView={SearchResultsEmptyView}
                    focusOnMount={this.props.focusOnMount}
                    updateQueryOnSelect={this.props.updateQueryOnSelect}
                    onSelect={this.handleSelect}
                    renderRow={(result, highlighted) =>
                        this.renderSearchResultRow(result, highlighted)
                    }
                    search={(q, callback) => this.search(q, callback)}
                />
            </div>
        );
    }

    search(queryString: string, callback: (Object[]) => any) {
        const resultsCollection = new Nut.Model.SearchResultsCollection({
            q: queryString,
            entityTypes: this.props.types,
            hierarchyType: this.props.hierarchyTypeFilter,
        });

        resultsCollection.fetch().done(() => callback(resultsCollection.models));
    }

    renderSearchResultRow(result: Object, isHighlighted: boolean) {
        return (
            <SearchResultItem key={result.id} searchResult={result} isSelected={isHighlighted} />
        );
    }

    handleSelect = (e: Event, result: Object) => {
        e.preventDefault();
        this.props.onSelect(result);
    };
}
