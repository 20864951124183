/* @flow */

import * as React from 'react';

import {Popover} from 'shells/popover';
import {Body} from 'shells/typography';

import './info-popover.css';

type Props = {
    text: string,
    anchor: ?HTMLElement,
};

export function InfoPopover(props: Props) {
    return (
        <Popover anchor={props.anchor} location='top' bodyColor='dark'>
            <div styleName='popover-content'>
                <Body size='small' color='white'>
                    {props.text}
                </Body>
            </div>
        </Popover>
    );
}
