/* @flow */

import * as React from 'react';

import {FormattedTimestamp} from 'shells/timestamp';

import './status-bar.css';

export type Props = {
    datetime: ?number,
    prefix: string,
    emptyText: string,
};

export function StatusBarSecondaryTimestamp(props: Props) {
    if (!props.datetime && props.emptyText) {
        return <span className='flex'>{props.emptyText}</span>;
    }

    if (props.datetime) {
        return (
            <span className='flex'>
                {props.prefix}&nbsp;{' '}
                <FormattedTimestamp
                    datetime={props.datetime}
                    format={FormattedTimestamp.formats.longDateWithTime}
                />
            </span>
        );
    }

    return null;
}
