import PropTypes from 'prop-types';
import * as React from 'react';
import Formsy from 'formsy-react';
import {Popover} from 'shells/popover';

import getClassesMixin from '../../mixins/get-classes';
import {FormSelect} from '../../base/form-components/form-select';
import {FormTextField} from '../../base/form-components/form-textfield';
import {CheckmarkSubmitButton} from '../../base/form-components/checkmark-submit-button';

const baseSelectValues = [
    {id: 'Equals', name: 'Equals'},
    {id: 'Contains', name: 'Contains'},
];

export class StringFormDeprecated extends React.Component {
    getClasses = getClassesMixin;

    static propTypes = {
        value: PropTypes.object,
        name: PropTypes.string,
        title: PropTypes.string,
        nullOptionText: PropTypes.string,
        anyOptionText: PropTypes.string,
        animateIn: PropTypes.bool,
        onDirty: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    static defaultProps = {
        value: {
            selected: 'Equals',
            data: '',
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            selectValue: props.value ? props.value.selected : 'Equals',
        };
    }

    componentDidMount() {
        const timeoutDuration = this.props.animateIn ? 250 : 0;

        setTimeout(() => {
            if (this.focusMe) {
                this.focusMe.focus();
            }
        }, timeoutDuration);
    }

    render() {
        const {name, value, nullOptionText, anyOptionText} = this.props;
        const selectValues = nullOptionText
            ? baseSelectValues.concat([{id: 'null', name: nullOptionText}])
            : baseSelectValues;

        if (anyOptionText) {
            selectValues.push({id: 'any', name: anyOptionText});
        }

        return (
            <Formsy
                className='ui-form'
                onChange={(data, changed) => this.props.onDirty(changed)}
                onValidSubmit={(data) => this.props.onSubmit(data)}
                onValid={() => this.setState({canSubmit: true})}
                onInvalid={() => this.setState({canSubmit: false})}
            >
                <div className='ui-linked-select ui-linked-select__string-form'>
                    <FormSelect
                        name={`${name}.selected`}
                        styleOptions={{fullWidth: true}}
                        onChange={this.handleSelectValueChange}
                        value={value.selected}
                        selectValues={selectValues}
                    />
                    {this.state.selectValue !== 'null' && this.state.selectValue !== 'any' ? (
                        <FormTextField
                            name={`${name}.data`}
                            formTextfieldRef={(c) => {
                                this.focusMe = c;
                            }}
                            value={value.data}
                            required={true}
                        />
                    ) : undefined}
                </div>
                <CheckmarkSubmitButton
                    className='ui-popover__submit-button'
                    getButtonRef={(c) => {
                        this.submitButton = c;
                    }}
                    disabled={!this.state.canSubmit}
                    onMouseOver={() => this.setState({submitHover: true})}
                    onMouseOut={() => this.setState({submitHover: false})}
                />
                {this.state.submitHover && !this.state.canSubmit ? (
                    <Popover anchor={this.submitButton} bodyColor='error' isDialog={true}>
                        <span>Whoops! Make sure your {this.props.title} isn’t empty!!</span>
                    </Popover>
                ) : undefined}
            </Formsy>
        );
    }

    handleSelectValueChange = (val) => {
        this.setState({selectValue: val});
    };
}
