/* @flow */

import {formEncodedApi} from 'nutshell-core/api';
import {mapEntityToDisplayOption, type Entity, type DisplayOptionEntity} from 'nutshell-core/utils';

export type PotentialContactSearchInput = {
    name: string,
    description: ?string,
    phoneNumber: ?string,
    email: ?string,
};

export function getPotentialContacts(
    contactInput: PotentialContactSearchInput,
    selectedAccountIds: string[]
): Promise<DisplayOptionEntity[]> {
    return formEncodedApi
        .get('/solr/find-potential-contacts-for-lead-form.json', {
            rows: 5,
            fields: [
                {name: 'name', value: contactInput.name},
                {name: 'email', value: contactInput.email},
                {name: 'phone', value: contactInput.phoneNumber},
                {name: 'description', value: contactInput.description},
            ],
            relatedAccountIds: selectedAccountIds,
        })
        .then((response) => response.json())
        .then((response: {results: Entity[]}) => {
            const results: Entity[] = response.results ? response.results : [];

            return results.map<DisplayOptionEntity>((entity: Entity) => {
                return mapEntityToDisplayOption(entity);
            });
        });
}
