/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import EditThicker from './icon-edit-thicker.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <EditThicker {...validProps} />;
}

SvgComponent.iconName = 'editThicker';

export const EditThickerIcon = svgIconWrapper(SvgComponent);
