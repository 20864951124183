/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import NavIconQuotas from './nav-icon-quotas.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <NavIconQuotas {...validProps} />;
}
SvgComponent.iconName = 'navIconQuotas';

export const NavIconQuotasIcon = svgIconWrapper(SvgComponent);
