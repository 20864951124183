/* @flow */

import * as React from 'react';
import * as ramda from 'ramda';

import {colors} from 'shells/colors';

import type {FilterObject, UnserializedFilterObject} from 'nutshell-core/types';
import * as Schema from 'nutshell-core/schema';
import {serialize} from 'nutshell-core/serialize';
import {deserializeUrlParam} from 'nutshell-core/deserialize-url-param';
import {type SerializedRelationshipFilter} from 'nutshell-core/deserialize-relationship';

import {PopoverForm} from '../../../../../ui/filtering/popover-form';

import {Badge} from '../badge';
import {Button} from '../button';
import type {ButtonSize} from '../button';
import {CloseIcon, ChevronDownIcon} from '../icon';
import type {Model} from '../selectable-list';
import {FilterDisplayText} from './filter-display-text';
import {PopoverForm as NewPopoverForm} from './popover-form/popover-form';

import './filter-capsule.css';

// Using a generic here and in the class definition allows us to infer the type of
// schemaField being used directly from the `schemaField` prop, and then use
// that type in the `onUpdate` and `onSubmit` prop type definitions.  Because,
// whatever schemaField is passed in to the component will be included in those
// callback functions.
type Props<T, N> = {
    schemaField: T,
    collection?: Model[],
    filterName: N,
    icon?: React.ComponentType<*>,
    filter?: SerializedRelationshipFilter | string | Object,
    defaultValueTitle?: string,
    entityType?: string,
    /** If defaultFilter is provided, and it equals the current filter, the capsule will not be clearable (it would be a no-op) */
    defaultFilter?: SerializedRelationshipFilter,
    size: ButtonSize,
    /** Display number of values, not full names (relationship filters only) */
    displayCountOnly?: boolean,
    disabled?: boolean,
    /** Maximum number of items to display, others become `+ 1` */
    numDisplayed?: number,
    onSubmit: (filter: FilterObject, filterName: N) => void,
    onRemove: (filterName: N) => void,
    shouldAllowSelectNone?: boolean,
    hasSearch: boolean,
    paginationProps?: {
        isLoading: boolean,
        hasNextPage: boolean,
        onFetchMoreRows: () => void,
    },
};

type State = {
    isPopoverShown: boolean,
    isHovered: boolean,
};

/**
This component should be used to allow the user control over a single filter.
It renders much like a select menu, and pops open a form to configure the filter.

Note: this component may not support all filter types.
*/
export class FilterCapsule<T: ?Schema.FieldProperties, N: string> extends React.Component<
    Props<T, N>,
    State,
> {
    filterButtonRef: ?HTMLElement;

    static defaultProps = {
        defaultValueTitle: '',
        entityType: 'leads',
        size: 'normal',
        onPopoverOpen: () => {},
        onPopoverClose: () => {},
        shouldAllowSelectNone: false,
        hasSearch: true,
    };

    constructor() {
        super();
        this.state = {
            isPopoverShown: false,
            isHovered: false,
        };
    }

    render() {
        const isFilterApplied = this.props.filter && this.props.schemaField;
        const canRemove =
            this.props.schemaField &&
            !this.props.schemaField.unclearable &&
            !ramda.equals(this.props.filter, this.props.defaultFilter);

        return (
            <div
                styleName='container'
                onMouseEnter={this.handleFilterMouseEnter}
                onMouseLeave={this.handleFilterMouseLeave}
            >
                {this.renderFilterButton()}
                {isFilterApplied ? (
                    <Badge
                        contents={
                            this.state.isHovered && canRemove ? (
                                <CloseIcon size={8} fill='#fff' wrapWithDiv={false} />
                            ) : undefined
                        }
                        onClick={canRemove ? this.handleFilterRemove : undefined}
                        color='grey'
                    />
                ) : null}
            </div>
        );
    }

    renderFilterButton = () => {
        const schemaField = {...this.props.schemaField};
        const isFilterApplied = this.props.filter && this.props.schemaField;

        // This is needed for the selected filter form popover
        if (!schemaField.name && this.props.filterName) {
            schemaField.name = this.props.filterName;
        }

        // Selected filter expects an object
        const selectedFilterObject = {
            [this.props.filterName]: this.props.filter,
        };

        // The defaultValueTitle is used to override the schema field's
        // title when no filter is applied. We use this for situations like
        // "Period" when really the schemaField title is "Funnel period" but
        // that would be redundant on the funnel report page, yet not redundant
        // anywhere else in the app
        const filterTitle = this.props.defaultValueTitle
            ? this.props.defaultValueTitle
            : schemaField.title || '\xa0'; // fall back to nonbreaking space, to preserve height

        const value =
            this.props.filter && this.props.schemaField
                ? deserializeUrlParam(this.props.filter, this.props.schemaField.type)
                : null;

        const Icon = this.props.icon;

        return (
            <div>
                <Button
                    variant={this.state.isPopoverShown ? 'primary' : 'secondary'}
                    size={this.props.size}
                    getButtonRef={(node) => {
                        this.filterButtonRef = node;
                    }}
                    onClick={this.handlePopoverOpen}
                    disabled={this.props.disabled}
                >
                    <div styleName='button-body'>
                        {Icon ? (
                            <div className='mr-8'>
                                <Icon
                                    size={14}
                                    alignCenter={true}
                                    fill={this.state.isPopoverShown ? colors.white : colors.greyDk}
                                />
                            </div>
                        ) : undefined}
                        {isFilterApplied && this.props.schemaField ? (
                            <FilterDisplayText
                                isActive={this.state.isPopoverShown}
                                field={this.props.schemaField}
                                filter={selectedFilterObject}
                                numDisplayed={this.props.numDisplayed}
                                shouldShowTitle={Boolean(this.props.displayCountOnly)}
                                displayCountOnly={this.props.displayCountOnly}
                                entityType={this.props.entityType}
                            />
                        ) : (
                            filterTitle
                        )}
                        <div styleName='chevron'>
                            <ChevronDownIcon size={11} />
                        </div>
                    </div>
                </Button>
                {this.state.isPopoverShown && !this.props.collection ? (
                    <PopoverForm
                        className={`ui-popover-form__header-filter ui-popover-form__header-filter--${schemaField.type}`}
                        anchor={this.filterButtonRef}
                        onSubmit={this.handleFilterSubmit}
                        onRemove={this.handleFilterRemove}
                        onClose={() => this.setState({isPopoverShown: false})}
                        field={schemaField}
                        filter={this.props.filter}
                        value={value || undefined}
                    />
                ) : null}
                {this.state.isPopoverShown && this.props.collection && this.props.schemaField ? (
                    <NewPopoverForm
                        location='bottom-right'
                        collection={this.props.collection}
                        field={this.props.schemaField}
                        filter={this.props.filter || null}
                        anchor={this.filterButtonRef}
                        onClose={() => this.setState({isPopoverShown: false})}
                        onSubmit={this.handleFilterSubmit}
                        shouldAllowSelectNone={this.props.shouldAllowSelectNone}
                        hasSearch={this.props.hasSearch}
                        paginationProps={this.props.paginationProps}
                    />
                ) : null}
            </div>
        );
    };

    handleFilterMouseEnter = () => {
        this.setState({isHovered: true});
    };

    handleFilterMouseLeave = () => {
        this.setState({isHovered: false});
    };

    handlePopoverOpen = () => {
        this.setState({isPopoverShown: true});
    };

    handlePopoverClose = () => {
        this.setState({isPopoverShown: false});
    };

    handleRemoveBadgeClick = (e: SyntheticEvent<*>) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }

        this.handleFilterRemove();
    };

    handleFilterRemove = () => {
        this.props.onRemove(this.props.filterName);
    };

    handleFilterSubmit = (data: ?UnserializedFilterObject<*>) => {
        // Should never happen, but if it does, better to just close the popover
        // without doing anything than erroring trying to get `type` of undefined.
        if (!this.props.schemaField) return;

        if (data) {
            this.props.onSubmit(
                serialize(data, this.props.schemaField.type),
                this.props.filterName
            );
        } else {
            this.props.onRemove(this.props.filterName);
        }
    };
}
