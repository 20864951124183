/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import NavIconChat from './nav-icon-chat.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <NavIconChat {...validProps} />;
}
SvgComponent.iconName = 'navIconChat';

export const NavIconChatIcon = svgIconWrapper(SvgComponent);
