/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import NavIconEmail from './nav-icon-email.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <NavIconEmail {...validProps} />;
}
SvgComponent.iconName = 'navIconEmail';

export const NavIconEmailIcon = svgIconWrapper(SvgComponent);
