/* @flow */

import * as React from 'react';
import {Field} from 'redux-form';

import {FooterBar} from 'shells/modal';
import {Button, SaveButton} from 'shells/button';
import {ButtonMover} from 'shells/button-mover';
import {Dialog} from 'shells/dialog';

import {Checkbox} from '../fields/checkbox';
import {IS_CREATING_SINGLE_LEAD_FROM_MULTIPLE} from '../forms/lead-form-connected';

import '../default-footer/default-footer.css';

// Two reasons for this:
//
// 1) It's not good practice for customers to add hundreds of related entities
// to their leads, so let's not help them do that
//
// 2) It gaurentees that we'll have all of the entities loaded up on the client,
// so we don't need to do any additional requests to the backend to determine
// which entities to show when creating a single lead for those mass selected peeps
export const MAX_CONTACTS_PER_LEAD = 100;

type Props = {|
    onCancel: () => void,
    isSaving: boolean,
    errorSaving?: boolean,
    creatorOriginId: ?string, // The creatorOriginId when leads have been successfully created
    numSelectedEntities: number, // The number of selected entities when creating single lead from multiple
    isDisabled: boolean, // If the form is disabled (will be true while submitting and after submitting)
    relatedEntity: string, // The related entity for the lead(s) being created (either contacts or accounts)
    handleSubmit: () => any, // Function that will submit the form
    isCreatingSingleLeadFromMultiple: boolean, // True if creating a single lead from multiple related entities
    numLeadsCreating: number, // The number of leads being created when creating multiple
|};

export function BulkCreateLeadFooter(props: Props) {
    const {
        onCancel,
        isSaving,
        errorSaving,
        creatorOriginId,
        numSelectedEntities,
        isDisabled,
        relatedEntity,
        handleSubmit,
        isCreatingSingleLeadFromMultiple,
        numLeadsCreating,
    } = props;

    const [numRelatedEntities, setNumRelatedEntities] = React.useState<number>(numLeadsCreating);
    const [showConfirmationDialog, setShowConfirmationDialog] = React.useState<boolean>(false);
    const saveButtonRef = React.useRef();

    // If the user is creating a single lead from multiple relatedEntities, they have the ability to add or remove
    // entities that have been pre-populated in the form. This is to make sure the button text updates with the
    // correct number of leads being created as this number changes.
    React.useEffect(() => {
        if (isCreatingSingleLeadFromMultiple) {
            setNumRelatedEntities(numSelectedEntities);
        }
    }, [numSelectedEntities, isCreatingSingleLeadFromMultiple]);

    // Text to use for button
    let buttonText = 'Create lead';
    let savingText = 'Creating lead…';

    if (!isCreatingSingleLeadFromMultiple) {
        // After submit succeeds for bulk lead creation, the button will link to the leads page on click
        buttonText = creatorOriginId ? 'View leads' : `Create ${numRelatedEntities} leads`;
        savingText = `Creating ${numRelatedEntities} leads…`;
    }

    const relatedEntityType = relatedEntity === 'contacts' ? 'people' : 'companies';

    return (
        <div styleName='default-footer' id='create-entity-footer'>
            <FooterBar
                left={
                    <ButtonMover size='big' direction='left'>
                        <Button variant='text' size='big' onClick={onCancel}>
                            {creatorOriginId ? 'Close' : 'Cancel'}
                        </Button>
                    </ButtonMover>
                }
                right={
                    <div styleName='right-group-container'>
                        {numLeadsCreating <= MAX_CONTACTS_PER_LEAD ? (
                            <Field
                                name={IS_CREATING_SINGLE_LEAD_FROM_MULTIPLE}
                                type='checkbox'
                                label={`Create a single lead with ${numRelatedEntities} related ${relatedEntityType}`}
                                component={Checkbox}
                                disabled={isDisabled}
                            />
                        ) : undefined}
                        <SaveButton
                            id='save-entity'
                            buttonText={buttonText}
                            savingText={savingText}
                            isSaving={isSaving}
                            getButtonRef={saveButtonRef}
                            errorSaving={errorSaving}
                            resetOnComplete={true}
                            saveCompleteTimeout={1000} // Shortening the saveComplete timeout before button is reset
                            onClick={(e) => {
                                e.preventDefault();

                                // If creatorOriginId has been passed in we will redirect to leads page with that
                                // originId applied as a filter.
                                if (creatorOriginId) {
                                    // Close the modal and reset the redux form state
                                    onCancel();
                                    // Link to the leads page with the origin filter applied
                                    window.location = `/leads?filter[origin][data][0][data]=${creatorOriginId}`;
                                } else {
                                    // Show confirmation dialog
                                    setShowConfirmationDialog(true);
                                }
                            }}
                        />
                        {showConfirmationDialog ? (
                            <Dialog
                                anchor={saveButtonRef.current}
                                question={
                                    isCreatingSingleLeadFromMultiple
                                        ? `This will create a single lead with ${numRelatedEntities} related ${relatedEntityType}. Would you like to create a lead now?`
                                        : `This will create ${numLeadsCreating} leads and cannot be undone. Would you like to create leads now?`
                                }
                                primaryText={
                                    isCreatingSingleLeadFromMultiple
                                        ? 'Yes, create lead'
                                        : `Yes, create ${numRelatedEntities} leads`
                                }
                                onPrimary={() => {
                                    setShowConfirmationDialog(false);
                                    handleSubmit();
                                }}
                                primaryVariant='save'
                                secondaryText='No, don’t create'
                                onSecondary={() => {
                                    setShowConfirmationDialog(false);
                                }}
                            />
                        ) : undefined}
                    </div>
                }
            />
        </div>
    );
}
