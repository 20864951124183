import PropTypes from 'prop-types';
import * as React from 'react';
import Formsy from 'formsy-react';
import _ from 'underscore';
import {orderUsersAndTeams} from 'nutshell-core/order-users-and-teams';
import {Popover} from 'shells/popover';
import {FormSearchableList} from '../../base/form-components/form-searchable-list';
import {FormRadioGroup} from '../../base/form-components/form-radio-group';
import {EntitySearchableList} from '../../base/form-components/entity-searchable-list';
import {CheckmarkSubmitButton} from '../../base/form-components/checkmark-submit-button';
import {RadioButtonDeprecated} from './radio-button-deprecated';
import {RelationshipEmptyViewDeprecated} from './relationship-empty-view-deprecated';

function getEntityType() {
    const rawType = window.location.pathname;
    const trimmedType = rawType.substr(1, rawType.length - 1);
    let entityType = 'leads';
    switch (trimmedType) {
        case 'companies':
            entityType = 'accounts';
            break;
        case 'people':
            entityType = 'contacts';
            break;
    }

    return entityType;
}

export class RelationshipFormDeprecated extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        value: PropTypes.any,
        nullOptionText: PropTypes.string,
        relationships: PropTypes.array.isRequired,
        onDirty: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        isSingleSelect: PropTypes.bool,
        isAndable: PropTypes.bool,
        animateIn: PropTypes.bool,
    };

    static defaultProps = {
        value: {anyAll: 'any', data: []},
    };

    constructor(props) {
        super(props);
        this.state = {canSubmit: false};
    }

    render() {
        const {
            name,
            title,
            value,
            relationships,
            isAndable,
            isSingleSelect,
            nullOptionText,
            animateIn,
        } = this.props;

        const normalizedValue = value.data.map((item) => item.data);

        const entityRelationships = _.intersection(relationships, [
            'accounts',
            'contacts',
            'leads',
        ]);
        const type = _.first(relationships);

        let formBody = [];
        let formControls = [];

        if (entityRelationships.length) {
            formBody = (
                <EntitySearchableList
                    name={`${name}.data`}
                    types={relationships}
                    label={title}
                    validations='minLength:1'
                    value={normalizedValue}
                    hierarchyTypeFilter={`${name}`}
                />
            );
        } else {
            let models = relationships
                .reduce((list, col) => {
                    const collectionName = col.charAt(0).toUpperCase() + col.slice(1);
                    const items = Nut.managedCollections[collectionName].models;

                    // Code below is to ensure we only show the correct tagType
                    // for the type of tag filter we're applying
                    const entityType = getEntityType();
                    const filteredItems = items.filter((item) => {
                        if (item.get('isHiddenFromFilters')) return false;

                        // If we're not dealing with tags, return true
                        if (collectionName !== 'Tags') return true;

                        // If we're filtering accountTags on people, we only want account tags
                        if (name === 'accountTags') {
                            return item.get('tagType') === 'accounts';
                        } else if (name === 'contactTags') {
                            return item.get('tagType') === 'contacts';
                        }

                        // Otherwise, only match entityType
                        return entityType === item.get('tagType');
                    });

                    return list.concat(filteredItems);
                }, [])
                .map((model) => {
                    return {
                        id: model.id,
                        type: model.get('type'),
                        name: model.get('name'),
                        isEnabled: model.get('type') === 'users' ? model.get('isEnabled') : true,
                    };
                });

            if (_.contains(relationships, 'users')) {
                models = orderUsersAndTeams(models);
            }

            if (nullOptionText) models.unshift({id: '-', name: nullOptionText});

            formBody = (
                <FormSearchableList
                    name={`${name}.data`}
                    emptyView={<RelationshipEmptyViewDeprecated />}
                    animateIn={animateIn}
                    label={title}
                    type={type}
                    isSingleSelect={isSingleSelect}
                    validations='minLength:1'
                    value={normalizedValue}
                    collection={models}
                />
            );

            if (isAndable) {
                formControls = (
                    <div className='ui-form--relationship__form-controls'>
                        <FormRadioGroup
                            className='ui-radio-group--inline'
                            name={`${name}.anyAll`}
                            value={value.anyAll || 'any'}
                        >
                            <RadioButtonDeprecated
                                className='ui-form--relationship__form-controls__control'
                                type='standard'
                                key='any'
                                index={0}
                                value='any'
                            >
                                Any
                            </RadioButtonDeprecated>
                            <RadioButtonDeprecated
                                className='ui-form--relationship__form-controls__control'
                                type='standard'
                                key='all'
                                index={1}
                                value='all'
                            >
                                All
                            </RadioButtonDeprecated>
                        </FormRadioGroup>
                    </div>
                );
            }
        }

        return (
            <Formsy
                className='ui-form ui-form--relationship'
                onChange={(data, changed) => this.props.onDirty(changed)}
                onValidSubmit={(data) => this.serializeAndSubmitData(data)}
                onValid={() => this.setState({canSubmit: true})}
                onInvalid={() => this.setState({canSubmit: false})}
            >
                {formControls}
                {formBody}
                <CheckmarkSubmitButton
                    className='ui-popover__submit-button'
                    getButtonRef={(c) => {
                        this.submitButton = c;
                    }}
                    disabled={!this.state.canSubmit}
                    onMouseOver={() => this.setState({submitHover: true})}
                    onMouseOut={() => this.setState({submitHover: false})}
                />
                {this.state.submitHover && !this.state.canSubmit ? (
                    <Popover anchor={this.submitButton} bodyColor='error' isDialog={true}>
                        <span>Must select one or more {type}!</span>
                    </Popover>
                ) : undefined}
            </Formsy>
        );
    }

    serializeAndSubmitData(data) {
        const fieldName = this.props.name; // Owner

        const dataToSubmit = {
            [fieldName]: {
                anyAll: data[fieldName].anyAll,
                data: data[fieldName].data.map((value) => ({isNot: false, data: value})),
            },
        };

        this.props.onSubmit(dataToSubmit);
    }
}
