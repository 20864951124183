/* @flow */

import {combineReducers} from 'redux';
import type {User} from '../types';

import {ActionTypes, type SessionAction} from './session-types';

const isLoading = (state: boolean = false, action: SessionAction): boolean => {
    switch (action.type) {
        case ActionTypes.SESSION_FETCH_SESSION_REQUEST:
            return true;
        case ActionTypes.SESSION_FETCH_SESSION_SUCCESS:
        case ActionTypes.SESSION_FETCH_SESSION_FAILURE:
            return false;
        default:
            return state;
    }
};

type UserState = $PropertyType<SessionState, 'user'>;
const user = (state: UserState = null, action: SessionAction): UserState => {
    switch (action.type) {
        case ActionTypes.SESSION_FETCH_SESSION_SUCCESS: {
            const sessionUser =
                (action.payload && action.payload.users && action.payload.users[0]) || state;
            const sessionUserId = sessionUser && sessionUser.id;

            // If the new data passed shares the same id, we'll consider this an update,
            // so we'll destructure our existing data onto our new user, so that a sparse
            // response won't blow away other valuable information
            const newSessionUser =
                state && state.id === sessionUserId ? {...state, ...sessionUser} : sessionUser;

            return newSessionUser;
        }
        case ActionTypes.SESSION_UPDATE_AGENT_STATUS: {
            if (!state) return state;

            return {
                ...state,
                agentStatus: action.payload.agentStatus,
            };
        }
        case ActionTypes.SESSION_UPDATE_MAIN_SIDEBAR_COLLAPSED: {
            if (!state) return state;

            return {
                ...state,
                isMainSidebarCollapsed: action.payload.isMainSidebarCollapsed,
            };
        }
        case ActionTypes.SESSION_FETCH_SESSION_FAILURE: {
            // HACK! When we download files in safari, we get a session
            // request failure. We can't blow away the session state,
            // but we do need to clear notifyMessages so the "Download Ready"
            // indication goes away
            if (state) {
                return {
                    ...state,
                    notifyMessages: [],
                };
            }

            return state;
        }
        case ActionTypes.SESSION_HACKY_DOM_USER_ADDED: {
            const sessionUser = action.payload;

            // Don't overwrite state if it's already set
            if (state && sessionUser && state.id === sessionUser.id) return state;

            return sessionUser;
        }
        default:
            return state;
    }
};

export type SessionState = {
    isLoading: boolean,
    user: ?User,
};

// $FlowFixMe upgrading Flow to v0.92.1
export const reducer = combineReducers({
    isLoading,
    user,
});
