/* @flow */

import * as React from 'react';

import {RemoveButton} from '../../button';
import type {Source} from './types';
import {getDisplayableSourceType} from './helpers';

import './source-tag.css';

type Props = {|
    source: Source,
    onRemoveSource: () => any,
|};

export function SourceTag(props: Props) {
    const {source, onRemoveSource} = props;

    const sourceType = getDisplayableSourceType(source.type);

    return (
        <div styleName={sourceType ? 'source-tag' : 'source-tag--short'}>
            <div styleName='text-container'>
                <div>{source.name}</div>
                {sourceType ? <div>{sourceType}</div> : undefined}
            </div>
            <div styleName='remove-button-container'>
                <RemoveButton size={9} onClick={onRemoveSource} />
            </div>
        </div>
    );
}
