/* @flow */

import React from 'react';
import {temporaryHtmlToString} from 'nutshell-core/list-items/temporary-html-to-string';
import {LinkedEntity} from '../linked-entity';

type Peep = {
    id: string,
    htmlUrl: string,
    name: string,
};

type Props = {
    relatedPerson: ?Peep,
    relatedCompany: ?Peep,
    leadName: string,
    isLinkable: boolean,
};

export function RelatedEntity({relatedPerson, relatedCompany, leadName, isLinkable}: Props) {
    const leadHasCompanyName = leadNamedForCompany(leadName, relatedCompany);

    // If the lead is named for the company, show the person name.  Likely most common case.
    if (leadHasCompanyName && relatedPerson) {
        return (
            <LinkedEntity
                name={relatedPerson.name}
                type='contacts'
                isLinkable={isLinkable}
                useEntityColor={false}
                htmlUrl={relatedPerson.htmlUrl}
            />
        );
    }

    // As long as the lead isn't named the same as the company, use the company name
    if (!leadHasCompanyName && relatedCompany) {
        return (
            <LinkedEntity
                name={relatedCompany.name}
                type='accounts'
                isLinkable={isLinkable}
                useEntityColor={false}
                htmlUrl={relatedCompany.htmlUrl}
            />
        );
    }

    // As a last ditch, try to show the person
    if (relatedPerson) {
        return (
            <LinkedEntity
                name={relatedPerson.name}
                type='contacts'
                isLinkable={isLinkable}
                useEntityColor={false}
                htmlUrl={relatedPerson.htmlUrl}
            />
        );
    }

    return null;
}

function leadNamedForCompany(leadName, company: ?Peep) {
    // Since for now leadNames are returned with special characters converted by the server,
    // we need to turn it into a normal string for checking against company name.
    const decodedLeadName = temporaryHtmlToString(leadName);

    return company && decodedLeadName === company.name;
}
