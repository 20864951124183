/* @flow */

/**
 * Map plural entity type with singular version.
 * @param  {string} entityType The plural version of the entity type.
 * @return {string}            The singular version of the entity type.
 */
export function getSingularEntityType(entityType: string): string {
    switch (entityType) {
        case 'accounts':
            return 'company';
        case 'mcfxcompanies':
            return 'visitor';
        case 'activities':
            return 'activity';
        case 'contacts':
            return 'person';
        case 'competitors':
            return 'competitor';
        case 'leads':
            return 'lead';
        case 'tasks':
            return 'task';
        case 'tags':
            return 'tag';
        case 'sources':
            return 'source';
        case 'territories':
            return 'territory';
        case 'products':
            return 'product';
        case 'notes':
            return 'note';
        case 'quotes':
            return 'quote';
        default:
            return entityType;
    }
}
