/* @flow */

import * as React from 'react';

type Props = {
    width?: number,
    height?: number,
};

export function MobilePhoneFrame(props: Props) {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox='0 0 356 636'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                x='0.75'
                y='0.75'
                width='354.5'
                height='634.111'
                rx='23.25'
                fill='white'
                stroke='#DBD9D9'
                strokeWidth='1.5'
            />
            <rect x='10.5889' y='62.4756' width='333.381' height='499.13' stroke='#EBE8E8' />
            <circle cx='136.099' cy='32.429' r='3' fill='#F7F7F7' stroke='#EBE8E8' />
            <rect
                x='147.099'
                y='29.429'
                width='35'
                height='6'
                rx='3'
                fill='#F7F7F7'
                stroke='#EBE8E8'
            />
            <circle cx='178.721' cy='601.02' r='25.4433' fill='#F7F7F7' stroke='#EBE8E8' />
        </svg>
    );
}
