import * as React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {withFormsy, propTypes as formsyPropTypes} from 'formsy-react';
import {TextField} from 'shells/textfield';

import getClassesMixin from '../../mixins/get-classes';
import {DatePicker} from '../date-picker';

class FormDatePickerComponent extends React.Component {
    getClasses = getClassesMixin;

    static propTypes = {
        dateFormat: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        ...formsyPropTypes,
    };

    static defaultProps = {
        value: moment().unix(),
        dateFormat: 'D MMMM YYYY',
    };

    constructor() {
        super();
        this.state = {
            openDatePicker: false,
        };
    }

    render() {
        const classes = this.getClasses('ui-input-date-picker');

        const formattedDate = moment(this.props.getValue() * 1000).format(this.props.dateFormat);

        return (
            <div className={classes}>
                <TextField
                    readOnly={true}
                    textFieldRef={(c) => {
                        this.input = c;
                    }}
                    value={formattedDate}
                    onFocus={() => this.setState({openDatePicker: true})}
                />
                {this.state.openDatePicker ? (
                    <DatePicker
                        initialDate={moment.unix(this.props.getValue())}
                        anchor={this.input}
                        location='bottom'
                        onSubmit={this.handleSubmit}
                        onCancel={() => this.setState({openDatePicker: false})}
                    />
                ) : undefined}
            </div>
        );
    }

    handleSubmit = (date) => {
        this.setState({openDatePicker: false});
        const newValue = moment(date).unix();
        this.props.setValue(newValue);
        this.props.onChange(newValue);
    };
}

export const FormDatePicker = withFormsy(FormDatePickerComponent);
