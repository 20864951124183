/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {Checkbox as CheckboxComponent} from 'shells/checkbox';

type Props = FieldProps & {
    label: string,
};

export function Checkbox(props: Props) {
    const {input, label, ...rest} = props;

    return (
        <div>
            <CheckboxComponent
                {...rest}
                {...props.input}
                isLarge={true}
                name={input.name}
                checked={props.input.value}
                label={label}
            />
        </div>
    );
}
