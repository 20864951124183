/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {getSingularEntityType} from 'nutshell-core/utils/get-singular-entity-type';

import type {SectionEntity} from './types';
import {getEntityClassName} from './helpers';

import './section-add-item-button.css';

type Props = {|
    sectionEntity: SectionEntity,
    // Used as `+ Add [parentItemText] [entityType]`
    parentItemText?: React.Node,
    omitEntityName?: boolean,
    onAddItem: () => void,
|};

export function EntityPageAddItemButton(props: Props) {
    const {sectionEntity, parentItemText, onAddItem, omitEntityName} = props;

    const addItemStyleNames = classnames({
        'add-item-button': true,
        ...getEntityClassName(sectionEntity),
    });

    return (
        <button
            styleName={addItemStyleNames}
            onClick={(e) => {
                e.preventDefault();
                onAddItem();
            }}
        >
            + Add {parentItemText ? parentItemText : ''}
            {omitEntityName ? undefined : getSingularEntityType(sectionEntity)}
        </button>
    );
}
