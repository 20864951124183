import PropTypes from 'prop-types';
import * as React from 'react';

import {DateTimeFormDeprecated} from './forms-deprecated/date-time-deprecated';
import {StringFormDeprecated} from './forms-deprecated/string-deprecated';
import {PhoneFormDeprecated} from './forms-deprecated/phone-deprecated';
import {NumberFormDeprecated} from './forms-deprecated/number-deprecated';
import {IntegerFormDeprecated} from './forms-deprecated/integer-deprecated';
import {BooleanFormDeprecated} from './forms-deprecated/boolean-deprecated';
import {EnumFormDeprecated} from './forms-deprecated/enum-deprecated';
import {RelationshipFormDeprecated} from './forms-deprecated/relationship-deprecated';
import {LocationFormDeprecated} from './forms-deprecated/location-deprecated';

const filterComponents = {
    string: StringFormDeprecated,
    text: StringFormDeprecated,
    email: StringFormDeprecated,
    phone: PhoneFormDeprecated,
    integer: IntegerFormDeprecated,
    rating: IntegerFormDeprecated,
    currency: NumberFormDeprecated,
    'date-time': DateTimeFormDeprecated,
    'date-time-nullable': DateTimeFormDeprecated,
    boolean: BooleanFormDeprecated,
    stage: EnumFormDeprecated,
    enum: EnumFormDeprecated,
    relationship: RelationshipFormDeprecated,
    badge: EnumFormDeprecated,
    location: LocationFormDeprecated,
    hierarchy: RelationshipFormDeprecated,
};

export class FilterFormDeprecated extends React.Component {
    static propTypes = {
        animateIn: PropTypes.bool,
        field: PropTypes.object.isRequired,
        value: PropTypes.any,
        onSubmit: PropTypes.func.isRequired,
        onDirty: PropTypes.func.isRequired,
    };

    render() {
        const {field, onSubmit, onDirty, value, animateIn} = this.props;
        const FilterComponent = filterComponents[field.type];

        return (
            <FilterComponent
                {...field}
                value={value}
                animateIn={animateIn}
                onDirty={onDirty}
                onSubmit={onSubmit}
                options={field.enum}
            />
        );
    }
}
