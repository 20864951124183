/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import NavIconWebsite from './nav-icon-website.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <NavIconWebsite {...validProps} />;
}
SvgComponent.iconName = 'navIconWebsite';

export const NavIconWebsiteIcon = svgIconWrapper(SvgComponent);
