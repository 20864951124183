import * as React from 'react';
import PropTypes from 'prop-types';
import {withFormsy, propTypes as formsyPropTypes} from 'formsy-react';

import {StateSelect} from 'shells/select';

/*
 * Helper component that acts as a translate layer between our FormsyComponent
 * styles and our standard StateSelect shell.
 */
class FormStateSelectComponent extends React.Component {
    // ISO 3166-1 country codes taken from http://opencountrycodes.appspot.com/
    // includes a lot of defunct countries (e.g. Soviet Union)

    static propTypes = {
        country: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        ...formsyPropTypes,
    };

    render() {
        return (
            <StateSelect
                value={this.props.getValue()}
                country={this.props.country}
                onChange={this.handleChange}
            />
        );
    }

    handleChange = (value) => {
        this.props.setValue(value.id);
        this.props.onChange(value.id);
    };
}

export const FormStateSelect = withFormsy(FormStateSelectComponent);
