/* @flow */

import * as React from 'react';
import * as ramda from 'ramda';

import {ListView} from '../list-view';
import {SearchBar} from '../search-bar';

import {SelectableListRow, type Model} from './selectable-list-row';

import './selectable-list.css';

type Props<T> = {
    /** An array of models (objects with id, name, and type) to show in the list */
    collection: T[],
    /** If true, it will be impossible to deselect all items */
    alwaysHasValue?: boolean,
    /** If true, more than one value is possible */
    isMultiSelect?: boolean,
    hasSearch?: boolean,
    /** If provided, this will show when no other results are available */
    emptyStateComponent?: React.Node,
    /** The currently-selected model(s) */
    value: ?(string | string[]),
    onSelect: (selected: any) => void,
    shouldAllowSelectNone?: boolean,
    isNoneSelected: boolean,
    onSelectNone: (selectNone: boolean) => void,
    /** Dumb, hopefully temp hack prop */
    isNewStyle?: boolean,
    /** Should the search bar be auto focused, as in a popover? */
    autoFocusSearch?: boolean,
    paginationProps?: {
        hasNextPage: boolean,
        isLoading: boolean,
        onFetchMoreRows: () => void,
    },
    sortInitialValueToTop?: boolean,
};

type State<T> = {
    searchQuery: string,
    /** This is only set in the constructor, so we can bubble the pre-set values to the top of the list */
    initialValues: string[],
    sortedCollection: T[],
    selectNone: boolean,
};

const LOAD_MORE_PIXEL_THRESHOLD = 250;

/**
This component can be used to render a list of users and/or teams, and can be configured
to support single or multi-select, along with a few other options.

By default a search field is provided at the top to narrow down the list, but this can be disabled if desired.
 */
class SelectableList<T: Model> extends React.Component<Props<T>, State<T>> {
    listRef: ?HTMLElement;
    state: State<T>;

    static defaultProps = {
        alwaysHasValue: false,
        isMultiSelect: false,
        hasSearch: true,
        shouldAllowSelectNone: false,
        isNoneSelected: false,
        onSelectNone: () => {},
        sortInitialValueToTop: true,
    };

    constructor(props: Props<T>) {
        super(props);
        let initialValues = [];
        if (props.value) {
            if (Array.isArray(props.value)) {
                initialValues = props.value;
            } else {
                initialValues = [props.value];
            }
        }
        const sortedCollection = moveInitialValuesToTop(
            props.collection,
            initialValues,
            props.sortInitialValueToTop
        );

        this.state = {
            searchQuery: '',
            initialValues,
            sortedCollection,
            selectNone: props.isNoneSelected,
        };
    }

    componentDidMount() {
        if (this.listRef) {
            this.listRef.addEventListener('scroll', this.handleScroll);
        }
    }

    componentWillUnmount() {
        if (this.listRef) {
            this.listRef.removeEventListener('scroll', this.handleScroll);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props<T>) {
        if (nextProps.collection !== this.props.collection) {
            const sortedCollection = moveInitialValuesToTop(
                nextProps.collection,
                // We can safely disable this eslint rule because initialValues state never changes
                // eslint-disable-next-line react/no-access-state-in-setstate
                this.state.initialValues,
                this.props.sortInitialValueToTop
            );

            this.setState({sortedCollection});
        }
    }

    render() {
        const filteredCollection = this.state.sortedCollection.filter(this.filterForSearch);

        let values = [];
        if (this.props.value) {
            values = Array.isArray(this.props.value) ? this.props.value : [this.props.value];
        }

        const EmptyStateComponent = (
            <ul styleName='empty-container'>
                <li>{this.props.emptyStateComponent}</li>
            </ul>
        );

        return (
            <div styleName={this.props.isNewStyle ? 'container-new' : 'container'}>
                {this.props.hasSearch ? (
                    <div styleName='search'>
                        <SearchBar
                            hasBorder={this.props.isNewStyle}
                            value={this.state.searchQuery}
                            autoFocus={this.props.autoFocusSearch}
                            onChange={this.handleTextChange}
                        />
                    </div>
                ) : null}
                {this.props.shouldAllowSelectNone ? (
                    <div styleName='select-none list-view'>
                        <SelectableListRow
                            key='select-none'
                            hasCheckboxes={true}
                            isBorderless={true}
                            isChecked={this.state.selectNone}
                            item={{
                                id: 'none',
                                name: 'None',
                                type: 'fake',
                            }}
                            onSelect={this.handleSelectNone}
                        />
                    </div>
                ) : undefined}
                <div styleName='list-view' ref={(c) => (this.listRef = c)}>
                    <ListView
                        renderRow={(item: T) => this.renderRow(item, values)}
                        collection={filteredCollection}
                        specialStates={[
                            {
                                shouldRender: () =>
                                    Boolean(
                                        filteredCollection.length === 0 &&
                                            this.props.emptyStateComponent
                                    ),
                                component: EmptyStateComponent,
                            },
                            {
                                shouldRender: () =>
                                    Boolean(
                                        filteredCollection.length === 0 && this.state.searchQuery
                                    ),
                                component: (
                                    <ul styleName='empty-container'>
                                        <li>{`No matches for "${this.state.searchQuery}"`}</li>
                                    </ul>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }

    handleTextChange = (val: string) => {
        this.setState({searchQuery: val});
    };

    renderRow = (item: Model, values: string[]) => {
        const isChecked = values.includes(item.id);

        return (
            <SelectableListRow
                key={item.id}
                hasCheckboxes={this.props.isMultiSelect}
                isBorderless={this.props.isNewStyle}
                isChecked={isChecked}
                item={item}
                isDisabled={item.isDisabled || this.state.selectNone}
                tooltipText={item.tooltipText}
                onSelect={this.handleSelect}
            />
        );
    };

    handleSelect = (item: Model, isCurrentlySelected: boolean) => {
        let value = [];
        if (this.props.value) {
            value = Array.isArray(this.props.value) ? this.props.value : [this.props.value];
        }
        const {alwaysHasValue, isMultiSelect} = this.props;

        let returnValue = null;
        if (!isMultiSelect) {
            // If the item isn't currently selected or we always have a value
            // we'll return the selected item
            if (!isCurrentlySelected || alwaysHasValue) {
                returnValue = item.id;
            }
        } else if (value.length === 1 && alwaysHasValue && isCurrentlySelected) {
            returnValue = [item.id];
        } else {
            returnValue = [...value];
            returnValue = isCurrentlySelected
                ? returnValue.filter((selectedModelId) => selectedModelId !== item.id)
                : returnValue.concat([item.id]);
        }

        this.props.onSelect(returnValue);
    };

    handleSelectNone = (item: Model, isCurrentlySelected: boolean) => {
        const shouldSelectNone = !isCurrentlySelected;

        this.setState({selectNone: shouldSelectNone});
        this.props.onSelectNone(shouldSelectNone);
    };

    filterForSearch = (item: Model) => {
        if (!this.state.searchQuery || !item.name) return true;

        return !(item.name.toLowerCase().indexOf(this.state.searchQuery.toLowerCase()) === -1);
    };

    handleScroll = (e: Event) => {
        // $FlowIgnore these _do_ exist
        const {scrollHeight, offsetHeight, scrollTop} = e.target;

        if (
            scrollHeight - offsetHeight < scrollTop + LOAD_MORE_PIXEL_THRESHOLD &&
            this.props.paginationProps &&
            this.props.paginationProps.hasNextPage &&
            !this.props.paginationProps.isLoading
        ) {
            this.props.paginationProps.onFetchMoreRows();
        }
    };
}

type SingleProps<T> = {
    /** An array of models (objects with id, name, and type) to show in the list */
    collection: T[],
    /** If true, it will be impossible to deselect all items */
    alwaysHasValue?: boolean,
    hasSearch?: boolean,
    /** If provided, this will show when no other results are available */
    emptyStateComponent?: React.Node,
    /** The currently-selected model(s) */
    value: ?string,
    // TODO: Remove nullable type when alwaysHasValue is true (disjoint union types with Flow 53+)
    onSelect: (selected: ?string) => void,
};

/**
This component can be used to render a list of users and/or teams (or other models), which can be
clicked on to be selected.  This is a single-select, so each click will move the selection.

Initial value is sorted to the top, disabled items are sorted to the bottom.

By default a search field is provided at the top to narrow down the list, but this can be disabled if desired.
 */
export class SelectableListSingle<
    // $FlowFixMe upgrading Flow to v0.110.1
    T: $Supertype<Model>,
> extends React.Component<SingleProps<T>, void> {
    render() {
        return <SelectableList {...this.props} isMultiSelect={false} />;
    }
}

type MultipleProps<T> = {
    /** An array of models (objects with id, name, and type) to show in the list */
    collection: T[],
    /** If true, it will be impossible to deselect all items */
    alwaysHasValue?: boolean,
    hasSearch?: boolean,
    /** If provided, this will show when no other results are available */
    emptyStateComponent?: React.Node,
    /** The currently-selected model(s) */
    value: ?(string[]),
    onSelect: (selected: string[]) => void,
    paginationProps?: {
        hasNextPage: boolean,
        isLoading: boolean,
        onFetchMoreRows: () => void,
    },
};

/**
This component can be used to render a list of users and/or teams (or other models), which can be
clicked on to be selected.  This is a multi-select, so each click will add or remove to the list of
selected items.

Initial values are sorted to the top, disabled items are sorted to the bottom.

By default a search field is provided at the top to narrow down the list, but this can be disabled if desired.
*/
export class SelectableListMultiple<
    // $FlowFixMe upgrading Flow to v0.110.1
    T: $Supertype<Model>,
> extends React.Component<MultipleProps<T>, void> {
    render() {
        return <SelectableList {...this.props} isMultiSelect={true} />;
    }
}

/*
 * This will pull any models having an id provided in the `initialValues` argument
 * up to the top of the list, without changing the overall sort order of the list
 * in any other way.
 */
export function moveInitialValuesToTop<T: Model>(
    collection: T[],
    initialValues: string[],
    shouldSort: boolean = true
): T[] {
    if (!shouldSort) {
        return collection;
    }

    const initialCollection = collection.filter((model) => initialValues.includes(model.id));
    const remainingCollection = ramda.without(initialCollection, collection);

    return initialCollection.concat(remainingCollection);
}
