/* @flow */

/**
 * Map plural entity type with plural friendly version.
 * @param  {string} entityType The plural version of the entity type.
 * @return {string}            The plural, friendly version of the entity type.
 */
export function getMultipleEntityType(entityType: string): string {
    switch (entityType) {
        case 'accounts':
            return 'companies';
        case 'mcfxcompanies':
            return 'visitors';
        case 'activities':
            return 'activities';
        case 'contacts':
            return 'people';
        case 'leads':
            return 'leads';
        case 'tasks':
            return 'tasks';
        case 'steps':
            return 'automated tasks';
        default:
            return entityType;
    }
}
