/* @flow */

import {getSingularEntityType} from 'nutshell-core/utils/get-singular-entity-type';

import type {SectionEntity} from './types';

export function getEntityClassName(entityType: SectionEntity): Object {
    return {
        [getSingularEntityType(entityType)]: true,
    };
}
