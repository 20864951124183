/* @flow */

import * as React from 'react';
import {formValueSelector, type FieldProps} from 'redux-form';
import type {DisplayOptionEntity} from 'nutshell-core/utils';
import type {GetContactFormValidation_contactCreateValidation_errors as FormErrorType} from 'nutshell-graphql-types';

import {Stack} from 'shells/layout';
import {EntityPageAddItemButton} from 'shells/lead-sidebar';
import {
    ContactCard,
    ContactCardCreateable,
    type ContactInput,
    FIELD_ALL,
} from 'shells/contact-card';
import {useSelector} from 'react-redux';

import {useGetContactFormValidation} from '../../graphql/hooks';

import {LEADS_FORM_KEY} from '../../../entity-form/forms/lead-form-connected';
import {
    getPotentialContacts,
    type PotentialContactSearchInput,
} from './rich-contact-select-helpers';

type Props = {
    ...FieldProps,
};

const leadFormSelector = formValueSelector(LEADS_FORM_KEY);

const NEW_CONTACT_MAGIC_ID = 'new-contact-magic-id';

export function RichContactSelect(props: Props): React.Node {
    const {input} = props;

    const [isEditingContactId, setIsEditingContactId] = React.useState<?string>(null);
    const [formErrors, setFormErrors] = React.useState<FormErrorType[]>([]);

    const {refetch: fetchFormValidation} = useGetContactFormValidation();

    const selectedAccounts = useSelector((state) => leadFormSelector(state, 'links.accounts'));
    const selectedAccountIds = selectedAccounts && selectedAccounts.map((account) => account.id);

    const handleCreateContact = (contactInput: ContactInput) => {
        // validate contactInput
        fetchFormValidation({
            name: contactInput.name,
            email: contactInput.email,
            phone: contactInput.phoneNumber,
            description: contactInput.description,
        }).then((response) => {
            const errors =
                response &&
                response.data &&
                response.data.contactCreateValidation &&
                response.data.contactCreateValidation.errors;

            if (errors.length > 0) {
                setFormErrors(errors);

                return;
            }

            // If existing, update
            if (contactInput.id) {
                input.onChange(
                    input.value.map((contact) =>
                        contact.id === contactInput.id ? {...contact, ...contactInput} : contact
                    )
                );
            } else {
                // If new, add
                const newContact = {...contactInput, id: Math.random().toString(), isNew: true};
                input.onChange([...input.value, newContact]);
            }

            setIsEditingContactId(null);
            setFormErrors([]);
        });
    };

    const handleSelectContact = (contact) => {
        input.onChange([...input.value, contact]);
        setIsEditingContactId(null);
        setFormErrors([]);
    };

    const handleRemoveContact = (id: ?string) => {
        input.onChange(input.value.filter((contact) => contact.id !== id));
        setIsEditingContactId(null);
        setFormErrors([]);
    };

    const handleEditContact = (contactToEdit: DisplayOptionEntity) => {
        setIsEditingContactId(contactToEdit.id);
        setFormErrors([]);
    };

    const handleCloseEditModal = () => {
        setIsEditingContactId(null);
        setFormErrors([]);
    };

    const removeError = (field: string) => {
        setFormErrors(
            formErrors.filter((error) => error.field !== field && error.field !== FIELD_ALL)
        );
    };

    return (
        <Stack spacing={12}>
            {props.input.value.map((contact) => {
                if (contact.id === isEditingContactId) {
                    return (
                        <ContactCardCreateable
                            key={contact.id}
                            temporaryContact={contact}
                            onRemove={() => handleRemoveContact(contact.id)}
                            onCreateContact={handleCreateContact}
                            handleCloseEditModal={handleCloseEditModal}
                            hasRelatedAccounts={false}
                            formErrors={formErrors}
                            removeError={removeError}
                        />
                    );
                } else {
                    return (
                        <ContactCard
                            key={contact.id}
                            contact={contact}
                            onRemove={() => handleRemoveContact(contact.id)}
                            onEdit={() => handleEditContact(contact)}
                            handleCloseEditModal={handleCloseEditModal}
                            isNew={contact.isNew}
                        />
                    );
                }
            })}
            {isEditingContactId === NEW_CONTACT_MAGIC_ID && (
                <ContactCardCreateable
                    onRemove={() => handleRemoveContact(null)}
                    onSearchPotentialContacts={(query: PotentialContactSearchInput) =>
                        getPotentialContacts(query, selectedAccountIds)
                    }
                    matchedContactsFilter={(contacts) =>
                        contacts.filter((contact) => !input.value.find((c) => c.id === contact.id))
                    }
                    onCreateContact={handleCreateContact}
                    onSelectContact={handleSelectContact}
                    hasRelatedAccounts={Boolean(selectedAccounts)}
                    formErrors={formErrors}
                    removeError={removeError}
                />
            )}
            {!isEditingContactId && (
                <EntityPageAddItemButton
                    sectionEntity='contacts'
                    parentItemText='a person'
                    onAddItem={() => {
                        setIsEditingContactId(NEW_CONTACT_MAGIC_ID);
                    }}
                    omitEntityName={true}
                />
            )}
        </Stack>
    );
}
