/* @flow */
// No idea why eslint is complaining about a flow types here.  If you're reading this,
// remove the disable and see what happens.  Maybe it will have been fixed.
/* eslint-disable no-undef */

import * as React from 'react';
import _ from 'underscore';
import moment from 'moment';
import type {FilterObject} from 'nutshell-core/types';
import * as Schema from 'nutshell-core/schema';
import {deserializeUrlParam} from 'nutshell-core/deserialize-url-param';
import {getSingularEntityType} from 'nutshell-core/utils/get-singular-entity-type';
import {getMultipleEntityType} from 'nutshell-core/utils/get-multiple-entity-type';
import {LimitedGroup} from '../limited-group';
import {shouldShowDelimiter} from '../utils/should-show-delimiter';
import FetchedBackboneModel from '../../../../../ui/core/fetched-backbone-model';
import './filter-display-text.css';

type Props = {
    field: Schema.FieldProperties,
    filter: FilterObject,
    entityType?: string,
    isActive: boolean,
    numDisplayed: number,
    shouldShowTitle: boolean,
    displayCountOnly?: boolean,
};

type State = {
    mapLocation: ?string,
};

export class FilterDisplayText extends React.Component<Props, State> {
    static defaultProps = {
        numDisplayed: 2,
        shouldShowTitle: true,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            mapLocation: null,
        };
    }

    UNSAFE_componentWillReceiveProps() {
        const {filter, field} = this.props;
        const value = deserializeUrlParam(filter[Object.keys(filter)[0]], field.type);

        if (field && field.type === 'map') {
            if (!value || typeof value.longitude !== 'string' || typeof value.latitude !== 'string')
                return;
            const endpoint = 'https://a.tiles.mapbox.com/v4/geocode/mapbox.places';
            // const endpoint = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
            const coords = `${value.longitude},${value.latitude}`;
            const uri = `${endpoint}/${encodeURIComponent(coords)}.json?types=place&access_token=${
                MapboxConfig.apiKey
            }`;

            fetch(uri)
                .then((res) => res.json())
                .then(({features}) => {
                    if (features.length) {
                        this.setState({
                            mapLocation: features[0].place_name.replace(', United States', ''),
                        });
                    } else {
                        this.setState({mapLocation: null});
                    }
                });
        }
    }

    render() {
        const {filter, field, isActive} = this.props;
        const value = deserializeUrlParam(filter[Object.keys(filter)[0]], field.type);

        if (!value) return null;

        return (
            <div styleName={isActive ? 'display-text-active' : 'display-text'}>
                {this.getDisplayTextForFilter(field, value)}
            </div>
        );
    }

    getDisplayTextForFilter(field: Schema.FieldProperties, value: any) {
        if (field.name === 'id') {
            return this.getDisplayTextForId(field, value);
        } else if (field.name === 'omittedId') {
            return this.getDisplayTextForOmittedId(field, value);
        }

        switch (field.type) {
            case 'relationship':
                return this.getDisplayTextForRelationship(field, value);
            case 'date-time':
                return this.getDisplayTextForDateTime(field, value);
            case 'string':
                return this.getDisplayTextForString(field, value);
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            case 'text':
                return this.getDisplayTextForString(field, value);
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            case 'customField':
                return this.getDisplayTextForString(field, value);
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            case 'number':
            case 'integer':
                return this.getDisplayTextForNumber(field, value);
            case 'phone':
                return this.getDisplayTextForPhone(field, value);
            case 'currency':
                return this.getDisplayTextForCurrency(field, value);
            case 'enum':
                return this.getDisplayTextForEnum(field, value);
            case 'boolean':
                return this.getDisplayTextForBoolean(field, value);
            case 'location':
                return this.getDisplayTextForLocation(field, value);
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            case 'map':
                return this.getDisplayTextForMap(field, value);
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            case 'emailTemplate':
                return this.getDisplayTextForEmailTemplate(field, value);
            default:
                return field.title;
        }
    }

    getDisplayTextForId(unused: any, value: any) {
        const count = value && value.data && value.data.length ? value.data.split(',').length : 0;
        let noun = count === 1 ? 'item' : 'items';
        if (this.props.entityType) {
            noun =
                count === 1
                    ? getSingularEntityType(this.props.entityType)
                    : getMultipleEntityType(this.props.entityType);
        }

        const verb = count === 1 ? 'is' : 'are';

        return `Only ${count} selected ${noun} ${verb} being shown`;
    }

    getDisplayTextForOmittedId(unused: any, value: any) {
        const count = value && value.data && value.data.length ? value.data.split(',').length : 0;
        let noun = count === 1 ? 'item' : 'items';
        if (this.props.entityType) {
            noun =
                count === 1
                    ? getSingularEntityType(this.props.entityType)
                    : getMultipleEntityType(this.props.entityType);
        }

        return `Skipping ${count} omitted ${noun}`;
    }

    getDisplayTextForRelationship(field: Schema.RelationshipField, value: any) {
        const typesWithoutManagedCollections = ['accounts', 'contacts'];
        const normalizedValue = transformNegatableFilterObjectToLegacyFilterObject(value);

        // If our relationship field is one WITHOUT a managedCollection, we have to
        // actually fetch the model from its id to get the name.
        if (_.contains(typesWithoutManagedCollections, field.name)) {
            return this.getDisplayTextForNonManagedCollectionRelationship(field, normalizedValue);
        } else {
            return this.getDisplayTextForManagedCollectionRelationship(field, normalizedValue);
        }
    }

    getDisplayTextForNonManagedCollectionRelationship(field: Schema.RelationshipField, value: any) {
        const {title} = field;
        const models = value.data
            .filter((id) => id)
            .map((id) => {
                if (id === '-') {
                    return <span key={id}>None</span>;
                }
                const entityName = <FetchedBackboneModel id={id} />;

                return <span key={id}>{entityName}</span>;
            });

        return (
            <div styleName='text-container'>
                {this.props.shouldShowTitle ? <span>{title}:&nbsp; </span> : undefined}
                {this.props.displayCountOnly ? (
                    `(${models.length})`
                ) : (
                    <LimitedGroup
                        models={models}
                        shouldTruncate={true}
                        numDisplayed={this.props.numDisplayed}
                    />
                )}
            </div>
        );
    }

    getDisplayTextForManagedCollectionRelationship(field: Schema.RelationshipField, value: any) {
        const {title, relationships, nullOptionText} = field;
        const numDisplayed = this.props.numDisplayed;

        // Get collection from managedCollections
        const collection = relationships.reduce((list, col) => {
            const collectionName = col.charAt(0).toUpperCase() + col.slice(1);
            if (
                Nut.managedCollections[collectionName] &&
                Nut.managedCollections[collectionName].models
            ) {
                return list.concat(Nut.managedCollections[collectionName].models);
            }

            return list;
        }, []);

        // Filter invalid models
        const filteredModels = value.data.filter((id) => {
            return collection.find((cmodel) => cmodel.id === id) || (nullOptionText && id === '-');
        });

        // Get model names
        const models = filteredModels.map((item, index) => {
            let textSpan;
            if (nullOptionText && item === '-') {
                textSpan = <span>{nullOptionText}</span>;
            } else {
                const model = collection.find((cmodel) => cmodel.id === item);
                const text = model ? model.get('name') : '\xa0'; // non-breaking space to preserve height
                textSpan = <span>{text}</span>;
            }

            return (
                <span key={item}>
                    {textSpan}
                    {shouldShowDelimiter(index, filteredModels.length) ? ', ' : undefined}
                </span>
            );
        });

        const displayCount = value.none ? 'none' : `(${models.length || '…'})`;

        return (
            <div styleName='text-container'>
                {this.props.shouldShowTitle ? <span>{title}:&nbsp; </span> : undefined}
                {this.props.displayCountOnly ? (
                    displayCount
                ) : (
                    <LimitedGroup
                        models={models}
                        shouldTruncate={true}
                        numDisplayed={numDisplayed}
                    />
                )}
            </div>
        );
    }

    getDisplayTextForDateTime(field: Schema.DateTimeField, value: any) {
        const {nullOptionText} = field;
        const {selected: timeFilter, data: filterValue} = value;

        let displayText = '';

        if (timeFilter === 'between') {
            const startDateText = moment(filterValue.startDate * 1000).format('M/DD/YY');
            const endDateText = moment(filterValue.endDate * 1000).format('M/DD/YY');

            displayText = `${startDateText} – ${endDateText}`;
        } else if (timeFilter === 'never') {
            displayText = `${nullOptionText || 'None'}`;
        } else if (timeFilter === 'current') {
            displayText = `Current ${filterValue.selected}`;
        } else if (timeFilter === 'todate') {
            displayText = `${filterValue.selected}-to-date`;
        } else {
            // Don't display '1 weeks' if singular period value
            const timePeriod =
                Number(filterValue.data) === 1
                    ? filterValue.selected.substr(0, filterValue.selected.length - 1)
                    : filterValue.selected;

            if (value.selected === 'moreThan') {
                displayText = `Newer than ${filterValue.data} ${timePeriod} ago`;
            } else if (value.selected === 'lessThan') {
                displayText = `Older than ${filterValue.data} ${timePeriod} ago`;
            } else if (value.selected === 'within') {
                displayText = `Within the last ${filterValue.data} ${timePeriod}`;
            } else if (value.selected === 'next') {
                displayText = `In the next ${filterValue.data} ${timePeriod}`;
            }
        }

        return (
            <DisplayText
                title={field.title}
                shouldShowTitle={this.props.shouldShowTitle}
                displayText={displayText}
            />
        );
    }

    getDisplayTextForEmailTemplate(field: Schema.FieldProperties, value: any) {
        const template = <FetchedBackboneModel id={value} />;

        return (
            <span key={value}>
                {this.props.shouldShowTitle ? <span>{field.title}:&nbsp;</span> : undefined}
                {template}
            </span>
        );
    }

    getDisplayTextForString(field: Schema.StringField, value: any) {
        if (value.selected === 'null') {
            return field.nullOptionText;
        }

        if (value.selected === '*') {
            return field.anyOptionText;
        }

        const displayText = `${value.selected.toLowerCase()} "${value.data}"`;

        return (
            <DisplayText
                title={field.title}
                shouldShowTitle={this.props.shouldShowTitle}
                displayText={displayText}
            />
        );
    }

    // Difference from text is that we don't want quotes around value
    getDisplayTextForNumber(field: Schema.IntegerField, value: any) {
        const displayText = `${value.selected.toLowerCase()} ${value.data}${
            field.name === 'confidence' ? '%' : ''
        }`;

        return (
            <DisplayText
                title={field.title}
                shouldShowTitle={this.props.shouldShowTitle}
                displayText={displayText}
            />
        );
    }

    getDisplayTextForPhone(field: Schema.PhoneField, value: any) {
        if (value.selected === 'null') {
            return field.nullOptionText;
        }

        const displayText = `${value.selected === 'Contains' ? 'starts with' : 'equals'} "${
            value.data
        }"`;

        return (
            <DisplayText
                title={field.title}
                shouldShowTitle={this.props.shouldShowTitle}
                displayText={displayText}
            />
        );
    }

    getDisplayTextForCurrency(field: Schema.CurrencyField, value: any) {
        if (value.selected === 'null') {
            return field.nullOptionText;
        }

        const displayText = `${value.selected.toLowerCase()} ${
            window.localeData ? window.localeData.currencySymbol : ''
        }${value.data}`;

        return (
            <DisplayText
                title={field.title}
                shouldShowTitle={this.props.shouldShowTitle}
                displayText={displayText}
            />
        );
    }

    getDisplayTextForEnum(field: Schema.EnumField, value: any) {
        const normalizedValue = transformNegatableFilterObjectToLegacyFilterObject(value);
        const values = normalizedValue.data.filter((val) => val);
        const models = values.map((val, index) => {
            let text;
            const valueEnum = field.enum.find((e) => typeof e === 'object' && e.id === val);
            if (field.isNullable && val === '-') {
                text = 'None';
            } else if (
                field.name === 'milestone' ||
                field.name === 'outcome' ||
                field.name === 'stageset'
            ) {
                text = <FetchedBackboneModel id={val} />;
                // $FlowFixMe upgrading Flow to v0.92.1 on web
            } else if (field.name === 'status' && field.schema === 'dashboard') {
                const itemText = `${val.substr(0, 1).toUpperCase()}${val.substr(1, val.length)}`;
                text = itemText;
            } else if (valueEnum && typeof valueEnum === 'object') {
                text = valueEnum.name;
            } else {
                text = val;
            }

            const childKey = `${index}_${val}`;

            return (
                <span key={childKey}>
                    {text}
                    {shouldShowDelimiter(index, values.length) ? ', ' : undefined}
                </span>
            );
        });

        return (
            <div styleName='text-container'>
                {this.props.shouldShowTitle ? <span>{field.title}:&nbsp;</span> : undefined}
                <LimitedGroup
                    models={models}
                    shouldTruncate={true}
                    numDisplayed={this.props.numDisplayed}
                />
            </div>
        );
    }

    getDisplayTextForBoolean(field: Schema.BooleanField, value: any) {
        const booleanValue = value ? 'True' : 'False';

        if (this.props.shouldShowTitle) {
            return `${field.title} = ${booleanValue}`;
        }

        return booleanValue;
    }

    getDisplayTextForLocation(field: Schema.LocationField, value: any) {
        const {selected, data} = value;

        if (selected === 'range') {
            const kilometers = Number(data.selected) * 1.6;
            const displayText = `Within ${data.selected}mi (${kilometers}km) of ${data.data.locationString}`;

            return (
                <DisplayText
                    title={field.title}
                    shouldShowTitle={this.props.shouldShowTitle}
                    displayText={displayText}
                />
            );
        } else if (selected === 'noLocation') {
            const displayText = 'No location specified';

            return (
                <DisplayText
                    title={field.title}
                    shouldShowTitle={this.props.shouldShowTitle}
                    displayText={displayText}
                />
            );
        } else if (selected === 'exactMatch') {
            // Displays either 'Any (country|state|city)' or the exact match selected
            const userFriendlyLocation =
                data[data.selected] === '*' ? `Any ${data.selected}` : data[data.selected];

            const selectedDisplay = {
                country: 'Country',
                state: 'State',
                city: 'City',
                postalCode: 'Postal code',
                areaCode: 'Area code',
            }[data.selected];

            return `${selectedDisplay}: ${userFriendlyLocation}`;
        }
    }

    getDisplayTextForMap(field: Schema.FieldProperties, value: any) {
        if (!value || !value.radius) return null;

        let displayText = `Some ${value.radius}mi (${Math.round(value.radius * 1.62)}km) radius`;
        const location = this.state.mapLocation;
        if (location) {
            displayText = `Within ${value.radius}mi (${Math.round(
                value.radius * 1.62
            )}km) of ${location}`;
        }

        return (
            <DisplayText
                title={field.title}
                shouldShowTitle={this.props.shouldShowTitle}
                displayText={displayText}
            />
        );
    }
}

type DisplayTextProps = {
    shouldShowTitle: boolean,
    title: string,
    displayText: string,
};

function DisplayText(props: DisplayTextProps) {
    if (!props.shouldShowTitle) {
        return <span>{props.displayText}</span>;
    }

    return (
        <span>
            {props.title}: {props.displayText}
        </span>
    );
}

/**
 * Helper function to transform a "new-school" FilterObject shape to the
 * old-school version that this component expects. This is a band-aid, as this
 * component will be removed when the filter drawer takes over.
 *
 * @param  {Object} value         New-school filter object
 * @return {Object}               Old school filter object
 */
function transformNegatableFilterObjectToLegacyFilterObject(value: any) {
    return {
        ...value,
        data: value.data
            ? value.data.map((item) => {
                  return item.data;
              })
            : [],
    };
}
