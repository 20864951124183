/* @flow */
import {Field} from 'redux-form';
import * as React from 'react';
import {useSelector} from 'react-redux';

import type {
    EntityType,
    GetFields_createModalFields as SelectableField,
} from 'nutshell-graphql-types';

import {keyWithIds} from 'nutshell-core/schema';
import {wrapWithProperties, addName} from 'nutshell-core/schema/schema-api';
import type {Market} from 'nutshell-core/types';

import {
    ReduxFormTextField,
    ReduxFormDateTime,
    ReduxFormNumberField,
} from 'shells/redux-form-fields';
import {nonNull} from 'shells/form/validation';

import type {NutshellState} from '../../../store';
import * as dashboardSelectors from '../../master-dashboard/dashboard-selectors';
import {
    DecisionFieldSelect,
    GraphQLRelationshipSelect,
    ProductSelectField,
} from '../../core/form/fields';

import {AUTO_RESOLVE_ID} from '../../core/form/fields/consts';
import {EntitySelectField} from '../../core/form/fields/entity-select-field';
import {TagSelectField} from '../../core/form/fields/tag-select-field';
import {AssigneeSelectField} from '../../core/form/fields/assignee-select-field';
import {LeadNameField} from '../../core/form/fields/lead-name-field';
import type {EntityFormType} from '../../creator/types';
import {translateEntityFormTypeToFieldEntityType} from '../../creator/customize-fields/helpers';
import {RichContactSelectField} from '../../core/form/fields/rich-contact-select-field';
import {StagesetPriorityFields} from './stageset-priority-fields';
import {getFetcherFunctionForRelationship, getFetcherFunctionReturnKey} from './consts';
import type {DisplayableField, SchemaField, SchemaPropertyWithRequired, FieldType} from './types';

export function isString(value: ?string): boolean {
    return typeof value === 'string' && value.trim().length > 0;
}

/**
 * Fetches the schema fields for the given entity type.
 */
export const useGetSchemaFieldsForEntityType = (type: EntityFormType) => {
    const contactsSchema = useSelector((state: NutshellState) =>
        dashboardSelectors.getContactsSchema(state)
    );
    const leadsSchema = useSelector((state: NutshellState) =>
        dashboardSelectors.getLeadsSchema(state)
    );
    const accountsSchema = useSelector((state: NutshellState) =>
        dashboardSelectors.getAccountsSchema(state)
    );

    switch (type) {
        case 'contacts':
            return translateFieldsAndFilterForEntityType(contactsSchema, 'CONTACT');
        case 'leads':
            return translateFieldsAndFilterForEntityType(leadsSchema, 'LEAD');
        case 'accounts':
        default:
            return translateFieldsAndFilterForEntityType(accountsSchema, 'ACCOUNT');
    }
};

/**
 * Takes the schema and returns an array of fields.
 */
export function translateSchemaToFields(schema: Object): SchemaField[] {
    const entitySchemaWithProperties = schema ? wrapWithProperties(schema) : undefined;
    const entitySchemaFields = entitySchemaWithProperties
        ? addName(entitySchemaWithProperties)
        : undefined;
    const entityProperties = entitySchemaFields && entitySchemaFields.properties;

    return entityProperties
        ? Object.keys(entityProperties).map((key) => entityProperties[key])
        : [];
}

/**
 * Takes the schema returns an array of fields for that entity type. Lead fields
 * don't include any with a bucket.
 */
export const translateFieldsAndFilterForEntityType = (
    schema: Object,
    entityType: EntityType
): SchemaField[] => {
    const schemaFields = translateSchemaToFields(schema);

    return entityType === 'LEAD' ? schemaFields.filter((field) => !field.bucket) : schemaFields;
};

/**
 * Takes the schema and the selected fields and returns an array of addable selected fields with
 * schema values added.
 */
export const addSchemaValuesToSelectedFields = (
    schema: Object,
    selectedFields: SelectableField[]
): SchemaPropertyWithRequired[] => {
    const schemaKeyedWithIds = keyWithIds(schema);

    return (
        selectedFields
            // safety to make sure each field has valid schema values
            .filter((selectedField) => schemaKeyedWithIds[selectedField.fieldId])
            .map((selectedField) => ({
                ...schemaKeyedWithIds[selectedField.fieldId],
                isRequired: selectedField.isRequired,
            }))
    );
};

/**
 * Takes an array of selected fields with schema values included, and returns an array of
 * fields with the values we need to display the fields in the form.
 */
export const translateSchemaFieldToFormField = (input: {
    fields: SchemaPropertyWithRequired[],
    hasPriorityField: boolean,
    entityType: EntityFormType,
    nameFieldIsReadOnly?: boolean,
    companyFieldIsReadOnly?: boolean,
    personFieldIsReadOnly?: boolean,
    relatedEntity?: 'contacts' | 'accounts',
}): DisplayableField[] => {
    const {
        fields,
        hasPriorityField,
        entityType,
        nameFieldIsReadOnly,
        companyFieldIsReadOnly,
        personFieldIsReadOnly,
        relatedEntity,
    } = input;

    return (
        fields
            // safety to make sure each field is addable
            .filter((field) => field && field.isAddable)
            .map((field) => {
                const fieldKey = getFieldKey(field.isCustomField, field.name, entityType);

                return {
                    fieldKey,
                    title: getTitle(field.name, field.title, entityType),
                    placeholder: field.placeholder,
                    name: field.name,
                    type: field.type,
                    enumOptions: field.enum,
                    isRequired: getIsRequired(field.name, field.isRequired, nameFieldIsReadOnly),
                    validateFunction: getValidateFunction(
                        field.name,
                        field.isRequired,
                        nameFieldIsReadOnly
                    ),
                    component: getFormFieldComponent(field.type, field.name, fieldKey, entityType),
                    relationshipKey: getRelationshipKey(field.type, field.name),
                    fetchFunction: getRelationshipFetcherFunction(field.type, field.name),
                    isMulti: getIsMulti(field.name, field.isAndable, entityType),
                    isCustomComponent: getIsCustomComponent(field.name),
                    isReadOnly: getIsReadOnly(
                        field.name,
                        nameFieldIsReadOnly,
                        companyFieldIsReadOnly,
                        personFieldIsReadOnly
                    ),
                    relatedEntity: getRelatedEntity(field.name, relatedEntity),
                    hasPriorityField,
                };
            })
    );
};

/**
 * Takes the schema and selectedFields and returns an array of
 * fields with the values we need to display the fields in the form.
 */
export const getFieldsToDisplay = (input: {
    schema: Object,
    selectedFields: SelectableField[],
    entityType: EntityFormType,
    selectedContacts?: string[],
    selectedAccounts?: string[],
    nameFieldIsReadOnly?: boolean,
    companyFieldIsReadOnly?: boolean,
    personFieldIsReadOnly?: boolean,
    relatedEntity?: 'contacts' | 'accounts',
}): DisplayableField[] => {
    const {
        schema,
        selectedFields,
        entityType,
        selectedContacts,
        selectedAccounts,
        nameFieldIsReadOnly,
        companyFieldIsReadOnly,
        personFieldIsReadOnly,
        relatedEntity,
    } = input;

    if (selectedContacts && selectedContacts.length > 0) {
        if (!selectedFields.find((field) => field.fieldId === 'contacts')) {
            selectedFields.push({
                fieldId: 'contacts',
                isRequired: false,
                isRemoveable: true,
                __typename: 'SelectableField',
            });
        }
    }

    if (selectedAccounts) {
        if (!selectedFields.find((field) => field.fieldId === 'accounts')) {
            selectedFields.push({
                fieldId: 'accounts',
                isRequired: false,
                isRemoveable: true,
                __typename: 'SelectableField',
            });
        }
    }

    const selectedFieldsWithSchemaValues = addSchemaValuesToSelectedFields(schema, selectedFields);
    const hasPriorityField = Boolean(selectedFields.find((field) => field.fieldId === 'priority'));

    return translateSchemaFieldToFormField({
        fields: selectedFieldsWithSchemaValues,
        hasPriorityField,
        entityType,
        nameFieldIsReadOnly,
        companyFieldIsReadOnly,
        personFieldIsReadOnly,
        relatedEntity,
    });
};

/**
 * This key is used to map the field to the proper data key when saving. Emails, addresses,
 * phones, and urls are saved as arrays, linked fields are saved under the links key, and custom
 * fields are saved under the customFields key. Other fields are saved using the name.
 */
export const getFieldKey = (isCustomField: boolean, name: string, entityType: EntityFormType) => {
    if (isCustomField) {
        const escapedName = name.replace(/(['"\\])/g, '\\$1');

        return `customFields["${escapedName}"]`;
    }

    if (name === 'email') {
        return 'emails[0].value';
    }
    if (name === 'address') {
        return 'addresses[0].value';
    }
    if (name === 'phone') {
        return 'phones[0].value';
    }
    if (name === 'url') {
        return 'urls[0].value';
    }
    if (name === 'products') {
        return `links.productMaps`;
    }

    const linkedFields = [
        'accounts',
        'accountType',
        'competitors',
        'contacts',
        'industry',
        'market',
        'owner',
        'participants',
        'sources',
        'stageset',
        'tags',
        'territory',
    ];

    if (linkedFields.includes(name)) {
        return `links.${name}`;
    }

    if (entityType === 'leads' && name === 'name') {
        return 'description';
    }

    return name;
};

/**
 * If the name field is read-only, we can't require it or the form won't submit
 */
export const getIsRequired = (
    fieldName: string,
    isRequired: boolean,
    nameFieldIsReadOnly?: boolean
) => {
    if (fieldName === 'name' && nameFieldIsReadOnly) {
        return false;
    }

    return isRequired;
};

/**
 * If the field is required, we validate that is has a value.
 */
export const getValidateFunction = (
    fieldName: string,
    isRequired: boolean,
    nameFieldIsReadOnly?: boolean
) => {
    if (fieldName === 'name' && nameFieldIsReadOnly) {
        return undefined;
    }

    return isRequired ? [nonNull] : undefined;
};

/**
 * Returns the component needed to render the field.
 */
export const getFormFieldComponent = (
    fieldType: FieldType,
    fieldName: string,
    fieldKey: string,
    entityType: EntityFormType
) => {
    switch (fieldType) {
        case 'string':
        case 'text':
        case 'email':
        case 'phone':
        case 'location':
            if (fieldKey === 'description' && entityType === 'leads') {
                return LeadNameField;
            } else {
                return ReduxFormTextField;
            }
        case 'date-time':
            return ReduxFormDateTime;
        case 'currency':
        case 'integer':
            return ReduxFormNumberField;

        case 'relationship': {
            if (fieldName === 'tags') {
                return TagSelectField;
            } else if (fieldName === 'contacts') {
                if (NutClientConfig.hasEnhancedLeadCreateModal && entityType === 'leads') {
                    return RichContactSelectField;
                } else {
                    return EntitySelectField;
                }
            } else if (fieldName === 'accounts' || fieldName === 'participants') {
                return EntitySelectField;
            } else if (fieldName === 'products') {
                return ProductSelectField;
            } else if (fieldName === 'owner') {
                return AssigneeSelectField;
            } else {
                return GraphQLRelationshipSelect;
            }
        }
        case 'enum': {
            if (fieldName === 'stageset') {
                return StagesetPriorityFields;
            } else {
                return DecisionFieldSelect;
            }
        }
    }
};

const getRelationshipKey = (fieldType: FieldType, fieldName: string) => {
    if (fieldType === 'relationship') {
        return getFetcherFunctionReturnKey(fieldName);
    }
};

const getRelationshipFetcherFunction = (fieldType: FieldType, fieldName: string) => {
    if (fieldType === 'relationship') {
        return getFetcherFunctionForRelationship(fieldName);
    }
};

const getIsMulti = (fieldName: string, isAndable: boolean, entityType: EntityFormType) => {
    if (fieldName === 'contacts' || fieldName === 'accounts') {
        return entityType === 'leads';
    }

    return (
        fieldName === 'participants' ||
        fieldName === 'competitors' ||
        fieldName === 'sources' ||
        isAndable
    );
};

// Stageset is a combo stageset/priority field, products is a FieldArray
const getIsCustomComponent = (fieldName: string) => {
    return fieldName === 'stageset' || fieldName === 'products';
};

// Accounts, contacts, and name can be read-only when creating multiple leads
const getIsReadOnly = (
    fieldName: string,
    nameFieldIsReadOnly?: boolean,
    companyFieldIsReadOnly?: boolean,
    personFieldIsReadOnly?: boolean
) => {
    if (fieldName === 'accounts') {
        return companyFieldIsReadOnly;
    }

    if (fieldName === 'contacts') {
        return personFieldIsReadOnly;
    }

    if (fieldName === 'name') {
        return nameFieldIsReadOnly;
    }
};

// Used when creating multiple leads
const getRelatedEntity = (fieldName: string, relatedEntity?: 'contacts' | 'accounts') => {
    if (fieldName === 'name') {
        return relatedEntity;
    }
};

export const getTitle = (fieldName: string, title: string, entityType: EntityFormType) => {
    if (fieldName === 'email') {
        return 'Email';
    }

    if (fieldName === 'accounts' && entityType === 'contacts') {
        return 'Company';
    }

    if (fieldName === 'contacts' && entityType === 'accounts') {
        return 'Person';
    }

    return title;
};

/**
 * These values are set this way so we can spread the object into the field component.
 * This way we are only setting values that are needed for each field and not passing
 * unused props which can cause errors with the way props are passed.
 */
const getOneOffValues = (input: {
    field: DisplayableField,
    entityType: EntityFormType,
    currentMarket: ?Market,
}) => {
    const {field, entityType, currentMarket} = input;

    // This is the lead name field
    if (field.fieldKey === 'description' && entityType === 'leads') {
        return {
            focusOnMount: true,
            noMargin: true,
            isReadOnly: field.isReadOnly,
            relatedEntity: field.relatedEntity,
        };
    }

    if (field.name === 'name') {
        return {
            focusOnMount: true,
            noMargin: true,
        };
    }

    if (field.name === 'confidence') {
        return {
            formatType: 'percent',
            format: null,
        };
    }

    if (field.name === 'tags') {
        return {
            tagType: translateEntityFormTypeToFieldEntityType(entityType),
            shouldAnd: true,
        };
    }

    if (field.name === 'stageset') {
        return {
            hasPriorityField: field.hasPriorityField,
        };
    }

    if (field.type === 'location') {
        return {
            multiline: true,
            rows: 2,
        };
    }

    if (field.type === 'currency') {
        return {
            formatType: 'currency',
        };
    }

    if (field.name === 'owner') {
        return {
            showAutoAssign: true,
            fieldKey: field.fieldKey,
        };
    }

    if (field.type === 'text') {
        return {
            multiline: true,
        };
    }

    if (field.type === 'enum') {
        return {
            enumOptions: field.enumOptions,
            isMulti: field.isMulti,
        };
    }

    if (field.name === 'accounts' || field.name === 'contacts' || field.name === 'participants') {
        return {
            isMulti: field.isMulti,
            entityType: field.name,
            isReadOnly: field.isReadOnly,
        };
    }

    if (field.name === 'products') {
        return {
            isMulti: field.isMulti,
            currentMarket,
        };
    }

    if (field.type === 'relationship') {
        return {
            relationshipKey: field.relationshipKey,
            fetchFunction: field.fetchFunction,
            isMulti: field.isMulti,
        };
    }

    if (field.type === 'date-time') {
        return {
            shouldOmitTime: true,
            persistOnSelect: false,
        };
    }
};

/**
 * Gets the component with necessary props for each field in the form.
 */
export const getFieldComponent = (input: {
    field: DisplayableField,
    entityType: EntityFormType,
    currentMarket?: ?Market,
}) => {
    const {field} = input;

    // Safety to make sure each field has a correspongding component
    // Priority field is a one-off that is displayed in the stageset field
    if (!field.component || field.name === 'priority') {
        return null;
    }

    const props = getOneOffValues(input);

    if (field.isCustomComponent) {
        const Component = field.component;

        return (
            <Component
                key={field.fieldKey}
                fieldKey={field.fieldKey}
                placeholder={field.placeholder}
                title={field.title}
                isRequired={field.isRequired}
                validate={field.validateFunction}
                {...props}
            />
        );
    }

    return (
        <Field
            key={field.fieldKey}
            name={field.fieldKey}
            title={field.title}
            placeholder={field.placeholder}
            isRequired={field.isRequired}
            validate={field.validateFunction}
            component={field.component}
            {...props}
        />
    );
};

// Transforms a link object to a backend-compatible value.
// Handles special cases where the link id is AUTO_RESOLVE_ID.
const transformEntityLink = (link, type) => {
    if (!link) return undefined;

    const linkValue = isString(link) ? link : link.id;

    if (linkValue === AUTO_RESOLVE_ID) {
        switch (type) {
            case 'owner':
                return null;
            case 'stageset':
                return undefined;
            default:
                return linkValue;
        }
    }

    return linkValue;
};

export const getLinksForSubmission = (links: ?Object, entityType: EntityFormType) => {
    const getTags = () => {
        if (!links || !links.tags) {
            return [];
        } else {
            return links.tags.filter((tag) => tag).map((tag) => tag.id);
        }
    };

    // The old form saves owner as an id, the new form saves it as an array of strings
    const getOwner = () => {
        if (!links) {
            return undefined;
        }

        return transformEntityLink(links.owner, 'owner');
    };

    if (entityType === 'contacts') {
        // The old form saves accounts as an array of objects, the new form saves it as an array of strings
        const getAccountsForContactForm = () => {
            if (!links || !links.accounts) {
                return undefined;
            } else if (Array.isArray(links.accounts)) {
                return links.accounts.filter((account) => account).map((account) => account.id);
            } else {
                return [links.accounts.id];
            }
        };

        return {
            accounts: getAccountsForContactForm(),
            territory: links && links.territory,
            owner: getOwner(),
            tags: getTags(),
        };
    }

    if (entityType === 'accounts') {
        // The old form saves contacts as an array of objects, the new form saves it as an array of strings
        const getContactsForAccountForm = () => {
            if (!links || !links.contacts) {
                return undefined;
            } else if (Array.isArray(links.contacts)) {
                return links.contacts.filter((contact) => contact).map((contact) => contact.id);
            } else {
                return [links.contacts.id];
            }
        };

        return {
            contacts: getContactsForAccountForm(),
            owner: getOwner(),
            industry: links && links.industry,
            tags: getTags(),
            accountType: links && links.accountType,
            territory: links && links.territory,
        };
    }

    if (entityType === 'leads') {
        // The old form saves accounts as an array of objects, the new form saves it as an array of strings
        const getAccountsForLeadForm = () => {
            if (!links || !links.accounts) {
                return [];
            } else if (Array.isArray(links.accounts)) {
                return links.accounts.filter((account) => account).map((account) => account.id);
            } else {
                return [links.accounts.id];
            }
        };

        // Contacts link consists of an array of objects and strings where
        // objects represent new contacts we want to create and strings
        // represent existing ones.
        const getContactsForLeadForm = () => {
            if (!links || !links.contacts) {
                return [];
            } else if (Array.isArray(links.contacts)) {
                return links.contacts
                    .filter((contact) => contact)
                    .map((contact) => {
                        if (NutClientConfig.hasEnhancedLeadCreateModal) {
                            if (contact.isNew) {
                                return {
                                    name: contact.name,
                                    description: contact.description,
                                    phone: contact.phoneNumber,
                                    email: contact.email,
                                    type: 'contact',
                                };
                            }
                        }

                        return contact.id;
                    });
            } else {
                return [links.contacts.id];
            }
        };

        // The old form saves sources as an array of objects, the new form saves it as an array of strings
        const getSources = () => {
            if (!links || !links.sources) {
                return [];
            } else {
                return links.sources.map((source) =>
                    typeof source === 'string' ? source : source.id
                );
            }
        };

        // The old form saves stageset as an id, the new form saves it as an array of strings
        const getStageset = () => {
            if (!links) {
                return undefined;
            }

            return transformEntityLink(links.stageset, 'stageset');
        };

        const getCompetitorMaps = () => {
            if (!links || !links.competitors) {
                return [];
            }

            return links.competitors.map((competitor) => {
                if (typeof competitor === 'string') {
                    return {
                        type: 'competitorMaps',
                        name: competitor,
                        links: {
                            competitor: competitor,
                        },
                    };
                } else {
                    return {
                        type: 'competitorMaps',
                        name: competitor.name,
                        links: {
                            competitor: competitor.id,
                        },
                    };
                }
            });
        };

        const getProducts = () => {
            if (!links || !links.productMaps) {
                return [];
            }

            return links.productMaps.filter((product) => product);
        };

        return {
            sources: getSources(),
            contacts: getContactsForLeadForm(),
            accounts: getAccountsForLeadForm(),
            stageset: getStageset(),
            owner: getOwner(),
            tags: getTags(),
            competitorMaps: getCompetitorMaps(),
            market: links && links.market ? links.market.id : undefined,
            productMaps: getProducts(),
        };
    }

    if (entityType === 'activities') {
        // Safety to remove any null values
        const getParticipantsForActivityForm = () => {
            if (!links || !links.participants) {
                return [];
            } else {
                return links.participants
                    .filter((particpant) => particpant)
                    .map((particpant) => particpant.id);
            }
        };

        return {
            participants: getParticipantsForActivityForm(),
        };
    }
};
