/* @flow */

import * as React from 'react';
import {NavLink} from 'react-router-dom';
import styles from '../tab.css';

type Props = {
    id: string,
    // When true, will only match if the path matches the location.pathname exactly.
    exact: boolean,
    pathname: string,
    // Used if the tab inherits the given search
    search?: string,
    replace?: boolean,
    children?: React.Node,
};

// Note - this needs to be React.Component, because React Router
// internally will check on re-render if the tab is active,
// but the props will not change.
export class RoutedTab extends React.Component<Props> {
    static defaultProps = {
        replace: true,
        exact: false,
    };

    render() {
        return (
            <NavLink
                key={this.props.id}
                to={{
                    pathname: this.props.pathname,
                    search: this.props.search,
                }}
                exact={this.props.exact}
                // When true, clicking the link will replace the current
                // entry in the history stack instead of adding a new one.
                replace={this.props.replace}
                className={styles.tab}
                activeClassName={styles['tab--active']}
            >
                {this.props.children}
            </NavLink>
        );
    }
}
