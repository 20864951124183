/* @flow */

import * as React from 'react';

import type {DisplayOptionEntity} from 'nutshell-core/utils';

import {Card} from '../../card';
import {Button} from '../../button';
import {PeepTwoLine} from '../../peep/peep-two-line';

import './potential-contacts.css';

type Props = {|
    potentialContacts: DisplayOptionEntity[],
    onSelectContact: (contact: DisplayOptionEntity) => void,
|};

export function PotentialContacts(props: Props) {
    const {potentialContacts, onSelectContact} = props;

    return (
        <Card hasBorder={true} fitHeight={true}>
            <div className='flex flex-dir-col align-start'>
                {potentialContacts.map((contact) => (
                    <div styleName={contact.isDisabled ? '' : 'result'} key={contact.id}>
                        <Button
                            variant='text-secondary'
                            onClick={() => onSelectContact(contact)}
                            preventUnderline={true}
                            isFullWidth={true}
                            noPadding={true}
                            disabled={contact.isDisabled}
                        >
                            <PeepTwoLine
                                name={contact.value}
                                avatarUrl={contact.avatarUrl}
                                initials={contact.initials}
                                type='contacts'
                                relatedEntityName={contact.primaryAccountName}
                                isLinkable={false}
                            />
                        </Button>
                    </div>
                ))}
            </div>
        </Card>
    );
}
