/* @flow */

import * as React from 'react';

import {getChannelNameFromChannel} from 'nutshell-core/channels/helpers';

import {getChannelIconFromChannel} from './helpers';
import type {ChannelType} from './types';

import './channel-icon-with-name.css';

type Props = {|
    channel: ?ChannelType,
    isSmall?: boolean,
|};

export function ChannelIconWithName(props: Props) {
    const {channel, isSmall} = props;

    const iconSize = isSmall ? 13 : 15;
    const Icon = channel ? getChannelIconFromChannel(channel) : undefined;
    const NameTag = isSmall ? 'span' : 'strong';

    return (
        <div styleName={isSmall ? 'channel-icon-with-name--small' : 'channel-icon-with-name'}>
            {Icon ? <Icon size={iconSize} wrapWithDiv={false} /> : undefined}
            <NameTag>{channel ? getChannelNameFromChannel(channel) : 'No channel'}</NameTag>
        </div>
    );
}
