import * as React from 'react';
import PropTypes from 'prop-types';
import {withFormsy, propTypes as formsyPropTypes} from 'formsy-react';

import getClassesMixin from '../../mixins/get-classes';

class FormCheckboxComponent extends React.Component {
    getClasses = getClassesMixin;

    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.bool,
        label: PropTypes.string,
        validationError: PropTypes.string,
        ...formsyPropTypes,
    };

    static defaultProps = {
        value: false,
        label: '',
        validationError: ' ',
    };

    render() {
        const classes = this.getClasses('ui-input');

        return (
            <label className='ui-input-checkbox-label' onClick={this.props.onClick}>
                <input
                    type='checkbox'
                    {...this.props}
                    className={classes}
                    checked={this.props.getValue()}
                    onChange={(e) => this.props.setValue(e.target.checked)}
                />
                {this.props.label}
            </label>
        );
    }
}

export const FormCheckbox = withFormsy(FormCheckboxComponent);
