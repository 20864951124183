/* @flow */

import {CHANNELS, CHANNEL_VALUES, CHANNEL_UNKNOWN} from './consts';

// copied from shells/lead-sidebar/sources/types
type ChannelType =
    | 'UNKNOWN'
    | 'SEARCH_ORGANIC'
    | 'SEARCH_PAID'
    | 'SOCIAL_ORGANIC'
    | 'SOCIAL_PAID'
    | 'EMAIL'
    | 'REFERRER'
    | 'TRADITIONAL'
    | 'DIRECT_TO_WEBSITE';

export function getChannelNameFromChannel(channel: ChannelType): string {
    return CHANNEL_VALUES.includes(channel)
        ? CHANNELS[channel].title
        : // Return value of no channel as fallback
          CHANNELS[CHANNEL_UNKNOWN].title;
}

export function getArticleFromChannel(channel: ChannelType): string {
    return CHANNEL_VALUES.includes(channel)
        ? CHANNELS[channel].article
        : // Return value of no channel as fallback
          CHANNELS[CHANNEL_UNKNOWN].article;
}

export function getRemoveConfirmationTextFromChannel(channel: ?ChannelType): string {
    if (!channel) {
        return 'Are you sure you want to remove all sources without a channel? All sources without a channel will always be removed from this lead';
    } else {
        const channelName = getChannelNameFromChannel(channel);

        return `Are you sure you want to remove the ${channelName} channel? All ${channelName} sources will always be removed from this lead`;
    }
}

export function getChannelEnumFromValue(value: number): ChannelType {
    switch (value) {
        case 1:
            return 'SEARCH_ORGANIC';
        case 2:
            return 'SEARCH_PAID';
        case 3:
            return 'SOCIAL_ORGANIC';
        case 4:
            return 'SOCIAL_PAID';
        case 5:
            return 'EMAIL';
        case 6:
            return 'REFERRER';
        case 7:
            return 'TRADITIONAL';
        case 8:
            return 'DIRECT_TO_WEBSITE';
        case 0:
        default:
            return 'UNKNOWN';
    }
}
