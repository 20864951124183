/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {RemoveButton} from '../button';
import {Dialog} from '../dialog';

import type {SectionEntity} from './types';
import {getEntityClassName} from './helpers';

import './section-item.css';

type Props = {|
    sectionEntity: SectionEntity,
    itemHeaderComponent: React.Node,
    onClickRemove: (any) => Promise<*>,
    removeConfirmationText: string,
    content?: React.Node,
|};

export function SectionItem(props: Props) {
    const {sectionEntity, itemHeaderComponent, onClickRemove, removeConfirmationText, content} =
        props;

    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState<boolean>(false);
    const [isDisabled, setIsDisabled] = React.useState<boolean>(false);

    const deleteButtonRef = React.useRef(null);

    const styleNames = classnames({
        'section-item': true,
        'section-item--disabled': isDisabled,
        ...getEntityClassName(sectionEntity),
    });

    return (
        <>
            <div styleName={styleNames}>
                <div styleName='item-header-container'>
                    <div styleName='item-header'>{itemHeaderComponent}</div>
                    <div styleName='remove-button-container'>
                        <RemoveButton
                            size={10}
                            onClick={() => {
                                setIsConfirmingDelete(true);
                            }}
                            getButtonRef={deleteButtonRef}
                        />
                    </div>
                </div>
                {content ? <div styleName='content-container'>{content}</div> : undefined}
            </div>
            {isConfirmingDelete ? (
                <Dialog
                    anchor={deleteButtonRef.current}
                    question={removeConfirmationText}
                    primaryText='Yes, remove'
                    secondaryText='No, don’t remove'
                    onPrimary={() => {
                        setIsDisabled(true);
                        setIsConfirmingDelete(false);

                        // Reset if remove fails
                        onClickRemove().catch(() => {
                            setIsDisabled(false);
                        });
                    }}
                    onSecondary={() => {
                        setIsConfirmingDelete(false);
                    }}
                    isWarning={true}
                />
            ) : undefined}
        </>
    );
}
