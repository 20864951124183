/* @flow */

import * as React from 'react';
import './tab-container.css';

type Props = {
    getRef?: (?HTMLElement) => void,
    children?: React.Node,
};

// Note - this needs to be React.Component, because React Router
// internally will check on re-render if the tab is active,
// but the props will not change.
export class TabContainer extends React.Component<Props> {
    render() {
        return (
            <div styleName='tab-container' ref={this.props.getRef}>
                {this.props.children}
            </div>
        );
    }
}
