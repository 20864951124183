/* @flow */

import * as React from 'react';

import {FormWrapperWithButtons} from '../form';
import {TextField} from './textfield';

type State = {
    value: string,
};

type Props = {|
    name: string,
    title: string,
    onCancel: (e?: SyntheticEvent<*>) => void,
    isSaving: boolean,
    buttonText?: string,
    subtitle?: string,
    placeholder?: string,
    initialValue: string,
    /** This happens just before the button submits the form, so it will run before your form's onSubmit.
    Note: do not set `isSaving` to true inside the onSave handler, it prevents form submission somehow. */
    onSave: (inputValue: string, e?: SyntheticInputEvent<>) => void,
    focusOnMount?: boolean,
    textfieldSize?: 'normal' | 'big',
    requireValue?: boolean,
    inputType?: string,
    errorMessage?: ?string,
    resetOnSaveComplete?: boolean,
|};

export class TextfieldWithButtons extends React.PureComponent<Props, State> {
    textField: ?HTMLInputElement;
    static defaultProps = {
        onSave: () => {},
        initialValue: '',
        textfieldSize: 'normal',
    };

    constructor(props: Props) {
        super();
        this.state = {
            value: props.initialValue,
        };
    }

    componentDidMount() {
        // Not sure why, but autoFocus isn't working, and a setImmediate is needed.
        setImmediate(() => {
            if (this.textField && this.props.focusOnMount) {
                this.textField.focus();
            }
        });
    }

    render() {
        const saveButtonDisabled = this.props.requireValue && this.state.value.length === 0;

        return (
            <FormWrapperWithButtons
                title={this.props.title}
                subtitle={this.props.subtitle}
                errorMessage={this.props.errorMessage ? this.props.errorMessage : ''}
                isSaving={this.props.isSaving}
                onCancel={this.props.onCancel}
                onSaveClick={this.handleSaveClick}
                buttonText={this.props.buttonText}
                saveButtonDisabled={saveButtonDisabled}
                errorSaving={Boolean(this.props.errorMessage)}
                resetOnSaveComplete={this.props.resetOnSaveComplete}
            >
                <TextField
                    id='TitleTextField'
                    name={this.props.name}
                    required={true}
                    placeholder={this.props.placeholder}
                    onFocus={this.handleFocus}
                    value={this.state.value}
                    onChange={this.handleChange}
                    textFieldRef={(ref) => {
                        this.textField = ref;
                    }}
                    onEnter={() => {
                        if (saveButtonDisabled) {
                            return;
                        }
                        this.props.onSave(this.state.value);
                    }}
                    size={this.props.textfieldSize}
                    type={this.props.inputType}
                />
            </FormWrapperWithButtons>
        );
    }

    // This is for selecting the entire input field
    handleFocus = (e: SyntheticInputEvent<>) => {
        e.target.select();
    };

    handleChange = (value: string) => {
        this.setState({value});
    };

    handleSaveClick = (e: SyntheticInputEvent<*>) => {
        this.props.onSave(this.state.value, e);
    };
}
