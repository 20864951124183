/* @flow */

import React from 'react';
import type {UnserializedFilterObject} from 'nutshell-core/types';
import * as Schema from 'nutshell-core/schema';
import {Popover, type LocationEnum} from '../../popover';
import {SelectableListSingle, SelectableListMultiple, type Model} from '../../selectable-list';
import './popover-form.css';

type Props = {
    collection: Model[],
    anchor: ?HTMLElement,
    onSubmit: (?UnserializedFilterObject<*>) => void,
    onClose: () => void,
    field: Schema.FieldProperties,
    filter: ?(string | Object),
    location?: LocationEnum,
    shouldAllowSelectNone?: boolean,
    hasSearch: boolean,
    paginationProps?: {
        hasNextPage: boolean,
        isLoading: boolean,
        onFetchMoreRows: () => void,
    },
};

const SUPPORTED_TYPES = ['relationship', 'enum'];

export class PopoverForm extends React.Component<Props> {
    static defaultProps = {
        location: 'bottom',
        shouldAllowSelectNone: false,
        hasSearch: true,
    };

    render() {
        if (!SUPPORTED_TYPES.includes(this.props.field.type)) {
            return null;
        }

        const isRequired =
            typeof this.props.field.isNullable === 'boolean' &&
            this.props.field.isNullable === false;

        const valueIds = getValueIdsFromFilter(this.props.filter);

        // Add null option to front if necessary
        const collection =
            typeof this.props.field.nullOptionText === 'string'
                ? [
                      {
                          id: '-',
                          name: this.props.field.nullOptionText,
                          type: this.props.field.type,
                      },
                  ].concat(this.props.collection)
                : this.props.collection;

        return (
            <Popover
                anchor={this.props.anchor}
                location={this.props.location}
                onBlur={this.props.onClose}
            >
                <div styleName='form-body'>
                    {this.props.field.isSingleSelect ? (
                        <SelectableListSingle
                            collection={collection}
                            value={valueIds[0]}
                            hasSearch={this.props.hasSearch}
                            autoFocusSearch={true}
                            isNewStyle={true}
                            alwaysHasValue={isRequired}
                            onSelect={this.handleSingleSelect}
                        />
                    ) : (
                        <SelectableListMultiple
                            collection={collection}
                            value={valueIds}
                            hasSearch={this.props.hasSearch}
                            autoFocusSearch={true}
                            isNewStyle={true}
                            alwaysHasValue={isRequired}
                            onSelect={this.handleMultiSelect}
                            shouldAllowSelectNone={this.props.shouldAllowSelectNone}
                            isNoneSelected={Boolean(
                                this.props.filter &&
                                    typeof this.props.filter === 'object' &&
                                    this.props.filter.none
                            )}
                            onSelectNone={this.handleMultiSelectNone}
                            paginationProps={this.props.paginationProps}
                        />
                    )}
                </div>
            </Popover>
        );
    }

    /*
     * If we're clearing out all selections of a multi-select,
     * then we're going to submit with null so that the parent knows to remove
     * the filter.  Otherwise, we'll send back an UnserializedFilterObject.
     *
     * Ideally, we'd just send back the filter itself, but for now we're keeping
     * the interface compatible with the old popover form.
     */
    handleMultiSelect = (selectedIds: string[]) => {
        if (selectedIds.length) {
            this.props.onSubmit({
                [this.props.field.name]: {
                    anyAll: 'any',
                    data: selectedIds.map((id) => ({data: id})),
                },
            });
        } else {
            this.props.onSubmit(null);
        }
    };

    handleSingleSelect = (selectedId: ?string) => {
        if (selectedId) {
            this.props.onSubmit({
                [this.props.field.name]: {
                    anyAll: 'any',
                    data: [{data: selectedId}],
                },
            });
        } else {
            this.props.onSubmit(null);
        }
        this.props.onClose();
    };

    handleMultiSelectNone = (isSelected: boolean) => {
        if (isSelected) {
            this.props.onSubmit({
                [this.props.field.name]: {
                    none: true,
                    anyAll: 'any',
                    data: [],
                },
            });
        } else {
            this.props.onSubmit(null);
        }
    };
}

// Because we don't have a high degree of certainty about the shape of the
// filters we're dealing with (and therefore the shape of `state.value`,
// since it is derived from those filters), we need to do a lot of
// refinements in order to get a plain array of string ids.
export function getValueIdsFromFilter(filter: ?(string | Object)): string[] {
    if (filter && Array.isArray(filter.data)) {
        return filter.data
            .map((item) => {
                if (item && typeof item === 'object' && typeof item.data === 'string') {
                    return item.data;
                }

                return null;
            })
            .filter(Boolean);
    } else if (filter && typeof filter === 'string') {
        return [filter];
    }

    return [];
}
