/* @flow */

import * as React from 'react';

import {SelectPickerSearch} from '../select-picker-search/select-picker-search';
import {getChannelSelectOptions} from './helpers';
import type {ChannelType, ChannelOption} from './types';
import {ChannelIconWithName} from './channel-icon-with-name';

type Props = {
    channelSelection?: ?ChannelType,
    onSelectChannel: (channel: ChannelType) => void,
    onBlur?: () => void,
    autoFocus?: boolean,
    existingChannels?: ChannelType[],
    includeNoChannelOption?: boolean,
};

export function ChannelPicker(props: Props) {
    const {autoFocus = true} = props;
    const channelOptions = getChannelSelectOptions(
        props.existingChannels,
        props.includeNoChannelOption
    );

    return (
        <SelectPickerSearch
            minHeight={34}
            noPadding={true}
            placeholder='Search channels…'
            autoFocus={autoFocus}
            openMenuOnFocus={true}
            preventScrollOnMenuOpen={true}
            options={channelOptions}
            onBlur={props.onBlur}
            value={
                props.channelSelection
                    ? channelOptions.find((option) => option.value === props.channelSelection)
                    : undefined
            }
            onChange={(channelOption) => {
                if (channelOption && !Array.isArray(channelOption)) {
                    props.onSelectChannel(channelOption.value);
                }
            }}
            formatOptionLabel={(option: ChannelOption) => (
                <ChannelIconWithName channel={option.value} isSmall={true} />
            )}
        />
    );
}
