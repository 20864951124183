/* @flow */

import * as React from 'react';
import {type ActionMeta} from 'react-select';

import {SelectInline} from 'shells/select-inline/select-inline';
import {colors} from 'shells/colors';

type Option = {id: string, name: string};

type Props = {|
    placeholder: string,
    value: Option[],
    onChange: (newValue: any, actionMeta: ActionMeta) => void,
    onBlur: () => void,
    onClearValue?: () => void,
    defaultOptions?: true | Object[],
    loadOptions: (search: string) => Promise<Option[]>,
    noOptionsMessage?: ({inputValue: string}) => string | null,
    multi?: boolean,
    components?: Object,
    labelKey?: string,
    valueKey?: string,
    clearable?: boolean,
    shouldStyleValueByType?: boolean,
    disabled?: boolean,
    hasDropdownIndicator?: boolean,
    searchable?: boolean,
    openMenuOnClick?: boolean,
|};

export function GraphQLSelectInline(props: Props) {
    const {multi = true} = props;

    const styles = {
        valueContainer: (base) => ({
            ...base,
            padding: '2px 0',
            marginLeft: '8px',
        }),
        // Carousel has a max z-index of 21
        menu: (base) => ({...base, zIndex: 22}),
        control: (base, state) => {
            const hoverColor = state.isFocused ? colors.blue : colors.greyLt;
            const isInteractive = !props.disabled && props.searchable;

            return {
                ...base,
                '&:hover': {
                    border: isInteractive ? `1px solid ${hoverColor}` : undefined,
                },
                border:
                    state.isFocused && isInteractive
                        ? `1px solid ${colors.blue}`
                        : `1px solid ${colors.offWhiteMd}`,
                boxShadow: 'none',
                backgroundColor: '#fff',
                paddingTop: 5,
                marginLeft: 0,
                minHeight: 26,
                cursor: state.isDisabled || !props.searchable ? 'default' : 'pointer',
            };
        },
    };

    return (
        <SelectInline
            {...props}
            multi={multi}
            defaultOptions={props.defaultOptions}
            tokenizedStyle='light'
            transparentBackground={true}
            styles={styles}
            hasDropdownIndicator={props.hasDropdownIndicator}
            searchable={props.searchable}
            openMenuOnClick={props.openMenuOnClick}
        />
    );
}
