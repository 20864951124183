/* @flow */

import * as React from 'react';
import Formsy from 'formsy-react';
import {Popover} from 'shells/popover';

import getClassesMixin from '../../mixins/get-classes';
import {FormSelect} from '../../base/form-components/form-select';
import {FormTextField} from '../../base/form-components/form-textfield';
import {CheckmarkSubmitButton} from '../../base/form-components/checkmark-submit-button';

const selectValues = [
    {id: 'Equals', name: 'Equals'},
    {id: 'Greater than', name: 'Greater than'},
    {id: 'Less than', name: 'Less than'},
];

type Props = {
    value: Object,
    name: string,
    onDirty: Function, // Optional
    onSubmit: Function, // Optional
    animateIn?: boolean,
};

type State = {
    canSubmit: boolean,
    submitHover: boolean,
};

export class IntegerFormDeprecated extends React.Component<Props, State> {
    focusMe: ?HTMLElement;
    submitButton: ?HTMLElement;
    getClasses = getClassesMixin;

    static defaultProps = {
        value: {
            selected: 'Equals',
            data: '',
        },
        onSubmit: () => {},
        onDirty: () => {},
    };

    constructor() {
        super();
        this.state = {
            canSubmit: false,
            submitHover: false,
        };
    }

    componentDidMount() {
        const timeoutDuration = this.props.animateIn ? 250 : 0;

        setTimeout(() => {
            if (this.focusMe) {
                this.focusMe.focus();
            }
        }, timeoutDuration);
    }

    render() {
        const {name, value} = this.props;

        return (
            <Formsy
                className='ui-form'
                onChange={(data, changed) => this.props.onDirty(changed)}
                onValidSubmit={this.props.onSubmit}
                onValid={() => this.setState({canSubmit: true})}
                onInvalid={() => this.setState({canSubmit: false})}
            >
                <div className='ui-linked-select ui-linked-select__integer-form'>
                    <FormSelect
                        name={`${name}.selected`}
                        styleOptions={{fullWidth: true}}
                        value={value.selected}
                        selectValues={selectValues}
                    />
                    <FormTextField
                        name={`${name}.data`}
                        formTextfieldRef={(c) => {
                            this.focusMe = c;
                        }}
                        value={value.data}
                        required={true}
                        validations='isInt'
                        type='number'
                    />
                </div>
                <CheckmarkSubmitButton
                    className='ui-popover__submit-button'
                    getButtonRef={(c) => {
                        this.submitButton = c;
                    }}
                    disabled={!this.state.canSubmit}
                    onMouseOver={() => this.setState({submitHover: true})}
                    onMouseOut={() => this.setState({submitHover: false})}
                />
                {this.state.submitHover && !this.state.canSubmit ? (
                    <Popover anchor={this.submitButton} bodyColor='error' isDialog={true}>
                        <span>Whoops! Input must be an integer!</span>
                    </Popover>
                ) : undefined}
            </Formsy>
        );
    }
}
