/* @flow */
/* eslint-disable react/no-danger */
/* We need to use dangerouslySetInnerHTML in this file because we are rendering the results from solr,
   which helpfully highlights the parts of the results that matched the search query */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import {Avatar} from '../avatar';
import {colors} from '../colors';
import {LinkedEntity} from '../linked-entity';
import {LoadingText, LoadingCircle} from '../loading-placeholder';
import {AccountAvatar} from '../avatar/account-avatar';

import './peep-two-line.css';

type Props = {
    name: string,
    avatarUrl: ?string,
    initials: ?string,
    htmlUrl?: string,
    type: ?string,
    relatedEntityName: ?string,
    isLinkable?: boolean,
    isLoading?: boolean,
    shouldClientRoute?: boolean,
    shouldCenterAlignSingleRow?: boolean,
    rightContent?: ?React.Node,
};

export function PeepTwoLine(props: Props) {
    React.useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    if (props.isLoading) {
        return (
            <div styleName='peep-two-line'>
                <div styleName='avatar'>
                    <LoadingCircle size={32} color={colors.offWhiteDk} />
                </div>
                <div styleName='peep-info'>
                    <div styleName='top-row'>
                        <LoadingText height={13} color={colors.offWhiteDk} />
                    </div>
                    <div styleName='bottom-row'>
                        <LoadingText height={13} color={colors.offWhiteMd} />
                    </div>
                </div>
            </div>
        );
    }

    // Loading state has no type, just satisfying flow
    if (!props.type) {
        return null;
    }

    const relatedEntityType = props.type === 'contacts' ? 'accounts' : 'contacts';

    return (
        <div styleName='peep-two-line'>
            <div styleName='avatar'>
                {props.type === 'accounts' ? (
                    <AccountAvatar avatarUrl={props.avatarUrl} />
                ) : (
                    <Avatar
                        type={props.type}
                        initials={props.initials}
                        avatarUrl={props.avatarUrl}
                        size={32}
                    />
                )}
            </div>
            <div styleName='peep-info'>
                <div
                    styleName={
                        props.shouldCenterAlignSingleRow ? 'top-row--align-single' : 'top-row'
                    }
                >
                    <LinkedEntity
                        name={
                            props.name ? (
                                <span
                                    style={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                    dangerouslySetInnerHTML={{__html: props.name}}
                                />
                            ) : undefined
                        }
                        htmlUrl={props.htmlUrl}
                        isLinkable={props.isLinkable}
                        shouldClientRoute={props.shouldClientRoute}
                        stopPropagation={true}
                        type={props.type}
                    />
                    {props.rightContent}
                </div>
                {props.relatedEntityName ? (
                    <div styleName='bottom-row'>
                        <div styleName='associated'>
                            <LinkedEntity
                                name={props.relatedEntityName}
                                type={relatedEntityType}
                                isLinkable={false}
                                useEntityColor={true}
                            />
                        </div>
                    </div>
                ) : undefined}
            </div>
        </div>
    );
}
