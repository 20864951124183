/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {ViewTabs} from '../tabs';

import './control-bar.css';

export type Tab = {|
    id: string,
    label?: string,
    icon?: React.ComponentType<*> | string,
    href?: string,
    // Occasionally the tab bar should override its default behavior
    // and link somewhere – usually for an upsell or in an embedded context
    externalLink?: string,
    component?: React.Node,
|};

type TabProps = {
    options: Tab[],
    onChangeTab?: (newTab: string) => void,
    selectedTab: string,
};

type Props = {
    tabs?: TabProps,
    filterSection?: React.Node,
    customLeftSection?: React.Node,
    isShort?: boolean,
};

export function ControlBar(props: Props) {
    const styleName = classnames('control-bar', {
        'control-bar--short': props.isShort,
    });

    return (
        <div styleName={styleName}>
            {props.customLeftSection ? (
                props.customLeftSection
            ) : (
                <div styleName='filter-controls'>
                    {props.filterSection ? props.filterSection : undefined}
                </div>
            )}
            {props.tabs ? renderTabs(props.tabs) : undefined}
        </div>
    );
}

function renderTabs(tabs: TabProps) {
    return (
        <div styleName='tabs'>
            <ViewTabs
                tabs={tabs.options.map((tab) => {
                    return {
                        id: tab.id,
                        label: tab.label,
                        Icon: tab.icon,
                        isActive: tabs.selectedTab === tab.id,
                        size: 18,
                        href: tab.href,
                    };
                })}
                currentView={tabs.selectedTab}
                onChange={tabs.onChangeTab}
            />
        </div>
    );
}
