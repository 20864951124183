/* @flow */

import * as React from 'react';

import type {EventFragmentSparse, SessionUser} from 'nutshell-graphql-types';

import {TimelineBannerOutreachSent} from '../banner';
import {TimelineEntryEmail, TimelineEntryInboxThread} from '.';

export function getEmailTimelineEvent(input: {
    event: EventFragmentSparse,
    currentUser: SessionUser,
    onDelete?: (eventId: string, allById?: boolean) => Promise<*>,
    hasGutterLine?: boolean,
    isDashboard?: boolean,
    refetchEvents: ?() => Promise<*>,
    entityPageId?: ?string,
    onFilterOutEvent?: () => void,
}) {
    const {
        event,
        currentUser,
        onDelete,
        hasGutterLine,
        isDashboard,
        refetchEvents,
        entityPageId,
        onFilterOutEvent,
    } = input;

    if (event.payload.isNutshellMarketingEmail) {
        return <TimelineBannerOutreachSent key={event.id} event={event} />;
    }

    return (
        <TimelineEntryEmail
            key={event.id}
            event={event}
            currentUser={currentUser}
            onDeleteEvent={onDelete}
            hasGutterLine={hasGutterLine}
            isDashboard={isDashboard}
            refetchEvents={refetchEvents}
            entityPageId={entityPageId}
            onFilterOutEvent={onFilterOutEvent}
        />
    );
}

export function getThreadTimelineEvent(input: {
    event: EventFragmentSparse,
    entityPageId: ?string,
    entityPageName: ?string,
}) {
    const {event, entityPageId, entityPageName} = input;

    return (
        <TimelineEntryInboxThread
            key={event.id}
            event={event}
            entityPageId={entityPageId}
            entityPageName={entityPageName}
        />
    );
}
