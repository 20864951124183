/* @flow */

import * as React from 'react';

import {colors} from '../../colors';

import './view-tab-radio-input.css';

type Props = {
    Icon?: React.ComponentType<*> | string,
    label: ?string,
    isActive: boolean,
    size?: number,
};

export function ViewTabRadioInput(props: Props) {
    const {Icon, label, isActive, size} = props;

    const iconColor = isActive ? colors.greyDk : colors.greyLt;
    const iconSize = size ? size : '17';

    return (
        <div styleName='view-tab'>
            <div style={{color: iconColor, height: 0}}>
                {Icon ? (
                    <Icon height={`${iconSize}px`} size={iconSize} fill={iconColor} />
                ) : undefined}
            </div>
            {label ? <span styleName='label'>{label}</span> : undefined}
        </div>
    );
}
