/* @flow */

import * as React from 'react';

import {RadioGroupSimple} from '../../radio-group-simple';
import {ViewTabRadioInput} from './view-tab-radio-input';

export type Props = {|
    tabs: {|
        Icon?: React.ComponentType<*> | string,
        label: ?string,
        isActive: boolean,
        size?: number,
        id: string,
        href?: string,
    |}[],
    currentView: string,
    onChange: (string) => any,
    isWide?: boolean,
|};

export function ViewTabsRadioGroup(props: Props) {
    const {isWide = true} = props;

    const options = props.tabs.map((tab) => {
        return {id: tab.id, label: <ViewTabRadioInput key={tab.id} {...tab} />};
    });

    return (
        <RadioGroupSimple
            name='view'
            isWide={isWide}
            options={options}
            selectedOption={props.currentView}
            onChange={props.onChange}
        />
    );
}
