/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import NavIconOrganization from './nav-icon-organization.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <NavIconOrganization {...validProps} />;
}
SvgComponent.iconName = 'navIconOrganization';

export const NavIconOrganizationIcon = svgIconWrapper(SvgComponent);
