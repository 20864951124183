/* @flow */

import type {UsersApiResponse, User} from '../types';
import type {SessionState} from './session-reducer';

export const REDUCER_KEY = 'session';

export type SessionModuleState = {|
    session: SessionState,
|};

export const ActionTypes = {
    SESSION_FETCH_SESSION_REQUEST: 'SESSION_FETCH_SESSION_REQUEST',
    SESSION_FETCH_SESSION_SUCCESS: 'SESSION_FETCH_SESSION_SUCCESS',
    SESSION_FETCH_SESSION_FAILURE: 'SESSION_FETCH_SESSION_FAILURE',
    SESSION_HACKY_DOM_USER_ADDED: 'SESSION_HACKY_DOM_USER_ADDED',
    SESSION_UPDATE_AGENT_STATUS: 'SESSION_UPDATE_AGENT_STATUS',
    SESSION_UPDATE_MAIN_SIDEBAR_COLLAPSED: 'SESSION_UPDATE_MAIN_SIDEBAR_COLLAPSED',
};

export type FetchSessionRequestAction = {|
    +type: 'SESSION_FETCH_SESSION_REQUEST',
    payload: {baseUrl: string, options?: RequestOptions},
|};
export type FetchSessionSuccessAction = {|
    +type: 'SESSION_FETCH_SESSION_SUCCESS',
    payload: UsersApiResponse,
|};
export type FetchSessionFailureAction = {|
    +type: 'SESSION_FETCH_SESSION_FAILURE',
    payload: ?Object,
    error: true,
|};
export type AddHackyDomUserAction = {|+type: 'SESSION_HACKY_DOM_USER_ADDED', payload: ?User|};
export type SessionUpdateAgentStatusAction = {
    type: typeof ActionTypes.SESSION_UPDATE_AGENT_STATUS,
    payload: {
        agentStatus: 'ONLINE' | 'NOT_ACCEPTING_CHATS',
    },
};
export type SessionUpdateMainSidebarCollapsedAction = {
    type: typeof ActionTypes.SESSION_UPDATE_MAIN_SIDEBAR_COLLAPSED,
    payload: {
        isMainSidebarCollapsed: boolean,
    },
};

export type SessionAction =
    | FetchSessionRequestAction
    | FetchSessionSuccessAction
    | FetchSessionFailureAction
    | AddHackyDomUserAction
    | SessionUpdateAgentStatusAction
    | SessionUpdateMainSidebarCollapsedAction;
