/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import NavIconEngagement from './nav-icon-engagement.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <NavIconEngagement {...validProps} />;
}
SvgComponent.iconName = 'navIconEngagement';

export const NavIconEngagementIcon = svgIconWrapper(SvgComponent);
