/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ArrowDownThinIconSvg from './icon-arrow-down-thin.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ArrowDownThinIconSvg {...validProps} />;
}
SvgComponent.iconName = 'arrowDownThinIcon';

export const ArrowDownThinIcon = svgIconWrapper(SvgComponent);
