/* @flow */

import * as React from 'react';

import {Button} from 'shells/button';
import {ButtonMover} from 'shells/button-mover';

import './status-bar.css';

export type Props = {
    text: string,
    linkProps?: {
        onClick: () => void,
        linkText: string,
    },
};

export function StatusBarPrimaryText(props: Props) {
    return (
        <div className='flex'>
            <div styleName='primary-text'>{props.text}</div>
            {props.linkProps ? (
                <ButtonMover size='big' direction={['up', 'down']}>
                    <Button size='big' variant='text-primary' onClick={props.linkProps.onClick}>
                        {props.linkProps.linkText}
                    </Button>
                </ButtonMover>
            ) : undefined}
        </div>
    );
}
