/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ReturnIconSvg from './icon-return.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ReturnIconSvg {...validProps} />;
}
SvgComponent.iconName = 'returnIcon';

export const ReturnIcon = svgIconWrapper(SvgComponent);
