import * as React from 'react';
import PropTypes from 'prop-types';
import {withFormsy, propTypes as formsyPropTypes} from 'formsy-react';
import {TextField} from 'shells/textfield';

class FormTextFieldComponent extends React.Component {
    static propTypes = {
        placeholder: PropTypes.string,
        type: PropTypes.oneOf(['text', 'number']),
        min: PropTypes.number,
        max: PropTypes.number,
        minLength: PropTypes.number,
        maxLength: PropTypes.number,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        formTextfieldRef: PropTypes.func,
        ...formsyPropTypes,
    };

    static defaultProps = {
        type: 'text',
        onFocus: (textField) => {
            textField.target.select();
        },
        onBlur: () => {},
        onChange: () => {},
        formTextfieldRef: () => {},
    };

    render() {
        return (
            <TextField
                textFieldRef={this.props.formTextfieldRef}
                type={this.props.type}
                min={this.props.min}
                max={this.props.max}
                minLength={this.props.minLength}
                maxLength={this.props.maxLength}
                placeholder={this.props.placeholder}
                value={this.props.getValue()}
                onChange={(value) => {
                    this.props.setValue(value);
                    this.props.onChange(value);
                }}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
            />
        );
    }
}

export const FormTextField = withFormsy(FormTextFieldComponent);
