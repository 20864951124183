/* @flow */

import * as React from 'react';

import {
    CHANNEL_UNKNOWN,
    CHANNEL_SEARCH_ORGANIC,
    CHANNEL_SEARCH_PAID,
    CHANNEL_SOCIAL_ORGANIC,
    CHANNEL_SOCIAL_PAID,
    CHANNEL_EMAIL,
    CHANNEL_DIRECT_TO_WEBSITE,
    CHANNEL_REFERRER,
    CHANNEL_TRADITIONAL,
    CHANNELS,
} from 'nutshell-core/channels/consts';

import {
    BrowserIcon,
    CompassIcon,
    EnvelopeOpenTextIcon,
    MagnifyingGlassChartIcon,
    MagnifyingGlassDollarIcon,
    MessagesIcon,
    MessagesDollarIcon,
    TVRetroIcon,
} from '../icon';
import type {ChannelOption, ChannelType} from './types';

export function getChannelIconFromInt(channel: number): React.ComponentType<*> {
    switch (channel) {
        case 1:
            return MagnifyingGlassChartIcon;
        case 2:
            return MagnifyingGlassDollarIcon;
        case 3:
            return MessagesIcon;
        case 4:
            return MessagesDollarIcon;
        case 5:
            return EnvelopeOpenTextIcon;
        case 6:
            return CompassIcon;
        case 8:
            return BrowserIcon;
        case 7:
        default:
            return TVRetroIcon;
    }
}

export function getChannelIconFromChannel(channel: ChannelType): ?React.ComponentType<*> {
    switch (channel) {
        case CHANNEL_SEARCH_ORGANIC:
            return MagnifyingGlassChartIcon;
        case CHANNEL_SEARCH_PAID:
            return MagnifyingGlassDollarIcon;
        case CHANNEL_SOCIAL_ORGANIC:
            return MessagesIcon;
        case CHANNEL_SOCIAL_PAID:
            return MessagesDollarIcon;
        case CHANNEL_EMAIL:
            return EnvelopeOpenTextIcon;
        case CHANNEL_DIRECT_TO_WEBSITE:
            return CompassIcon;
        case CHANNEL_REFERRER:
            return BrowserIcon;
        case CHANNEL_TRADITIONAL:
            return TVRetroIcon;
        case CHANNEL_UNKNOWN:
        default:
            return undefined;
    }
}
export function getChannelSelectOptions(
    existingChannels?: ChannelType[],
    includeNoChannel?: boolean
): ChannelOption[] {
    return (
        Object.keys(CHANNELS)
            // don't include CHANNEL_UNKNOWN in select
            .filter((channelKey) => includeNoChannel || channelKey !== CHANNEL_UNKNOWN)
            // filter out channels already on the lead, they can't be added again
            .filter((channelKey) =>
                existingChannels ? !existingChannels.includes(CHANNELS[channelKey].value) : true
            )
            .map((channelKey) => ({value: CHANNELS[channelKey].value}))
    );
}
