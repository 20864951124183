/* @flow */

import * as React from 'react';

export type StackSpacing = 0 | 2 | 4 | 6 | 8 | 12 | 16 | 20 | 24 | 32;

type Props = {
    children: React.Node,
    spacing: StackSpacing,
    isHorizontal?: boolean,
    alignCenter?: boolean,
    // Expands the stack horizontally, only to be used with horizontal stacks (i.e. isHorizontal=true)
    isFullWidth?: boolean,
};

export function Stack(props: Props) {
    return (
        <div
            className={`flex ${props.isHorizontal ? '' : 'flex-dir-col'} ${
                props.alignCenter ? 'align-center' : ''
            } ${props.isFullWidth ? 'full-width' : ''}`}
        >
            {React.Children.toArray(props.children)
                .filter((child) => child)
                .map((child, index) => {
                    return (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            style={{
                                // If horzintal, use marginLeft, else use marginTop
                                [props.isHorizontal ? 'marginLeft' : 'marginTop']:
                                    index === 0 ? '0px' : `${props.spacing}px`,
                            }}
                        >
                            {child}
                        </div>
                    );
                })}
        </div>
    );
}
