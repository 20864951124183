/* @flow */

import * as React from 'react';

import {SectionLabel} from '../../typography';
import {Stack} from '../../layout';

import {SectionAddItemButton} from '../section-add-item-button';

import {QuoteRow} from './quote-row';

import './quotes-section.css';

const testData = [
    {
        quoteId: '1238-04',
        quoteAmount: '$179.12',
        quoteStatus: 'Draft',
        quoteUpdatedAt: 'Jan 14th',
    },
    {
        quoteId: '1238-03',
        quoteAmount: '$179.12',
        quoteStatus: 'Sent',
        quoteSentAt: 'Jan 14th',
    },
    {
        quoteId: '1238-02',
        quoteAmount: '$179.12',
        quoteStatus: 'Approved',
        quoteAcceptedAt: 'Jan 14th',
    },
    {
        quoteId: '1238-01',
        quoteAmount: '$179.12',
        quoteStatus: 'Archived',
        quoteArchivedAt: 'Jan 14th',
    },
];

export function QuotesSection() {
    return (
        <div styleName='section'>
            <SectionLabel>Quote</SectionLabel>
            <Stack spacing={16}>
                {testData.map((quote) => (
                    <QuoteRow key={quote.quoteId} quote={quote} />
                ))}
                <SectionAddItemButton
                    sectionEntity='quotes'
                    isAddingItem={false}
                    setIsAddingItem={() => {}}
                    parentItemText=' a quote'
                    omitEntityName={true}
                    addItemComponent={<div></div>}
                />
            </Stack>
        </div>
    );
}
