/* @flow */

import * as React from 'react';

import type {DisplayOptionEntity} from 'nutshell-core/utils';
import type {GetContactFormValidation_contactCreateValidation_errors as FormErrorType} from 'nutshell-graphql-types';

import {colors} from '../../colors';
import {Card} from '../../card';
import {Avatar} from '../../avatar/avatar';
import {TextField} from '../../textfield';
import {Button, CloseButton, CheckmarkButton} from '../../button';
import {BulletedList} from '../../bulleted-list';

import type {ContactInput} from '../types';

import {PotentialContacts} from './potential-contacts';
import {shouldShowPotentialContacts, shouldAllowCreate} from './helpers';

import './contact-card-createable.css';

type Props = {|
    hasRelatedAccounts: boolean,
    onSelectContact?: (Object) => void,
    onCreateContact?: (ContactInput) => void,
    onSearchPotentialContacts?: (ContactInput) => Promise<*>,
    matchedContactsFilter?: (DisplayOptionEntity[]) => DisplayOptionEntity[],
    handleCloseEditModal?: () => void,
    onRemove?: () => void,
    // We might be editing an existing temporary contact
    temporaryContact?: ContactInput,
    formErrors?: FormErrorType[],
    removeError?: (field: string) => void,
|};

const FIELD_NAME = 'name';
const FIELD_EMAIL = 'email';
const FIELD_PHONE = 'phone';
const FIELD_DESCRIPTION = 'description';
export const FIELD_ALL = 'all';

export function ContactCardCreateable(props: Props) {
    const {onSearchPotentialContacts} = props;

    const temporaryContact = props.temporaryContact;
    const [name, setName] = React.useState(temporaryContact ? temporaryContact.name : '');
    const [description, setDescription] = React.useState(
        temporaryContact ? temporaryContact.description : ''
    );
    const [phoneNumber, setPhoneNumber] = React.useState(
        temporaryContact ? temporaryContact.phoneNumber : ''
    );
    const [email, setEmail] = React.useState(temporaryContact ? temporaryContact.email : '');
    const [potentialContacts, setPotentialContacts] = React.useState<DisplayOptionEntity[]>([]);
    const [isAnyFieldFocused, setIsAnyFieldFocused] = React.useState<boolean>(false);

    // We need to display the potential contacts for a short duration after blur
    // to allow the user changing focus to a potential contact. Otherwise, we
    // lose focus and the component unmounts before it can add the selected
    // contact.
    const blurTimeoutRef = React.useRef(null);

    const handleFocus = () => {
        if (blurTimeoutRef.current) {
            clearTimeout(blurTimeoutRef.current);
        }
        setIsAnyFieldFocused(true);
    };

    const handleBlur = () => {
        if (blurTimeoutRef.current) {
            clearTimeout(blurTimeoutRef.current);
        }
        blurTimeoutRef.current = setTimeout(() => {
            setIsAnyFieldFocused(false);
        }, 150);
    };

    // cleanup timeout on unmount
    React.useEffect(() => {
        return () => {
            if (blurTimeoutRef.current) {
                clearTimeout(blurTimeoutRef.current);
            }
        };
    }, []);

    const shouldShowPotentialContactsComponent = shouldShowPotentialContacts(
        name,
        description,
        phoneNumber,
        email,
        isAnyFieldFocused,
        props.hasRelatedAccounts
    );

    const nameRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const phoneRef = React.useRef(null);
    const descriptionRef = React.useRef(null);

    React.useEffect(() => {
        if (shouldShowPotentialContactsComponent) {
            onSearchPotentialContacts &&
                onSearchPotentialContacts({
                    name,
                    description,
                    phoneNumber,
                    email,
                }).then((matchedContacts: DisplayOptionEntity[]) => {
                    const filteredContacts = props.matchedContactsFilter
                        ? props.matchedContactsFilter(matchedContacts)
                        : matchedContacts;
                    setPotentialContacts(filteredContacts);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, description, phoneNumber, email, isAnyFieldFocused]);

    const handleCloseButtonClick = () => {
        // If editing a temporary contact, don't remove on close
        if (temporaryContact) {
            props.handleCloseEditModal && props.handleCloseEditModal();
        } else {
            props.onRemove && props.onRemove();
        }
    };

    const handleSelectContact = (contact: DisplayOptionEntity) => {
        const mappedContact = {
            id: contact.id,
            name: contact.value,
            description: contact.entityDescription,
            phoneNumber: contact.primaryPhoneText,
            email: contact.primaryEmailText,
            avatarUrl: contact.avatarUrl,
            initials: contact.initials,
        };
        props.onSelectContact && props.onSelectContact(mappedContact);
    };

    const handleCreateContact = () => {
        props.onCreateContact &&
            props.onCreateContact({
                name,
                description,
                phoneNumber: phoneNumber,
                email,
            });
    };

    const handleUpdateContact = () => {
        temporaryContact &&
            props.onCreateContact &&
            props.onCreateContact({
                ...temporaryContact,
                name,
                description,
                phoneNumber: phoneNumber,
                email,
            });
    };

    const handleKeyDown = (e: SyntheticKeyboardEvent<>, currentField: string) => {
        switch (e.key) {
            case 'ArrowRight':
                if (currentField === FIELD_NAME) emailRef.current && emailRef.current.focus();
                if (currentField === FIELD_PHONE)
                    descriptionRef.current && descriptionRef.current.focus();
                break;
            case 'ArrowLeft':
                if (currentField === FIELD_EMAIL) nameRef.current && nameRef.current.focus();
                if (currentField === FIELD_DESCRIPTION)
                    phoneRef.current && phoneRef.current.focus();
                break;
            case 'ArrowDown':
                if (currentField === FIELD_NAME) phoneRef.current && phoneRef.current.focus();
                if (currentField === FIELD_EMAIL)
                    descriptionRef.current && descriptionRef.current.focus();
                break;
            case 'ArrowUp':
                if (currentField === FIELD_PHONE) nameRef.current && nameRef.current.focus();
                if (currentField === FIELD_DESCRIPTION)
                    emailRef.current && emailRef.current.focus();
                break;
        }
    };

    const getFieldHasError = (field: string) => {
        return (
            props.formErrors &&
            props.formErrors.some((error) => {
                if (field === FIELD_DESCRIPTION && error.field === FIELD_ALL) {
                    return false;
                } else if (error.field === 'all') {
                    return true;
                } else if (error.field === field) {
                    return true;
                }

                return false;
            })
        );
    };

    return (
        <>
            <Card hasBorder={true} fitHeight={true}>
                <div className='py-12 px-8'>
                    <div className='flex align-center justify-start'>
                        <div styleName='avatar'>
                            <Avatar
                                avatarUrl={null}
                                type='contacts'
                                size={32}
                                color={colors.person}
                            />
                        </div>
                        <div styleName='textfield-wrapper'>
                            <TextField
                                placeholder='Name'
                                value={name}
                                onChange={(val) => {
                                    setName(val);
                                    props.removeError && props.removeError(FIELD_NAME);
                                }}
                                autoFocus={true}
                                size='expand'
                                ignorePasswordAutoFill={true}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onKeyDown={(e) => handleKeyDown(e, FIELD_NAME)}
                                onEnter={(e) => {
                                    e.preventDefault();
                                    temporaryContact
                                        ? handleUpdateContact()
                                        : handleCreateContact();
                                }}
                                textFieldRef={nameRef}
                                hasError={getFieldHasError(FIELD_NAME)}
                            />
                            <TextField
                                placeholder='Email'
                                value={email || ''}
                                onChange={(val) => {
                                    setEmail(val);
                                    props.removeError && props.removeError(FIELD_EMAIL);
                                }}
                                size='expand'
                                ignorePasswordAutoFill={true}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onKeyDown={(e) => handleKeyDown(e, FIELD_EMAIL)}
                                onEnter={(e) => {
                                    e.preventDefault();
                                    temporaryContact
                                        ? handleUpdateContact()
                                        : handleCreateContact();
                                }}
                                textFieldRef={emailRef}
                                hasError={getFieldHasError(FIELD_EMAIL)}
                            />
                            <TextField
                                placeholder='Phone number'
                                value={phoneNumber || ''}
                                onChange={(val) => {
                                    setPhoneNumber(val);
                                    props.removeError && props.removeError(FIELD_PHONE);
                                }}
                                size='expand'
                                ignorePasswordAutoFill={true}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onKeyDown={(e) => handleKeyDown(e, FIELD_PHONE)}
                                onEnter={(e) => {
                                    e.preventDefault();
                                    temporaryContact
                                        ? handleUpdateContact()
                                        : handleCreateContact();
                                }}
                                textFieldRef={phoneRef}
                                hasError={getFieldHasError(FIELD_PHONE)}
                            />
                            <TextField
                                placeholder='Description'
                                value={description || ''}
                                onChange={(val) => {
                                    setDescription(val);
                                    props.removeError && props.removeError(FIELD_DESCRIPTION);
                                }}
                                size='expand'
                                ignorePasswordAutoFill={true}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onKeyDown={(e) => handleKeyDown(e, FIELD_DESCRIPTION)}
                                onEnter={(e) => {
                                    e.preventDefault();
                                    temporaryContact
                                        ? handleUpdateContact()
                                        : handleCreateContact();
                                }}
                                textFieldRef={descriptionRef}
                                hasError={getFieldHasError(FIELD_DESCRIPTION)}
                            />
                        </div>
                        <div className='flex flex-dir-col align-center gap-8'>
                            {temporaryContact && (
                                <CheckmarkButton
                                    className='px-8'
                                    size={10}
                                    onClick={handleUpdateContact}
                                    variant='dark'
                                />
                            )}
                            <CloseButton
                                className='px-8'
                                size={10}
                                onClick={handleCloseButtonClick}
                                variant='dark'
                            />
                        </div>
                    </div>
                </div>
                {props.formErrors && props.formErrors.length > 0 && (
                    <div styleName='error-list'>
                        <BulletedList
                            bullets={props.formErrors.map((error) => error.error)}
                            variant='errors'
                            size='small'
                            marginBottom={16}
                        />
                    </div>
                )}
            </Card>
            {!temporaryContact && shouldAllowCreate(name, description, phoneNumber, email) && (
                <div styleName='create-button'>
                    <Button
                        variant='text-primary'
                        preventUnderline={true}
                        onClick={handleCreateContact}
                        noPadding={true}
                        isFullWidth={true}
                    >
                        + Create new person {name}
                    </Button>
                </div>
            )}
            {shouldShowPotentialContactsComponent && potentialContacts.length > 0 && (
                <PotentialContacts
                    potentialContacts={potentialContacts}
                    onSelectContact={handleSelectContact}
                />
            )}
        </>
    );
}
