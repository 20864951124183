import * as React from 'react';

export class RelationshipEmptyViewDeprecated extends React.Component {
    render() {
        return (
            <div className='ui-searchable-list__empty-view'>
                <h3>
                    <i>Nothing here</i>
                </h3>
            </div>
        );
    }
}
