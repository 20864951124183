/* @flow */

import React from 'react';
import classnames from 'classnames';

type Props = {
    size?: string | number,
    className?: string,
    // This isn't used in this component, but _is_ for SVG icons, and it helps to keep the interface the same
    wrapWithDiv?: boolean,
    style?: Object,
    alignCenter?: boolean,
};

export function faIconCreator(name: string) {
    function icon(props: Props) {
        const {
            size,
            // Don't want to pass this along to the `span`, in case it's provided
            wrapWithDiv, // eslint-disable-line no-unused-vars
            style,
            alignCenter,
            ...otherProps
        } = props;

        const className = classnames('fa', `fa-${name}`, props.className);

        return (
            <span
                {...otherProps}
                // Only spread our fontsize style object if we set a fontsize
                style={{
                    ...style,
                    ...(size ? {fontSize: size} : undefined),
                    ...(alignCenter ? {verticalAlign: 'middle'} : undefined),
                }}
                className={className}
            />
        );
    }

    return icon;
}
