import * as React from 'react';
import PropTypes from 'prop-types';
import {withFormsy, propTypes as formsyPropTypes} from 'formsy-react';
import getClasses from '../../mixins/get-classes';

class FormRadioGroupComponent extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        ...formsyPropTypes,
    };

    getClasses = getClasses;

    render() {
        const {name} = this.props;
        const onSelect = (val) => this.props.setValue(val);

        return (
            <div className={this.getClasses('ui-radio-group')}>
                {React.Children.map(this.props.children, (child, index) =>
                    React.cloneElement(child, {
                        name,
                        index,
                        onSelect,
                        selected: child.props.value === this.props.getValue(),
                    })
                )}
            </div>
        );
    }
}

export const FormRadioGroup = withFormsy(FormRadioGroupComponent);
