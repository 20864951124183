/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {ReduxFormSelectPickerGeneric} from 'shells/redux-form-fields';
import {SelectedFieldCard} from './selected-field-card';

type Props = {
    ...$Exact<FieldProps>,
    onRemove: () => void,
    optionFields: {id: string, name: string}[],
    autoFocus?: boolean,
};

export function SelectField(props: Props) {
    const inputValue = props.input.value;

    const selectedValue = inputValue
        ? props.optionFields.find((field) => field.id === inputValue)
        : undefined;

    if (inputValue) {
        return (
            <SelectedFieldCard
                name={selectedValue ? selectedValue.name : inputValue.label}
                onRemove={props.onRemove}
            />
        );
    }

    // $FlowIgnore this is because of the way we're passing the props through
    return <ReduxFormSelectPickerGeneric {...props} />;
}
