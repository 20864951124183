/* @flow */

import * as React from 'react';

import {EntitySelectRow} from 'shells/entity/entity-select-row';

import getClassesMixin from '../../mixins/get-classes';

type Props = {
    searchResult: Object,
    isSelected?: boolean,
    onClick: (e: Event, searchResult: Object) => any,
};

export class SearchResultItem extends React.Component<Props> {
    getClasses = getClassesMixin;

    static defaultProps = {
        onClick: () => {},
    };

    render() {
        const {searchResult, isSelected, onClick} = this.props;
        const entity = searchResult.getEntity();
        const escapedInfo = searchResult.getEscapedInfo();
        const entityType = entity.get('type');
        const classes = this.getClasses('ui-popover__search-result', {
            selected: isSelected,
        });

        // Necessary to prevent textfield's blur event to cause a state
        // change in the autocompleteResults and hide the list before the
        // click event can properly fire.
        const stop = function (e) {
            e.preventDefault();
            e.stopPropagation();
        };

        return (
            <li
                className={classes}
                onClick={(e) => onClick(e, searchResult)}
                onMouseDown={(e) => stop(e)}
            >
                <EntitySelectRow
                    entity={{
                        id: entity.get('id'),
                        description: escapedInfo.description,
                        type: entityType,
                        value: escapedInfo.primaryName ? (
                            <span
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: escapedInfo.primaryName,
                                }}
                            />
                        ) : undefined,
                        avatarUrl: searchResult.get('avatarUrl'),
                        initials: searchResult.get('initials'),
                        relatedType: searchResult.get('relatedType'),
                        secondaryInfo: escapedInfo.relatedName ? (
                            <span
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: escapedInfo.relatedName,
                                }}
                            />
                        ) : undefined,
                        tertiaryInfo: escapedInfo.relatedInfo ? (
                            <span
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: escapedInfo.relatedInfo,
                                }}
                            />
                        ) : undefined,
                    }}
                />
            </li>
        );
    }
}
