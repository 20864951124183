/* @flow */

import * as React from 'react';
import {CalendarWrapper} from 'shells/calendar';
import moment from 'moment';
import {Popover} from 'shells/popover';
import getClassesMixin from '../mixins/get-classes';

type Props = {
    initialDate: moment$Moment,
    onSubmit: (moment$Moment) => void,
    onCancel: () => void,
};

type State = {
    displayDate: moment$Moment,
};

export class DatePicker extends React.Component<Props, State> {
    static defaultProps = {
        initialDate: moment(),
        onCancel: () => {},
    };

    getClasses = getClassesMixin;

    constructor(props: Props) {
        super(props);
        this.state = {
            displayDate: props.initialDate,
        };
    }

    render() {
        return (
            <Popover
                {...this.props}
                isNubDark={true}
                onBlur={this.handleCancel}
                disableFocusTrapZone={true}
            >
                <div style={{width: 238}}>
                    <CalendarWrapper
                        momentDisplayDate={this.state.displayDate}
                        onDateClick={this.props.onSubmit}
                    />
                </div>
            </Popover>
        );
    }

    handleCancel = () => {
        // This is a temp sponge because the legacy react popover will have API
        // changes and I dont want to introduce any proptype errors by properly
        // changing handleBlur prop to onBlur.
        this.props.onCancel();
    };
}
