/* @flow */

import * as React from 'react';

import {SegmentedControlButton} from './segmented-control-button';

import './segmented-control.css';

export type ControlOption = {
    value: any,
    type?: 'small' | 'large',
    title?: string,
    subject?: string,
    content?: string | React.Node,
    isDisabled?: boolean,
    disabledPopoverText?: ?string,
};

type Props = {
    buttonOptions: ControlOption[],
    selectedValue: any,
    setSelectedValue: (value: any) => void,
    isDisabled?: boolean,
    onChange?: (value: any) => void,
};

export function SegmentedControl(props: Props) {
    return (
        <div styleName='control-container'>
            {props.buttonOptions.map((option) => (
                <SegmentedControlButton
                    {...props}
                    key={option.value}
                    option={option}
                    isSelected={option.value === props.selectedValue}
                    isDisabled={option.isDisabled}
                    disabledPopoverText={option.disabledPopoverText}
                />
            ))}
        </div>
    );
}
