/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import NavIconMarketing from './nav-icon-marketing.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <NavIconMarketing {...validProps} />;
}
SvgComponent.iconName = 'navIconMarketing';

export const NavIconMarketingIcon = svgIconWrapper(SvgComponent);
