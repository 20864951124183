import PropTypes from 'prop-types';
import * as React from 'react';
import _ from 'underscore';
import {SearchBar} from 'shells/search-bar';
import getClassesMixin from '../mixins/get-classes';
import {LoadingView as LoadingViewComponent} from '../core/search/loading-view';
import {AutocompleteResults} from './textfield/autocomplete-results';

export default class InputAjaxSearch extends React.Component {
    getClasses = getClassesMixin;

    static propTypes = {
        placeholder: PropTypes.string,
        focusOnMount: PropTypes.bool,
        shouldOpenOnFocus: PropTypes.bool,
        updateQueryOnSelect: PropTypes.bool,
        hasSearched: PropTypes.bool,
        popoverClass: PropTypes.string,
        header: PropTypes.func,
        search: PropTypes.func.isRequired,
        loadingView: PropTypes.func.isRequired,
        emptyView: PropTypes.func.isRequired,
        onSelect: PropTypes.func,
        renderRow: PropTypes.func,
    };

    static defaultProps = {
        placeholder: 'Search…',
        shouldOpenOnFocus: true,
        loadingView: LoadingViewComponent,
        onSelect: () => {},
    };

    constructor(props) {
        super(props);
        this.state = {
            query: '',
            focused: false,
            loading: true,
            resultsShown: false,
            value: '',
            results: [],
        };

        this.doSearch = _.debounce((q) => this.getSearchResults(q), 200);
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.props.focusOnMount) {
                this.textfield.focus();
            }
        });
    }

    render() {
        const classes = this.getClasses('ui-input-ajax-search', {
            selected: this.state.focused,
        });

        const {loadingView: LoadingView, header: HeaderView, emptyView: EmptyView} = this.props;

        return (
            <div
                ref={(c) => {
                    this.anchor = c;
                }}
                className={classes}
            >
                <SearchBar
                    searchBarRef={(c) => {
                        this.textfield = c;
                    }}
                    placeholder={this.props.placeholder}
                    value={this.state.value}
                    hasBorder={true}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                />
                {this.state.resultsShown ? (
                    <AutocompleteResults
                        shouldClose={() => this.setState({resultsShown: false})}
                        shouldOpenOnFocus={this.props.shouldOpenOnFocus}
                        anchor={this.anchor}
                        onSelect={(e, item) => this.handleSelect(e, item)}
                        popoverClass={this.props.popoverClass}
                        header={
                            this.props.header ? (
                                <HeaderView
                                    query={this.props.shouldOpenOnFocus ? this.state.query : ' '}
                                />
                            ) : undefined
                        }
                        emptyView={
                            this.props.emptyView ? (
                                <EmptyView hasQuery={this.state.query.length > 0} />
                            ) : undefined
                        }
                        loading={this.state.loading}
                        loadingView={<LoadingView />}
                        collection={this.state.results}
                        renderRow={this.wrapRenderRow(this.props.renderRow)}
                    />
                ) : undefined}
            </div>
        );
    }

    handleChange = (val) => {
        // Hide the thing if no input and we don't want to show on focus
        if (!this.props.shouldOpenOnFocus && !val.length) {
            this.setState({
                resultsShown: false,
                value: '',
            });

            return;
        }

        // If results aren't shown, we want to enter loading state, looks cleaner
        if (!this.state.resultsShown) {
            this.setState({
                loading: true,
                resultsShown: true,
            });
        }

        if (val !== this.state.value) {
            this.setState({value: val});
        }

        this.startSearching(val);
    };

    handleFocus = () => {
        this.setState((prevState) => ({
            focused: true,
            resultsShown: prevState.query.length || this.props.shouldOpenOnFocus,
        }));

        if (this.state.query.length || (!this.props.hasSearched && this.props.shouldOpenOnFocus)) {
            this.startSearching(this.state.query);
        }
    };

    handleBlur = () => {
        this.setState({
            focused: false,
            resultsShown: false,
        });
    };

    startSearching(q) {
        this.setState({
            resultsShown: true,
        });
        this.doSearch(q);
    }

    getSearchResults(queryString) {
        this.setState((prevState) => ({
            loading: prevState.loading,
        }));

        this.props.search(queryString, (res) => {
            this.setState({
                results: res,
                query: queryString,
                loading: false,
            });
        });
    }

    handleSelect(e, item) {
        e.stopPropagation();

        this.props.onSelect(e, item);

        if (this.props.updateQueryOnSelect) {
            this.setState({
                value: '',
                resultsShown: false,
            });
        }

        this.textfield.blur();
    }

    wrapRenderRow(renderRowFunc) {
        return (row, selected) => {
            const rowComponent = renderRowFunc(row, selected);

            const newOnClick = (e, item) => {
                this.handleSelect(e, item);
                if (rowComponent.props.onClick) {
                    rowComponent.props.onClick(e, item);
                }
            };

            return React.cloneElement(rowComponent, {
                onClick: newOnClick,
            });
        };
    }
}
