/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import './textfield.css';

export type Props = {
    // TODO: Figure out how to make this work.  Might need a newer flow than 0.53
    // ...HTMLInputElement,
    // There are rare case where you may want a textfield to be uncontrolled
    // (meaning you are not keeping its value in state anywhere). The primary
    // use case is for sensitive data (eg. a password) that you want to treat
    // as a black box.
    uncontrolled?: true,
    value: string,
    type?: $PropertyType<HTMLInputElement, 'type'>,
    onChange?: (string) => any,
    multiline?: boolean,
    rows?: number,
    min?: string,
    max?: string,
    textFieldRef?: {|current: ?HTMLInputElement|} | ((?HTMLInputElement) => void),
    autocomplete?: boolean,
    onEnter?: (SyntheticKeyboardEvent<*>) => void,
    onEscape?: (SyntheticKeyboardEvent<*>) => void,
    onKeyDown?: (SyntheticKeyboardEvent<*>) => void,
    focusOnMount?: boolean,
    selectOnFocus?: boolean,
    blinkSuccess?: boolean,
    hasError?: boolean,
    noPadding?: boolean,
    noBorder?: boolean,
    errorMessage?: string,
    placeholder?: string,
    fitContent?: boolean,
    size?: 'small' | 'normal' | 'medium' | 'big' | 'expand',
    inputUnit?: string,
    id?: string,
    ignorePasswordAutoFill?: boolean,

    // redux-form uses a different type of event than our components,
    // so we're typing this as "any" for now
    onFocus?: (event: any) => void,
};

/**
 * Use this component for text input in forms.  It is completely
 * stateless and controlled, so the parent will need to manage its value.
 */
export class TextField extends React.PureComponent<Props> {
    static displayName = 'TextField';
    node: ?HTMLInputElement;

    static defaultProps = {
        value: '',
        size: 'normal',
    };

    componentDidMount() {
        // Not sure why, but autoFocus isn't working, and a setImmediate is needed.
        setImmediate(() => {
            if (this.node && this.props.focusOnMount) {
                this.node.focus();
            }
        });
    }

    render() {
        const {
            autocomplete,
            uncontrolled,
            multiline,
            blinkSuccess,
            hasError,
            errorMessage,
            placeholder,
            noBorder,
            noPadding,
            fitContent,
            size,
            selectOnFocus, // eslint-disable-line no-unused-vars
            textFieldRef,
            // We don't want to spread in onChange, since we use `onInput` instead, and can't have both
            onChange, // eslint-disable-line no-unused-vars
            // We don't want to spread in onEnter, either
            onEnter, // eslint-disable-line no-unused-vars
            onEscape, // eslint-disable-line no-unused-vars
            onFocus, // eslint-disable-line no-unused-vars
            onKeyDown,
            inputUnit,
            focusOnMount, // eslint-disable-line no-unused-vars
            ignorePasswordAutoFill,
            ...restProps
        } = this.props;

        const NodeType = multiline ? 'textarea' : 'input';
        const styleNames = classnames({
            textfield: !multiline,
            'textfield--no-border': noBorder,
            'textfield--no-padding': noPadding,
            'textfield--bordered': !noBorder,
            'textfield--multiline': multiline,
            'textfield--success': blinkSuccess,
            'textfield--error': hasError,
            'textfield--big': size === 'big',
            'textfield--small': size === 'small',
            'textfield--medium': size === 'medium',
            'textfield--expand': size === 'expand',
            'textfield--error-message': Boolean(errorMessage),
            'textfield--fit-content': fitContent,
        });
        const classNames = classnames({
            'keeper-ignore': ignorePasswordAutoFill, // For Keeper
        });

        // If component is uncontrolled we don't want to add onInput or value
        // to the actual input
        const controlledProps = uncontrolled
            ? {
                  value: undefined,
              }
            : {
                  value: this.props.value,
                  onChange: (e) => this.handleChange(e),
              };
        // Have to set the ref depending on what type of ref we got in props.  It could be the old
        // style callback ref, or a ref created with `useRef` which will be an object with a `current` property.
        const ref =
            typeof textFieldRef === 'object' && textFieldRef.hasOwnProperty('current')
                ? textFieldRef
                : this.setRef;
        if (typeof textFieldRef === 'object' && textFieldRef.hasOwnProperty('current')) {
            // If we're not using a callback ref, this.setRef isn't called, so we need to
            // set our internal node reference here.
            this.node = textFieldRef.current;
        }

        return (
            <>
                <NodeType
                    id={this.props.id}
                    className={classNames}
                    styleName={styleNames}
                    // If we have set rows, override the set height, allowing rows to show without scrolling
                    style={
                        this.props.multiline && this.props.rows
                            ? {height: `${this.props.rows * 26}px`}
                            : undefined
                    }
                    // $FlowFixMe upgrading Flow to v0.92.1 on web
                    ref={ref}
                    autoComplete={autocomplete ? 'on' : 'off'}
                    onKeyPress={this.handleKeyPress}
                    onKeyUp={this.handleKeyUp}
                    onFocus={this.handleFocus}
                    onKeyDown={onKeyDown}
                    placeholder={errorMessage ? errorMessage : placeholder}
                    data-1p-ignore={ignorePasswordAutoFill} // For 1Password
                    data-dashlane-autofill={ignorePasswordAutoFill} // For dashlane
                    data-form-type={ignorePasswordAutoFill ? 'other' : null} // Generic hopefully catch all
                    {...restProps}
                    {...controlledProps}
                />
                {inputUnit ? <span styleName='unit'>{inputUnit}</span> : undefined}
            </>
        );
    }

    setRef = (node: ?HTMLInputElement) => {
        this.node = node;
        if (typeof this.props.textFieldRef === 'function') {
            this.props.textFieldRef(node);
        }
    };

    handleFocus = (e: SyntheticInputEvent<*>) => {
        if (this.props.selectOnFocus && this.node) {
            this.node.select();
        }

        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    };

    handleChange(e: SyntheticInputEvent<*>) {
        const newValue = e.target.value;
        if (this.props.value === newValue) return;
        if (this.props.onChange) {
            if (
                this.props.type === 'number' &&
                ((typeof this.props.max !== 'undefined' &&
                    Number(newValue) > Number(this.props.max)) ||
                    (typeof this.props.min !== 'undefined' &&
                        Number(newValue) < Number(this.props.min)))
            ) {
                // Don't even allow the form to be set, don't rely on validation
                // upon submission
                return;
            }
            this.props.onChange(e.target.value);
        }
    }

    handleKeyPress = (e: SyntheticKeyboardEvent<*>) => {
        if (e.key === 'Enter' && this.props.onEnter) {
            this.props.onEnter(e);
        }
    };

    handleKeyUp = (e: SyntheticKeyboardEvent<*>) => {
        if (e.keyCode === 27 && this.props.onEscape) {
            this.props.onEscape(e);
        }
    };
}
