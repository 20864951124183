/* @flow */
import * as React from 'react';

import {AutoDistributionBoltIcon} from 'shells/icon';
import {colors} from 'shells/colors';

export const AUTO_RESOLVE_ID = 'auto-resolve';

export const AUTO_DISTRIBUTE_OPTION = {
    value: AUTO_RESOLVE_ID, // need a unique id which we set to undefined/null before sending it to the backend
    label: (
        <div className='flex gap-4'>
            <AutoDistributionBoltIcon size={12} color={colors.grey} /> Auto distribute
        </div>
    ),
};
