/* @flow */

import * as React from 'react';

import './static-two-line-with-icon.css';

type Props = {
    title: string | React.Node,
    subtitle: string | React.Node,
    icon?: React.ComponentType<*> | React.Node,
    isDisabled?: boolean,
    disabledSubtitleText?: string,
};

export function StaticTwoLineWithIcon(props: Props) {
    const Icon = props.icon && typeof props.icon === 'function' ? props.icon : undefined;
    const iconComponent = props.icon && typeof props.icon === 'object' ? props.icon : undefined;

    return (
        <div styleName={props.isDisabled ? 'title-container--disabled' : 'title-container'}>
            <div styleName='title'>{props.title}</div>
            {props.isDisabled && props.disabledSubtitleText ? (
                <i>{props.disabledSubtitleText}</i>
            ) : (
                <div styleName='type-container'>
                    {Icon || iconComponent ? (
                        <div styleName='icon'>
                            {Icon ? <Icon size={14} /> : undefined}
                            {iconComponent ? iconComponent : undefined}
                        </div>
                    ) : undefined}
                    {props.subtitle ? props.subtitle : undefined}
                </div>
            )}
        </div>
    );
}
