/* @flow */
import * as React from 'react';
import {Field, type FieldProps} from 'redux-form';

import {FormField} from 'shells/form';

// import {RichContactSelect} from './rich-contact-select';
import {RichContactSelect} from './rich-contact-select';

type Props = {
    ...FieldProps,
    title?: string,
    helperText?: string,
    isRequired?: boolean,
    validate: Function,
};
export function RichContactSelectField(props: Props) {
    const {title, helperText, isRequired, validate} = props;

    return (
        <FormField
            innerId={props.input.name}
            label={title}
            helperText={helperText}
            errorMessage={props.meta.error && props.meta.submitFailed ? props.meta.error : null}
            isRequired={isRequired}
            validate={validate}
        >
            <Field
                name={props.input.name}
                component={RichContactSelect}
                label={title}
                isRequired={isRequired}
                validate={validate}
            />
        </FormField>
    );
}
