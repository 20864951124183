import PropTypes from 'prop-types';
import * as React from 'react';
import getClasses from '../../mixins/get-classes';

export class RadioButtonDeprecated extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        value: PropTypes.any.isRequired,
        name: PropTypes.string,
        index: PropTypes.number,
        disabled: PropTypes.bool,
        type: PropTypes.string,
        selected: PropTypes.bool,
        onSelect: PropTypes.func,
    };

    static defaultProps = {
        type: 'div',
        disabled: false,
    };

    getClasses = getClasses;

    render() {
        const classes = this.getClasses('ui-radio-btn', {
            'ui-input--no-focus': true,
            'ui-radio-btn--type-div': this.props.type === 'div',
            'ui-radio-btn--type-standard': this.props.type === 'standard',
        });

        const {disabled, name, index, value, selected, children: inputLabel} = this.props;
        const id = `${name}-${index}`;

        return (
            <div className={classes}>
                <input
                    type='radio'
                    id={id}
                    name={name}
                    value={value}
                    checked={selected}
                    onChange={this.handleChange}
                    disabled={disabled}
                />
                <label htmlFor={id}>{inputLabel}</label>
            </div>
        );
    }

    handleChange = (e) => {
        e.stopPropagation();
        this.props.onSelect(this.props.value);
    };
}
