/* @flow */

import * as React from 'react';
import {reduxForm, formValueSelector} from 'redux-form';
import {useSelector} from 'react-redux';
import type {FormProps} from 'redux-form/lib/types.js.flow';

import type {GetFields_createModalFields as CreateModalField} from 'nutshell-graphql-types';

import type {Assignable, Stageset, Source, Market, Competitor} from 'nutshell-core/types';

import {LeadIcon} from 'shells/icon';

import type {FlashMessage} from '../../creator/types';
import {FormWrapper} from '../form-wrapper/form-wrapper';
import {DefaultFooter} from '../default-footer/default-footer';
import {BulkCreateLeadFooter} from '../footers/bulk-create-lead-footer';
import {getFieldsToDisplay, getFieldComponent} from './helpers';

import '../form-common.css';

const ENTITY_FORM_TYPE = 'leads';

export type MassSelectOptions = {
    omittedItems: string[],
    isMassSelect: boolean,
    filters: Object[],
    q: ?string,
    selectedCount: number,
};

// Form key
export const LEADS_FORM_KEY = 'leads_modal';

// State keys related to this form
export const NUM_LEADS_CREATING = 'numLeadsCreating';
export const RELATED_ENTITY = 'relatedEntity';
export const IS_MASS_SELECT = 'isMassSelect';
export const CREATOR_ORIGIN_ID = 'creatorOriginId';
export const MASS_SELECT_OPTIONS = 'massSelectOptions';

// Field rendered in BulkCreateLeadFooter
export const IS_CREATING_SINGLE_LEAD_FROM_MULTIPLE = 'isCreatingSingleLeadFromMultiple';

// Default values/options related to this form
export const RELATED_ENTITY_ACCOUNT_OPTION = {
    name: 'Company name',
    id: 'relatedEntityAccount',
    type: 'accounts',
};
export const RELATED_ENTITY_CONTACT_OPTION = {
    name: 'Person name',
    id: 'relatedEntityContact',
    type: 'contacts',
};

type Props = {
    schema: any,
    selectedFields: CreateModalField[],
    isLoading: boolean,
    handleSubmit: $PropertyType<FormProps, 'handleSubmit'>,
    reset: $PropertyType<FormProps, 'reset'>,
    submitting: $PropertyType<FormProps, 'submitting'>,
    submitFailed: $PropertyType<FormProps, 'submitFailed'>,
    submitSucceeded: $PropertyType<FormProps, 'submitSucceeded'>,

    onCancelClick: () => void,
    // eslint-disable-next-line react/no-unused-prop-types
    pushFlashMessage: (FlashMessage) => void,

    // eslint-disable-next-line react/no-unused-prop-types
    usersAndTeams: Assignable[],
    // eslint-disable-next-line react/no-unused-prop-types
    stagesets: Stageset[],
    // eslint-disable-next-line react/no-unused-prop-types
    sources: Source[],
    // eslint-disable-next-line react/no-unused-prop-types
    markets: Market[],
    // eslint-disable-next-line react/no-unused-prop-types
    competitors: Competitor[],
    currentMarket: ?Market,
    numSelectedContacts: number,
    numSelectedAccounts: number,
    bulkCreateFields: Object,
    massSelectOptions: MassSelectOptions,
};

export function LeadFormConnectedComponent(props: Props) {
    function handleCancelClick() {
        props.onCancelClick();
        props.reset();
    }

    // Disable fields when form is submitting and after the form has been submitted successfully
    const isDisabled = props.submitSucceeded || props.submitting;

    const numLeadsCreating = props.bulkCreateFields[NUM_LEADS_CREATING];
    const relatedEntity = props.bulkCreateFields[RELATED_ENTITY];

    const isCreatingSingleLeadFromMultiple =
        props.bulkCreateFields[IS_CREATING_SINGLE_LEAD_FROM_MULTIPLE];
    const isBulkCreating = Boolean(numLeadsCreating && numLeadsCreating > 1 && relatedEntity);
    const nameFieldIsReadOnly = isBulkCreating && !isCreatingSingleLeadFromMultiple;
    const companyFieldIsReadOnly =
        (isBulkCreating && !isCreatingSingleLeadFromMultiple) ||
        (isBulkCreating && relatedEntity === 'contacts');
    const personFieldIsReadOnly =
        (isBulkCreating && !isCreatingSingleLeadFromMultiple) ||
        (isBulkCreating && relatedEntity === 'accounts');

    const selector = formValueSelector(LEADS_FORM_KEY);
    const selectedContacts = useSelector((state) => selector(state, 'links.contacts'));
    const selectedAccounts = useSelector((state) => selector(state, 'links.accounts'));

    const footerComponent = isBulkCreating ? (
        <BulkCreateLeadFooter
            onCancel={handleCancelClick}
            isSaving={props.submitting}
            errorSaving={props.submitFailed}
            handleSubmit={props.handleSubmit}
            relatedEntity={relatedEntity}
            isCreatingSingleLeadFromMultiple={isCreatingSingleLeadFromMultiple}
            numLeadsCreating={numLeadsCreating}
            isDisabled={isDisabled}
            creatorOriginId={props.bulkCreateFields[CREATOR_ORIGIN_ID]}
            numSelectedEntities={
                relatedEntity === 'contacts' ? props.numSelectedContacts : props.numSelectedAccounts
            }
        />
    ) : (
        <DefaultFooter
            onCancel={handleCancelClick}
            isSaving={props.submitting}
            errorSaving={props.submitFailed}
            buttonText='Create lead'
            savingText='Creating lead…'
        />
    );

    const fields = getFieldsToDisplay({
        schema: props.schema,
        selectedFields: props.selectedFields,
        entityType: ENTITY_FORM_TYPE,
        selectedContacts,
        selectedAccounts,
        nameFieldIsReadOnly,
        companyFieldIsReadOnly,
        personFieldIsReadOnly,
        relatedEntity,
    });
    const fieldComponents = fields.map((field) =>
        getFieldComponent({
            field,
            entityType: ENTITY_FORM_TYPE,
            currentMarket: props.currentMarket,
        })
    );

    return (
        <form styleName='form' onSubmit={props.handleSubmit}>
            <FormWrapper
                icon={LeadIcon}
                reset={props.reset}
                footer={footerComponent}
                isLoading={props.isLoading}
            >
                {fieldComponents}
            </FormWrapper>
        </form>
    );
}

export const LeadFormConnected = reduxForm({
    form: LEADS_FORM_KEY,
})(LeadFormConnectedComponent);
