/* @flow */

import * as React from 'react';
import './radio-group-simple.css';

type Option = {
    label: string | React.Element<any>,
    id: string,
};

type Props = {
    name: string,
    isWide?: boolean,
    options: Option[],
    selectedOption?: string | Option,
    disabledOptions?: string | Option,
    // $FlowFixMe upgrading Flow to v0.110.1
    onChange: ($Subtype<string>, Event) => any,
};

export class RadioGroupSimple extends React.Component<Props> {
    render() {
        const {disabledOptions, selectedOption} = this.props;

        return (
            <div styleName='container'>
                {this.props.options.map((opt) => {
                    const other = {
                        onChange: (e) => {
                            this.props.onChange(opt.id, e);
                        },
                        checked: undefined,
                        disabled: false,
                    };

                    if (typeof selectedOption === 'string') {
                        other.checked = selectedOption === opt.id;
                    } else if (typeof selectedOption === 'object') {
                        other.checked = selectedOption.id === opt.id;
                    }

                    if (Array.isArray(disabledOptions) && disabledOptions.length) {
                        // Allow array of strings or objects
                        other.disabled =
                            disabledOptions.includes(opt.id) ||
                            disabledOptions.map((x) => x.id).includes(opt.id);
                    }

                    return [
                        <input
                            key={opt.id}
                            id={opt.id}
                            name={this.props.name}
                            type='radio'
                            {...other}
                        />,
                        <label
                            styleName={this.props.isWide ? 'label' : 'label--icon-only'}
                            key={`${opt.id}-label`}
                            htmlFor={opt.id}
                        >
                            {opt.label}
                        </label>,
                    ];
                })}
            </div>
        );
    }
}
