import * as React from 'react';
import PropTypes from 'prop-types';
import {withFormsy, propTypes as formsyPropTypes} from 'formsy-react';

import getClassesMixin from '../../mixins/get-classes';
import {SearchableList} from './searchable-list';

class FormSearchableListComponent extends React.Component {
    getClasses = getClassesMixin;

    static propTypes = {
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        collection: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                type: PropTypes.string,
            })
        ).isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.array,
        emptyView: PropTypes.element.isRequired,
        isSingleSelect: PropTypes.bool,

        /**
         * This optional text will be displayed when no items are selected. It
         * can be an empty string. If it is null or undefined, the component
         * will render a default text containing the type(s), i.e. "No users or
         * teams selected."
         */
        noSelectedItemsText: PropTypes.string,
        ...formsyPropTypes,
    };

    static defaultProps = {
        value: [],
        validationError: '',
    };

    render() {
        return (
            <SearchableList
                {...this.props}
                value={this.props.getValue()}
                onChange={this.handleChange}
            />
        );
    }

    handleChange = (val) => {
        this.props.setValue(val);
    };
}

export const FormSearchableList = withFormsy(FormSearchableListComponent);
