/* @flow */

import * as React from 'react';
import {Link as RoutedLink} from 'react-router-dom';

import {getChannelNameFromChannel} from 'nutshell-core/channels/helpers';

import {SelectPickerSearch} from '../../select-picker-search/select-picker-search';
import type {ChannelType} from '../../channels';
import {Link} from '../../link';

import {SourcePickerOptionLabel} from './source-picker-option-label';
import type {Source} from './types';

import './sources-select.css';

type Props = {|
    channel?: ChannelType,
    sourceOptions: Source[],
    onSelectSource: (string) => void,
    canAccessSetup: boolean,
    onBlur?: () => void,
|};

export function SourcesSelect(props: Props) {
    const {channel: channelType, canAccessSetup} = props;

    const getPlaceholderText = (): string | React.Node => {
        return channelType ? (
            <>
                Search <strong>{getChannelNameFromChannel(channelType)}</strong> sources…
            </>
        ) : (
            'Search sources…'
        );
    };

    const getNoOptionsMessage = (): React.Node => {
        let message;

        if (props.sourceOptions.length) {
            // No sources found via search
            message = (
                <>
                    No{' '}
                    {channelType ? (
                        <strong>{getChannelNameFromChannel(channelType)} </strong>
                    ) : undefined}
                    sources found
                </>
            );
        } else {
            // No sources exist for a specific channel, or at all
            message = (
                <>
                    {channelType ? (
                        <strong>{getChannelNameFromChannel(channelType)}</strong>
                    ) : (
                        'This channel'
                    )}{' '}
                    doesn‘t have any sources yet
                </>
            );
        }

        return (
            <div>
                <div styleName='no-options-message-text'>{message}</div>
                {canAccessSetup ? (
                    <Link as={RoutedLink} to='/setup/source' size='small' variant='primary'>
                        Configure sources in settings »
                    </Link>
                ) : (
                    <i>Admins can add new sources in settings</i>
                )}
            </div>
        );
    };

    return (
        <SelectPickerSearch
            minHeight={34}
            placeholder={getPlaceholderText()}
            noOptionsMessage={getNoOptionsMessage()}
            autoFocus={true}
            openMenuOnFocus={true}
            preventScrollOnMenuOpen={true}
            onBlur={props.onBlur}
            options={props.sourceOptions.map((opt) => {
                return {value: opt.id, label: opt.name, type: opt.type, channel: opt.channel};
            })}
            onChange={(source: any) => {
                if (source) {
                    props.onSelectSource(source.value);
                }
            }}
            formatOptionLabel={SourcePickerOptionLabel}
        />
    );
}
