/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ArrowRightThinIconSvg from './icon-arrow-right-thin.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ArrowRightThinIconSvg {...validProps} />;
}
SvgComponent.iconName = 'arrowRightThinIcon';

export const ArrowRightThinIcon = svgIconWrapper(SvgComponent);
