/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import NavIconConnections from './nav-icon-connections.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <NavIconConnections {...validProps} />;
}
SvgComponent.iconName = 'navIconConnections';

export const NavIconConnectionsIcon = svgIconWrapper(SvgComponent);
