/* @flow */

const MIN_FIELD_LENGTH = 1;
export function shouldShowPotentialContacts(
    name: ?string,
    description: ?string,
    phoneNumber: ?string,
    email: ?string,
    isAnyFieldFocused: boolean,
    hasRelatedAccounts: boolean
) {
    return (
        (isAnyFieldFocused && hasRelatedAccounts) ||
        (name && name.length >= MIN_FIELD_LENGTH) ||
        (description && description.length >= MIN_FIELD_LENGTH) ||
        (phoneNumber && phoneNumber.length >= MIN_FIELD_LENGTH) ||
        (email && email.length >= MIN_FIELD_LENGTH)
    );
}

export function shouldAllowCreate(
    name: ?string,
    description: ?string,
    phoneNumber: ?string,
    email: ?string
) {
    return (
        (name && name.length >= MIN_FIELD_LENGTH) ||
        (description && description.length >= MIN_FIELD_LENGTH) ||
        (phoneNumber && phoneNumber.length >= MIN_FIELD_LENGTH) ||
        (email && email.length >= MIN_FIELD_LENGTH)
    );
}
