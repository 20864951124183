import PropTypes from 'prop-types';
import * as React from 'react';
import Formsy from 'formsy-react';
import _ from 'underscore';

import getClassesMixin from '../../mixins/get-classes';
import {CheckmarkSubmitButton} from '../../base/form-components/checkmark-submit-button';
import {FormRadioButton} from '../../base/form-components/form-radio-button';
import {FormTextField} from '../../base/form-components/form-textfield';
import {FormSelect} from '../../base/form-components/form-select';
import {FormStateSelect} from '../../base/form-components/form-state-select';
import {FormCountrySelect} from '../../base/form-components/form-country-select';
import {FormLocationSearch} from '../../base/form-components/form-location-search';

const exactMatchSelect = [
    {id: 'country', name: 'Country'},
    {id: 'state', name: 'State'},
    {id: 'city', name: 'City'},
    {id: 'postalCode', name: 'Postal code'},
    {id: 'areaCode', name: 'Area code'},
];

const rangeSelect = [
    {id: '5', name: '5mi (8km)'},
    {id: '25', name: '25mi (40km)'},
    {id: '50', name: '50mi (80km)'},
    {id: '100', name: '100mi (160km)'},
    {id: '250', name: '250mi (400km)'},
];

const placeholderMap = {
    city: 'a city',
    areaCode: 'an area code',
    postalCode: 'a postal code',
};

const defaultValues = {
    exactMatch: {
        selected: 'country',
        country: '*',
        state: '*',
    },
    range: {
        selected: '25',
        data: '',
    },
};

export class LocationFormDeprecated extends React.Component {
    getClasses = getClassesMixin;

    static propTypes = {
        value: PropTypes.object,
        name: PropTypes.string,
        onDirty: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    static defaultProps = {
        value: {
            selected: 'noLocation',
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            selected: props.value.selected,
            exactMatchSelected:
                (props.value.selected === 'exactMatch' && props.value.data.selected) ||
                defaultValues.exactMatch.selected,
            countrySelect:
                (props.value.selected === 'exactMatch' && props.value.data.country) ||
                defaultValues.exactMatch.country,
        };
    }

    render() {
        const {name: fieldName, value} = this.props;
        const formValues = _.extend(defaultValues, {[value.selected]: value.data});
        const {selected, exactMatchSelected, countrySelect, canSubmit} = this.state;

        return (
            <Formsy
                className='ui-form'
                onChange={(data, changed) => this.handleChange(changed)}
                onValidSubmit={(data) => this.serializeAndSubmitData(data)}
                onValid={() => this.setState({canSubmit: true})}
                onInvalid={() => this.setState({canSubmit: false})}
            >
                <FormRadioButton
                    name={`${fieldName}.selected.noLocation`}
                    value={selected === 'noLocation'}
                    onChange={() => this.setState({selected: 'noLocation'})}
                >
                    No location specified
                </FormRadioButton>
                <FormRadioButton
                    name={`${fieldName}.selected.exactMatch`}
                    value={selected === 'exactMatch'}
                    onChange={() => this.setState({selected: 'exactMatch'})}
                >
                    Exact location
                </FormRadioButton>
                <FormSelect
                    name={`${fieldName}.exactMatch.selected`}
                    styleOptions={{filled: true, fullWidth: true}}
                    value={formValues.exactMatch.selected}
                    onChange={(val) => this.handleExactMatchSelectionChange(val)}
                    selectValues={exactMatchSelect}
                />
                {exactMatchSelected !== 'areaCode' ? (
                    <FormCountrySelect
                        name={`${fieldName}.exactMatch.country`}
                        value={this.state.countrySelect}
                        onChange={(val) =>
                            this.setState({
                                selected: 'exactMatch',
                                countrySelect: val,
                            })
                        }
                        statesOnly={exactMatchSelected === 'state'}
                    />
                ) : undefined}
                {(exactMatchSelected === 'state' || exactMatchSelected === 'city') &&
                _.contains(['US', 'CA', 'BR', 'AU'], countrySelect) ? (
                    <FormStateSelect
                        name={`${fieldName}.exactMatch.state`}
                        country={countrySelect}
                        value={formValues.exactMatch.state}
                        onChange={() =>
                            this.setState({
                                selected: 'exactMatch',
                            })
                        }
                    />
                ) : undefined}
                {exactMatchSelected !== 'country' && exactMatchSelected !== 'state' ? (
                    <FormTextField
                        name={`${fieldName}.exactMatch.${exactMatchSelected}`}
                        key={exactMatchSelected}
                        className='ui-input--full-width'
                        placeholder={`Enter ${placeholderMap[exactMatchSelected]}…`}
                        value={formValues.exactMatch[exactMatchSelected]}
                        onChange={() => this.setState({selected: 'exactMatch'})}
                        validations={selected === 'exactMatch' ? 'minLength:3' : null}
                        required={selected === 'exactMatch'}
                    />
                ) : undefined}
                <FormRadioButton
                    name={`${fieldName}.selected.range`}
                    value={selected === 'range'}
                    onChange={() => this.setState({selected: 'range'})}
                >
                    Within
                </FormRadioButton>
                <FormSelect
                    name={`${fieldName}.range.selected`}
                    value={formValues.range.selected}
                    onChange={() => this.setState({selected: 'range'})}
                    selectValues={rangeSelect}
                />
                <span className='ui-location-form__of'>of</span>
                <FormLocationSearch
                    name={`${fieldName}.range.data`}
                    required={selected === 'range'}
                    value={formValues.range.data}
                    popoverClass='ui-popover--location-search'
                    onSelect={() => this.setState({selected: 'range'})}
                    accessToken={MapboxConfig.apiKey}
                />
                <CheckmarkSubmitButton
                    className='ui-popover__submit-button'
                    disabled={!canSubmit}
                />
            </Formsy>
        );
    }

    // We manually change the state of our radio group based on this component's state. This overrides
    // Formsy's internal "changed" function, so we need to check for selected dirty state here.
    handleChange(isDirty) {
        if (isDirty || this.state.selected !== this.props.value.selected) {
            this.props.onDirty(true);
        }
    }

    handleExactMatchSelectionChange(val) {
        this.setState((prevState) => {
            const countryVal =
                val === 'state' && !_.contains(['US', 'CA', 'AU', 'BR'], prevState.countrySelect)
                    ? 'US'
                    : prevState.countrySelect;

            return {
                selected: 'exactMatch',
                exactMatchSelected: val,
                countrySelect: countryVal,
            };
        });
    }

    serializeAndSubmitData(data) {
        const fieldName = this.props.name;
        const selected = _.find(
            Object.keys(data[fieldName].selected),
            (key) => data[fieldName].selected[key] === true
        );

        const dataToSubmit = {
            [fieldName]: {
                selected: selected,
                data: selected !== 'noLocation' ? data[fieldName][selected] : null,
            },
        };

        this.props.onSubmit(dataToSubmit);
    }
}
