/* @flow */

import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

// Example usage:
//
// const [width] = useResizeObserver(yourRef.current);
//
// React.useEffect(() => {
//        setWidth(width);
//  }
// }, [width]);

// Reusable resize observer hook, adapted from the internet
export const useResizeObserver = (ref: ?HTMLElement) => {
    const [width, setWidth] = React.useState();
    const [height, setHeight] = React.useState();

    const handleResize = React.useCallback((entries) => {
        // https://stackoverflow.com/a/76714495
        window.requestAnimationFrame(() => {
            if (!Array.isArray(entries) || entries.length === 0) {
                return;
            }

            const entry = entries[0];
            setWidth(entry.contentRect.width);
            setHeight(entry.contentRect.height);
        });
    }, []);

    React.useLayoutEffect(() => {
        if (!ref) {
            return;
        }

        let resizeObserver = new ResizeObserver((entries) => handleResize(entries));
        resizeObserver.observe(ref);

        return () => {
            resizeObserver ? resizeObserver.disconnect() : undefined;
            resizeObserver = null;
        };
    }, [ref, handleResize]);

    return [width, height];
};
