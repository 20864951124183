/* @flow */

import * as React from 'react';

import {Body} from '../../typography';
import {StatusBar} from '../../control-bar';
import {Stack} from '../../layout';
import {CloudDownloadIcon, CopyIcon, EmailIcon} from '../../icon';
import {ActionMenu} from '../../action-menu';
import type {Level} from '../../status-badge';

import './quotes-section.css';

type Props = {
    quote: {
        quoteId: string,
        quoteAmount: string,
        quoteStatus: string,
        quoteUpdatedAt?: string,
        quoteSentAt?: string,
        quoteAcceptedAt?: string,
        quoteArchivedAt?: string,
    },
};

export function QuoteRow(props: Props) {
    const {quote} = props;

    const getStatusLevel = (status: string): Level => {
        switch (status) {
            case 'Draft':
                return 'info';
            case 'Sent':
                return 'success';
            case 'Approved':
                return 'warning';
            case 'Archived':
                return 'info--grey';
            default:
                return 'info';
        }
    };

    const getSecondaryText = (status: string): string => {
        switch (status) {
            case 'Draft':
                return `Last updated ${quote.quoteUpdatedAt || ''}`;
            case 'Sent':
                return `Sent ${quote.quoteSentAt || ''}`;
            case 'Approved':
                return `Approved ${quote.quoteAcceptedAt || ''}`;
            case 'Archived':
                return `Archived ${quote.quoteArchivedAt || ''}`;
            default:
                return '';
        }
    };

    return (
        <div className='flex align-center justify-sb'>
            <StatusBar
                status={{
                    level: getStatusLevel(quote.quoteStatus),
                    text: quote.quoteStatus,
                    badgeWidth: '80px',
                }}
                primaryText={quote.quoteAmount}
                secondaryText={getSecondaryText(quote.quoteStatus)}
            />
            <Stack isHorizontal={true} spacing={0} alignCenter={true}>
                <Body color='dark'>#{quote.quoteId}</Body>
                <ActionMenu
                    size='small'
                    hasHoverBackground={true}
                    options={[
                        {
                            label: 'Clone this quote',
                            icon: <CopyIcon size={15} wrapWithDiv={false} />,
                            handleSelect: () => {},
                        },
                        {
                            label: 'Download',
                            icon: <CloudDownloadIcon size={15} wrapWithDiv={false} />,
                            handleSelect: () => {},
                        },
                        {
                            label: 'Email',
                            icon: <EmailIcon size={15} wrapWithDiv={false} />,
                            handleSelect: () => {},
                        },
                    ]}
                />
            </Stack>
        </div>
    );
}
