/* @flow */

import * as React from 'react';
import Select, {
    mergeStyles,
    // $FlowIgnore this exists
    createFilter,
    type SingleValueProps,
    type MultiValueProps,
    type StylesConfig,
    type ActionMeta,
} from 'react-select';
// $FlowIgnore It exists
import AsyncSelect from 'react-select/lib/Async';

import {selectPickerGenericStyles} from '../select-picker-generic/select-picker-generic-styles';
import {ColorNamedOption} from '../select-option-components';
import {colors} from '../colors';

import {ValueComponent, type TokenStyle} from './value-component';

import styles from './select-inline.css';

type Props = {|
    options?: Object[],
    value: ?Object | Object[],
    onChange: (newValue: any, actionMeta: ActionMeta) => void,
    /** If multi prop is false, and the 'clearable' button is clicked, this fires */
    onClearValue?: () => void,
    labelKey: string,
    valueKey: string,
    autoBlur?: boolean,
    autoFocus?: boolean,
    onFocus?: () => void,
    onBlur?: () => void,
    clearable?: boolean,
    multi?: boolean,
    placeholder?: string,
    inlineLabel?: string,
    inlineIcon?: React.Node,
    searchable?: boolean,
    openMenuOnClick?: boolean,
    disabled?: boolean,
    tokenizedStyle: TokenStyle,
    shouldStyleValueByType?: boolean,
    errorMessage?: string,
    menuPortalTarget?: HTMLElement,
    menuPortalStyles?: $PropertyType<StylesConfig, 'menuPortal'>,
    styles?: StylesConfig,
    transparentBackground?: boolean,
    loadOptions?: (string) => Promise<*>,
    noOptionsMessage?: ({inputValue: string}) => string | null,
    defaultOptions?: true | Object[],
    components: Object,
    rightContents?: React.Node,
    isTwoLine?: boolean,
    hasDropdownIndicator?: boolean,
|};

/**
 * Use this select component when you want to show selected values as tokens.
 * Can be single or multiple values.
 *
 * If you provide options which do not have an `id` or `name` key,
 * use the `valueKey` and `labelKey` respectively.
 */
export class SelectInline extends React.Component<Props> {
    static defaultProps = {
        placeholder: 'Type to search…',
        valueKey: 'id',
        labelKey: 'name',
        autoFocus: false,
        clearable: false,
        multi: false,
        transparentBackground: false,
        components: {},
    };

    render() {
        const styleName =
            this.props.inlineLabel || this.props.inlineIcon ? 'container--labeled' : 'container';
        const {labelKey, valueKey} = this.props;

        let Component = Select;
        if (this.props.loadOptions) {
            Component = AsyncSelect;
        }

        const componentStyles = {
            input: (base) => ({
                ...base,
                // height: 'auto',
                padding: 0,
                marginBottom: 6,
            }),
            control: (base, state) => ({
                ...base,
                '&:hover': {},
                backgroundColor: this.props.transparentBackground ? undefined : '#fff',
                boxShadow: 'none',
                border: 'none',
                paddingTop: 5,
                marginLeft: this.getMarginLeft(),
                minHeight: 26,
                cursor: state.isDisabled ? 'default' : 'pointer',
            }),
            indicatorsContainer: (base) => ({
                ...base,
                marginTop: -5,
            }),
            clearIndicator: (base) => ({
                ...base,
                padding: '4px 8px',
            }),
            placeholder: (base) => ({
                ...base,
                // offsets the paddingTop needed for the value
                marginTop: -3,
                color: this.props.errorMessage ? colors.rose : colors.greyLt,
            }),
            option: selectPickerGenericStyles.option,
            menuPortal: (base, state) => {
                if (this.props.menuPortalStyles) {
                    return this.props.menuPortalStyles(base, state);
                }

                return base;
            },
        };

        const components = {
            SingleValue: this.getValueComponent,
            MultiValue: this.getMultiValueComponent,
            ...(this.props.tokenizedStyle === 'colorName' ? {Option: ColorNamedOption} : {}),
            ...this.props.components,
        };

        if (!this.props.hasDropdownIndicator) {
            components.DropdownIndicator = null;
        }

        if (!this.props.clearable) {
            components.IndicatorSeparator = null;
        }

        // Add in styles from props
        const mergedStyles = this.props.styles
            ? mergeStyles(componentStyles, this.props.styles)
            : componentStyles;

        return (
            <div className={styles[styleName]}>
                {this.props.inlineLabel ? (
                    <label htmlFor={this.props.inlineLabel}>{this.props.inlineLabel}</label>
                ) : undefined}
                {this.props.inlineIcon}
                <Component
                    inputId={this.props.inlineLabel}
                    isDisabled={this.props.disabled}
                    options={this.props.options}
                    defaultOptions={this.props.defaultOptions}
                    isMulti={this.props.multi}
                    value={this.props.value}
                    placeholder={this.props.errorMessage || this.props.placeholder}
                    isClearable={this.props.clearable}
                    isSearchable={this.props.searchable}
                    autoFocus={this.props.autoFocus}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    openMenuOnClick={this.props.openMenuOnClick}
                    filterOption={customFilterOption}
                    blurInputOnSelect={this.props.autoBlur}
                    valueComponent={this.getValueComponent}
                    menuPortalTarget={this.props.menuPortalTarget}
                    noOptionsMessage={this.props.noOptionsMessage}
                    loadOptions={this.props.loadOptions}
                    styles={mergedStyles}
                    isValidNewOption={this.isValidNewOption}
                    components={components}
                    onChange={this.handleChange}
                    getOptionLabel={(option) => option[labelKey]}
                    getOptionValue={(option) => option[valueKey]}
                    formatOptionLabel={
                        this.props.isTwoLine ? this.formatTwoLineOptionLabel : undefined
                    }
                />
                {this.props.rightContents ? (
                    <div className={styles['right-contents']}>{this.props.rightContents}</div>
                ) : null}
            </div>
        );
    }

    // Allow creating options that are non-zero length and aren't already selected or existing items
    isValidNewOption = (
        inputValue: string,
        selectValue: Object[],
        selectOptions: Object[]
    ): boolean => {
        const existingNames = selectOptions
            .concat(selectValue)
            .filter(Boolean)
            .map((option) => option[this.props.labelKey])
            .map((name) => name.toLowerCase().trim());

        return (
            inputValue.trim().length > 0 && !existingNames.includes(inputValue.toLowerCase().trim())
        );
    };

    getMultiValueComponent = (propsFromReactSelect: MultiValueProps) => {
        return (
            <ValueComponent
                data={propsFromReactSelect.data}
                labelKey={this.props.labelKey}
                valueKey={this.props.valueKey}
                shouldStyleValueByType={this.props.shouldStyleValueByType}
                tokenizedStyle={this.props.tokenizedStyle}
                onRemove={propsFromReactSelect.removeProps.onClick}
                isTwoLine={this.props.isTwoLine}
            />
        );
    };

    // We'll absolutely position our label to avoid any annoying css
    // intricacies built into the select component. If it's an icon,
    // we need less room
    getMarginLeft = () => {
        if (this.props.inlineLabel) return 64;
        else if (this.props.inlineIcon) return 24;
        else return 0;
    };

    // Disabling the next line, eslint bug where it doesn't pick up onRemove
    getValueComponent = (propsFromReactSelect: SingleValueProps) => {
        return (
            <ValueComponent
                data={propsFromReactSelect.data}
                labelKey={this.props.labelKey}
                valueKey={this.props.valueKey}
                tokenizedStyle={this.props.tokenizedStyle}
                isTwoLine={this.props.isTwoLine}
            />
        );
    };

    handleChange = (value: ?Object | Object[], actionMeta: ActionMeta) => {
        if (!value) {
            if (typeof this.props.onClearValue === 'function') {
                this.props.onClearValue();
            }
        } else {
            this.props.onChange(value, actionMeta);
        }
    };

    formatTwoLineOptionLabel(option: Object) {
        return (
            <div
                styleName={
                    this.props && this.props.value && option === this.props.value
                        ? 'option--selected'
                        : 'option'
                }
            >
                <div styleName='label-container'>
                    <div styleName='label'>{option.name}</div>
                    <div styleName='sublabel'>{option.description}</div>
                </div>
            </div>
        );
    }
}

/*
    `ignoreAccents: true` causes the stripDiacritics function to be called,
    which is extremely slow, and crashing long lists (1000+)

    https://github.com/JedWatson/react-select/blob/0fcc42d23a39be18d5443ef8365e75dce70a1e72/src/filters.js#L34-L37
    https://nutshell.atlassian.net/browse/NUT-11207
*/
const customFilterOption = (option, input) => {
    // If our option is considered a "New" one, we'll
    // short circuit our option filtering and always
    // show it. This is necessary due to a bug in react-select
    // where a custom filter function breaks "+ Create new"
    // option filtering.
    if (option && option.data && option.data.__isNew__) {
        return true;
    }

    // Now use react-select's build in `createFilter` function,
    // but disable ignoreAccents, because it's really slow.
    const createFilterFunction = createFilter({
        ignoreCase: true,
        // `ignoreAccents: true` is very slow!!
        ignoreAccents: false,
        trim: true,
        matchFrom: 'any',
    });

    return createFilterFunction(option, input);
};
