import PropTypes from 'prop-types';
import * as React from 'react';
import Formsy from 'formsy-react';

import getClassesMixin from '../../mixins/get-classes';
import {FormCheckbox} from '../../base/form-components/form-checkbox';
import {CheckmarkSubmitButton} from '../../base/form-components/checkmark-submit-button';

export class BooleanFormDeprecated extends React.Component {
    getClasses = getClassesMixin;

    static propTypes = {
        value: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        onDirty: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    static defaultProps = {
        value: false,
    };

    constructor(props) {
        super(props);
        this.state = {canSubmit: false};
    }

    render() {
        return (
            <Formsy
                className='ui-form'
                onChange={this.handleChange}
                onValidSubmit={this.handleSubmit}
                onValid={this.handleValid}
                onInvalid={this.handleInvalid}
            >
                <FormCheckbox
                    name={this.props.name}
                    label={this.props.title}
                    value={this.props.value}
                />
                <CheckmarkSubmitButton
                    className='ui-popover__submit-button'
                    disabled={!this.state.canSubmit}
                />
            </Formsy>
        );
    }

    handleSubmit = (data) => {
        const fieldName = this.props.name;
        const dataToSubmit = {
            [fieldName]: String(data[fieldName]),
        };

        this.props.onSubmit(dataToSubmit);
    };

    handleChange = (unused, changed) => {
        this.props.onDirty(changed);
    };

    handleValid = () => {
        this.setState({canSubmit: true});
    };

    handleInvalid = () => {
        this.setState({canSubmit: false});
    };
}
