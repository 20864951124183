/* @flow */

import * as React from 'react';

import type {SectionEntity} from './types';
import {AddingItemContainer} from './adding-item-container';

import './section-add-item-button.css';
import {EntityPageAddItemButton} from './entity-page-add-item-button';

type Props = {|
    // Section entity
    sectionEntity: SectionEntity,
    // Component that the add button turns into when clicked
    addItemComponent: React.Node,
    // Used as `+ Add [parentItemText] [entityType]`
    parentItemText?: React.Node,
    omitEntityName?: boolean,
    isAddingItem: boolean,
    setIsAddingItem: (boolean) => void,
|};

export function SectionAddItemButton(props: Props) {
    const {
        sectionEntity,
        addItemComponent,
        parentItemText,
        isAddingItem,
        setIsAddingItem,
        omitEntityName,
    } = props;

    return isAddingItem ? (
        <AddingItemContainer
            sectionEntity={sectionEntity}
            onClickRemove={() => setIsAddingItem(false)}
        >
            {addItemComponent}
        </AddingItemContainer>
    ) : (
        <EntityPageAddItemButton
            sectionEntity={sectionEntity}
            parentItemText={parentItemText}
            omitEntityName={omitEntityName}
            onAddItem={() => setIsAddingItem(true)}
        />
    );
}
