/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import NavIconAutomation from './nav-icon-automation.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <NavIconAutomation {...validProps} />;
}
SvgComponent.iconName = 'navIconAutomation';

export const NavIconAutomationIcon = svgIconWrapper(SvgComponent);
