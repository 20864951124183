/* @flow */

import * as React from 'react';

import {Divider} from '../../layout';
import {colors} from '../../colors';
import {StaticTwoLineWithIcon} from '../../list-row/static-two-line-with-icon';

import {getChannelIconFromChannel, type ChannelType} from '../../channels';
import {getDisplayableSourceType} from './helpers';
import type {SourceType} from './types';

import './source-picker-option-label.css';

const NEW_SOURCE_OPTION_VALUE = 'newSource';

type Props = {
    value: string,
    label: string,
    type: SourceType,
    channel: {value: ChannelType, title: string},
};

export function SourcePickerOptionLabel(props: Props) {
    if (props.value === NEW_SOURCE_OPTION_VALUE) {
        return <div>{props.label}</div>;
    }

    return (
        <StaticTwoLineWithIcon
            title={props.label}
            subtitle={getBottomRowForPicker(props.type, props.channel)}
            icon={props.channel ? getIconForPicker(props.channel.value) : undefined}
        />
    );
}

const getBottomRowForPicker = (
    type: SourceType,
    channel: ?{
        value: ChannelType,
        title: string,
    }
) => {
    if (channel) {
        const sourceType = getDisplayableSourceType(type);

        return (
            <div styleName='bottom-row'>
                {channel.title}
                {sourceType ? (
                    <div className='flex'>
                        <Divider variant='dot' spacing={8} color={colors.greyLt} />
                        {sourceType}
                    </div>
                ) : undefined}
            </div>
        );
    } else {
        return (
            <div styleName='bottom-row'>
                <i>No channel</i>
            </div>
        );
    }
};

const getIconForPicker = (value: ChannelType) => {
    const Icon = getChannelIconFromChannel(value);

    return Icon ? <Icon size={14} color={colors.greyLt} /> : undefined;
};
