/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {RemoveButton} from '../button';
import {getEntityClassName} from './helpers';
import type {SectionEntity} from './types';

import './adding-item-container.css';

type Props = {
    children: React.Node,
    sectionEntity: SectionEntity,
    onClickRemove: (any) => any,
};

export function AddingItemContainer(props: Props) {
    const {children, sectionEntity, onClickRemove} = props;

    const addItemStyleNames = classnames({
        'adding-item-container': true,
        ...getEntityClassName(sectionEntity),
    });

    return (
        <div styleName={addItemStyleNames}>
            <div styleName='add-item-component'>{children}</div>
            <div styleName='remove-button-container'>
                <RemoveButton size={10} onClick={onClickRemove} />
            </div>
        </div>
    );
}
