/* @flow */

import * as React from 'react';
import {ClockIcon, StarIcon} from 'shells/icon';
import './search-header.css';

type Props = {
    query: string,
};

export class SearchHeader extends React.Component<Props> {
    render() {
        return (
            <header styleName='search-header'>
                {this.props.query.length ? (
                    <h3>
                        <StarIcon /> Top results:
                    </h3>
                ) : (
                    <h3>
                        <ClockIcon /> Recently viewed:
                    </h3>
                )}
            </header>
        );
    }
}
