/* @flow */
import type {UnserializedFilterObject, FilterObject} from './types';
import {serializeFilterObject} from './serialize-filter-object';

// Attempting a comprehensive list of field types,
// this might not be exhaustive, however.
export type FilterFieldType =
    | 'string'
    | 'email'
    | 'currency'
    | 'number'
    | 'integer'
    | 'rating'
    | 'customField'
    | 'text'
    | 'phone'
    | 'relationship'
    | 'enum'
    | 'stage'
    | 'date-time'
    | 'location'
    | 'state'
    | 'map'
    | 'boolean';

export function serialize(
    filter: UnserializedFilterObject<*>,
    type: FilterFieldType
): FilterObject {
    const filterName = Object.keys(filter)[0];
    const filterValue = serializeFilterObject(filter[filterName], type);

    return {[filterName]: filterValue};
}
