/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {GraphQLSelectEntities} from '../../select/graphql-select-entities';

type Props = {
    ...FieldProps,
    isMulti: boolean,
    disabled?: boolean,
    isLinkable?: boolean,
};

export function EntitiesSelect(props: Props) {
    const isLinkableOnly = props.disabled && props.isLinkable;

    return (
        <GraphQLSelectEntities
            isMulti={props.isMulti}
            onChange={(newEntity) => {
                props.input.onChange(newEntity);
            }}
            selectedValues={props.input.value || []}
            clearable={!props.isMulti && !props.isLinkable}
            onClearValue={() => {
                props.input.onChange(null);
            }}
            disabled={props.disabled && !props.isLinkable}
            hasDropdownIndicator={!isLinkableOnly}
            searchable={!isLinkableOnly}
            openMenuOnClick={!isLinkableOnly}
            isLinkable={props.isLinkable}
            defaultOptions={props.isLinkable ? undefined : true}
        />
    );
}
