/* @flow */

import * as React from 'react';

import {Avatar} from '../avatar';
import {Button} from '../button/button';
import {CloseButton} from '../button/close-button/close-button';
import {Card} from '../card';
import {StatusBadge} from '../status-badge/status-badge';
import {Body} from '../typography/body';

import './contact-card.css';

type Contact = {
    name: string,
    avatarUrl?: string,
    phoneNumber: ?string,
    email: ?string,
    description: ?string,
    initials: string,
};

type Props = {
    contact: Contact,
    isNew: boolean,
    onRemove: () => void,
    onEdit?: () => void,
};

export function ContactCard(props: Props) {
    const removeButtonContainerRef = React.useRef(null);

    const handleEditButtonClick = (event: SyntheticEvent<*>) => {
        event.preventDefault();
        event.stopPropagation();
        if (props.onEdit) {
            props.onEdit();
        }
    };

    const handleRemoveClick = (event: SyntheticEvent<*>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onRemove();
    };

    return (
        <>
            {props.isNew && (
                <div styleName='status-badge'>
                    <StatusBadge level='success' text='New person' size='small' />
                </div>
            )}
            <Card
                hasBorder={true}
                hasShadow={false}
                preventUnderline={true}
                borderRadius={3}
                fitHeight={true}
            >
                <div className='pad-8 flex gap-8 full-width border-box'>
                    <div styleName='info'>
                        <div styleName='avatar'>
                            <Avatar
                                avatarUrl={props.contact.avatarUrl}
                                initials={props.contact.initials}
                                type='contacts'
                            />
                        </div>
                        <div className='flex flex-dir-col gap-4 full-width'>
                            {props.contact.name && (
                                <Body
                                    isBold={true}
                                    color='dark'
                                    size='small'
                                    shouldTruncate={true}
                                    title={props.contact.name}
                                >
                                    {props.contact.name}
                                </Body>
                            )}
                            {props.contact.email && (
                                <Body
                                    size='small'
                                    shouldTruncate={true}
                                    title={props.contact.email}
                                >
                                    {props.contact.email}
                                </Body>
                            )}
                            {props.contact.phoneNumber && (
                                <Body size='small' shouldTruncate={true}>
                                    {props.contact.phoneNumber}
                                </Body>
                            )}
                            {props.contact.description && (
                                <Body size='small' isItalic={true} shouldTruncate={true}>
                                    {props.contact.description}
                                </Body>
                            )}
                        </div>
                    </div>
                    <div styleName='edit-button'>
                        {props.onEdit && props.isNew && (
                            <Button variant='text-primary' onClick={handleEditButtonClick}>
                                Edit
                            </Button>
                        )}
                    </div>
                    <div styleName='remove-button' ref={removeButtonContainerRef}>
                        <CloseButton onClick={handleRemoveClick} size={10} variant='dark' />
                    </div>
                </div>
            </Card>
        </>
    );
}
