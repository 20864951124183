/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import {PageTitleBreadcrumbs, type Breadcrumb} from './page-title-breadcrumbs';

import './page-title-bar-title.css';

type Props = {
    title: string,
    titleComponent?: React.Node,
    titleBadge?: React.Node,
    subheaderPrimaryText: string | React.Node,
    isModified?: boolean,
    subheaderSecondaryText?: string,
    breadcrumbsTitle?: string,
    breadcrumbs?: Breadcrumb[],
    noMaxWidth?: boolean,
    icon?: any,
    isEditable?: boolean,
    glossaryButton?: React.Node,
};

export class PageTitleBarTitle extends React.Component<Props> {
    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isModified && prevProps.isModified !== this.props.isModified) {
            ReactTooltip.rebuild();
        }
    }

    render() {
        const Icon = this.props.icon;

        const styleNames = classnames({
            title: true,
            'title--no-truncate': this.props.noMaxWidth,
            'title--editable': this.props.isEditable,
        });

        return (
            <div styleName='page-title-bar-title'>
                <div styleName='icon-and-title'>
                    {Icon && !NutClientConfig.hasUpdatedNavigation ? (
                        <Icon styleName='icon' />
                    ) : undefined}
                    <div styleName='title-container'>
                        <div styleName='title-header-container'>
                            <h1 styleName={styleNames}>
                                {this.props.titleComponent || this.props.title}
                                {this.props.isModified ? (
                                    <span styleName='modified' data-tip='Unsaved'>
                                        *
                                    </span>
                                ) : undefined}
                            </h1>
                            {this.props.glossaryButton}
                            {this.props.titleBadge}
                        </div>
                        {this.props.breadcrumbs && this.props.breadcrumbs.length ? (
                            <PageTitleBreadcrumbs
                                title={this.props.breadcrumbsTitle || this.props.title}
                                breadcrumbs={this.props.breadcrumbs}
                            />
                        ) : (
                            <div styleName='subheader-container'>
                                {this.props.subheaderSecondaryText ? (
                                    <h4 styleName='subheader--secondary'>
                                        {this.props.subheaderSecondaryText}
                                    </h4>
                                ) : undefined}
                                <i>{this.props.subheaderPrimaryText}</i>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
