/* @flow */

import * as React from 'react';

import {Body} from '../typography';

type Props = {|
    maximumCharacters: number,
    length: number,
    minimumCharacters?: number,
|};

const SHOW_WARNING_THRESHOLD = 16;

export function CharacterLimit(props: Props) {
    const remainingCharacters = props.maximumCharacters - props.length;

    let characterCountText = (
        <Body size='small' color='light'>
            {props.length} / {props.maximumCharacters} characters
        </Body>
    );

    if (props.minimumCharacters && props.length < props.minimumCharacters) {
        characterCountText = (
            <Body size='small' color='light'>
                {props.length} / {props.maximumCharacters} characters (minimum of{' '}
                {props.minimumCharacters})
            </Body>
        );
    } else if (
        props.minimumCharacters &&
        props.length >= props.minimumCharacters &&
        props.length <= props.maximumCharacters
    ) {
        characterCountText = (
            <Body size='small' color='green'>
                {props.length} / {props.maximumCharacters} characters
            </Body>
        );
    }

    if (remainingCharacters < 0) {
        characterCountText = (
            <Body size='small' color='error'>
                {props.length} / {props.maximumCharacters} character limit exceeded
            </Body>
        );
    }

    const shouldShowWarning =
        remainingCharacters < SHOW_WARNING_THRESHOLD && remainingCharacters >= 0;

    return (
        <div className='flex align-center'>
            {shouldShowWarning && (
                <div className='mr-4'>
                    <Body size='small' color='error'>
                        {props.maximumCharacters - props.length} characters remaining
                    </Body>
                </div>
            )}
            {characterCountText}
        </div>
    );
}
