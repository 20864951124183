import PropTypes from 'prop-types';
import * as React from 'react';
import Formsy from 'formsy-react';
import moment from 'moment';
import _ from 'underscore';

import getClassesMixin from '../../mixins/get-classes';
import {CheckmarkSubmitButton} from '../../base/form-components/checkmark-submit-button';
import {FormRadioButton} from '../../base/form-components/form-radio-button';
import {FormTextField} from '../../base/form-components/form-textfield';
import {FormSelect} from '../../base/form-components/form-select';
import {FormDatePicker} from '../../base/form-components/form-date-picker';

const selectValues = (dataValue) => {
    const s = Number(dataValue) === 1 ? '' : 's';

    return [
        {id: 'minutes', name: `minute${s}`},
        {id: 'hours', name: `hour${s}`},
        {id: 'days', name: `day${s}`},
        {id: 'weeks', name: `week${s}`},
        {id: 'months', name: `month${s}`},
        {id: 'years', name: `year${s}`},
    ];
};

const currentValues = [
    {id: 'day', name: 'day'},
    {id: 'week', name: 'week'},
    {id: 'month', name: 'month'},
    {id: 'quarter', name: 'quarter'},
    {id: 'year', name: 'year'},
];

const toDateValues = [
    {id: 'week', name: 'week-to-date'},
    {id: 'month', name: 'month-to-date'},
    {id: 'quarter', name: 'quarter-to-date'},
    {id: 'year', name: 'year-to-date'},
];

const defaultValues = {
    within: {
        selected: 'days',
        data: '7',
    },
    lessThan: {
        selected: 'minutes',
        data: '30',
    },
    current: {
        selected: 'month',
    },
    todate: {
        selected: 'month',
    },
    between: {
        startDate: moment().unix(),
        endDate: moment().add(7, 'days').unix(),
    },
    next: {
        selected: 'hours',
        data: '24',
    },
};

export class DateTimeFormDeprecated extends React.Component {
    getClasses = getClassesMixin;

    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.object,
        nullOptionText: PropTypes.string,
        isFutureFilterable: PropTypes.bool,
        isOlderThanFilterable: PropTypes.bool,
        isCurrentFilterable: PropTypes.bool,
        isBetweenFilterable: PropTypes.bool,
        isToDateFilterable: PropTypes.bool,
        onDirty: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        animateIn: PropTypes.bool,
    };

    static defaultProps = {
        value: {
            selected: 'current',
            data: defaultValues.current,
        },
        isOlderThanFilterable: true,
        isCurrentFilterable: false,
        isBetweenFilterable: true,
        isFutureFilterable: false,
        isToDateFilterable: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            startDate:
                (props.value.data && props.value.data.startDate) || defaultValues.between.startDate,
            endDate:
                (props.value.data && props.value.data.endDate) || defaultValues.between.endDate,
            selected: props.value.selected,
            formValues: {...defaultValues, [props.value.selected]: props.value.data},
        };
    }

    componentDidMount() {
        const node = this[this.state.selected];
        const timeoutDuration = this.props.animateIn ? 250 : 0;

        if (node) {
            setTimeout(() => {
                node.focus();
            }, timeoutDuration);
        }
    }

    componentDidUpdate(unused, {selected: prevSelected}) {
        if (this.state.selected !== prevSelected) {
            const node = this[this.state.selected];
            if (node) {
                node.focus();
            }
        }
    }

    render() {
        const {
            name: fieldName,
            isFutureFilterable,
            isOlderThanFilterable,
            isCurrentFilterable,
            isBetweenFilterable,
            isToDateFilterable,
        } = this.props;
        const {formValues, selected} = this.state;
        // In case they manually enter a time, we'll show the UI even if the props say it's not allowed
        const hideNextOptions = !isFutureFilterable && selected !== 'next';
        const hideOlderThanOptions = !isOlderThanFilterable && selected !== 'lessThan';
        const hideCurrentOptions = !isCurrentFilterable && selected !== 'current';
        const hideBetweenOptions = !isBetweenFilterable && selected !== 'between';
        const hideToDateOptions = !isToDateFilterable && selected !== 'todate';

        return (
            <Formsy
                className='ui-form'
                onChange={(data, changed) => this.handleChange(changed)}
                onValidSubmit={(data) => this.serializeAndSubmitData(data)}
                onValid={() => this.setState({canSubmit: true})}
                onInvalid={() => this.setState({canSubmit: false})}
            >
                {hideCurrentOptions ? null : (
                    <div>
                        <FormRadioButton
                            name={`${fieldName}.selected.current`}
                            value={this.state.selected === 'current'}
                            onChange={() => this.setState({selected: 'current'})}
                        >
                            Current
                        </FormRadioButton>
                        <div className='ui-linked-select ui-linked-select--inline'>
                            <FormSelect
                                name={`${fieldName}.current.selected`}
                                value={formValues.current.selected}
                                onChange={() => this.setState({selected: 'current'})}
                                selectValues={currentValues}
                            />
                        </div>
                    </div>
                )}
                {hideToDateOptions ? null : (
                    <div>
                        <FormRadioButton
                            name={`${fieldName}.selected.todate`}
                            value={this.state.selected === 'todate'}
                            onChange={() => this.setState({selected: 'todate'})}
                        >
                            Current
                        </FormRadioButton>
                        <div className='ui-linked-select ui-linked-select--inline'>
                            <FormSelect
                                name={`${fieldName}.todate.selected`}
                                value={formValues.todate.selected}
                                onChange={() => this.setState({selected: 'todate'})}
                                selectValues={toDateValues}
                            />
                        </div>
                    </div>
                )}
                <FormRadioButton
                    name={`${fieldName}.selected.within`}
                    value={this.state.selected === 'within'}
                    onChange={() => this.setState({selected: 'within'})}
                >
                    Within the last
                </FormRadioButton>
                <div className='ui-linked-select ui-linked-select--inline'>
                    <FormTextField
                        name={`${fieldName}.within.data`}
                        formTextfieldRef={(c) => {
                            this.within = c;
                        }}
                        type='number'
                        min={1}
                        max={999}
                        value={formValues.within.data}
                        onChange={_.bind(this.handleInputChange, this, 'within')}
                        validations={
                            this.state.selected === 'within' ? 'isNumeric:1,minLength:1' : null
                        }
                    />
                    <FormSelect
                        name={`${fieldName}.within.selected`}
                        value={formValues.within.selected}
                        onChange={() => this.setState({selected: 'within'})}
                        selectValues={selectValues(formValues.within.data)}
                    />
                </div>
                {hideNextOptions ? null : (
                    <div>
                        <FormRadioButton
                            name={`${fieldName}.selected.next`}
                            value={this.state.selected === 'next'}
                            onChange={() => this.setState({selected: 'next'})}
                        >
                            In the next
                        </FormRadioButton>
                        <div className='ui-linked-select ui-linked-select--inline'>
                            <FormTextField
                                name={`${fieldName}.next.data`}
                                value={formValues.next.data}
                                maxLength={3}
                                onChange={() => this.setState({selected: 'next'})}
                                validations={
                                    this.state.selected === 'next'
                                        ? 'isNumeric:1,minLength:1'
                                        : null
                                }
                            />
                            <FormSelect
                                name={`${fieldName}.next.selected`}
                                value={formValues.next.selected}
                                onChange={() => this.setState({selected: 'next'})}
                                selectValues={selectValues(formValues.next.data)}
                            />
                        </div>
                    </div>
                )}
                {hideOlderThanOptions ? null : (
                    <div>
                        <FormRadioButton
                            name={`${fieldName}.selected.lessThan`}
                            value={this.state.selected === 'lessThan'}
                            onChange={() => this.setState({selected: 'lessThan'})}
                        >
                            Older than
                        </FormRadioButton>
                        <div className='ui-linked-select ui-linked-select--inline'>
                            <FormTextField
                                name={`${fieldName}.lessThan.data`}
                                formTextfieldRef={(c) => {
                                    this.lessThan = c;
                                }}
                                type='number'
                                min={1}
                                max={999}
                                value={formValues.lessThan.data}
                                onChange={_.bind(this.handleInputChange, this, 'lessThan')}
                                validations={
                                    this.state.selected === 'lessThan'
                                        ? 'isNumeric:1,minLength:1'
                                        : null
                                }
                            />
                            <FormSelect
                                name={`${fieldName}.lessThan.selected`}
                                value={formValues.lessThan.selected}
                                onChange={() => this.setState({selected: 'lessThan'})}
                                selectValues={selectValues(formValues.lessThan.data)}
                            />
                        </div>
                    </div>
                )}
                {hideBetweenOptions ? null : (
                    <div>
                        <FormRadioButton
                            name={`${fieldName}.selected.between`}
                            value={this.state.selected === 'between'}
                            onChange={() => this.setState({selected: 'between'})}
                        >
                            Between
                        </FormRadioButton>
                        <div className='ui-input-date-range'>
                            <FormDatePicker
                                name={`${fieldName}.between.startDate`}
                                value={this.state.startDate}
                                onChange={(date) => this.handleStartDateChange(date)}
                                dateFormat='D MMMM YYYY'
                            />
                            <FormDatePicker
                                name={`${fieldName}.between.endDate`}
                                value={this.state.endDate}
                                onChange={(date) => this.handleEndDateChange(date)}
                                dateFormat='D MMMM YYYY'
                            />
                        </div>
                    </div>
                )}
                {this.props.nullOptionText ? (
                    <FormRadioButton
                        name={`${fieldName}.selected.never`}
                        value={this.state.selected === 'never'}
                        onChange={() => this.setState({selected: 'never'})}
                    >
                        {this.props.nullOptionText}
                    </FormRadioButton>
                ) : undefined}
                <CheckmarkSubmitButton
                    className='ui-popover__submit-button'
                    disabled={!this.state.canSubmit}
                />
            </Formsy>
        );
    }

    // We manually change the state of our radio group based on this component's state. This overrides
    // Formsy's internal "changed" function, so we need to check for selected dirty state here.
    handleChange(isDirty) {
        if (isDirty || this.state.selected !== this.props.value.selected) {
            this.props.onDirty(true);
        }
    }

    handleInputChange(selected, newValue) {
        this.setState((prevState) => {
            const oldValue = prevState.formValues[selected];
            const values = {...prevState.formValues, [selected]: {...oldValue, data: newValue}};

            return {
                formValues: values,
                selected,
            };
        });
    }

    serializeAndSubmitData(data) {
        const fieldName = this.props.name;
        const selected = _.find(
            Object.keys(data[fieldName].selected),
            (key) => data[fieldName].selected[key] === true
        );

        const dataToSubmit = {
            [fieldName]: {
                selected: selected,
                data: selected !== 'never' ? data[fieldName][selected] : null,
            },
        };

        this.props.onSubmit(dataToSubmit);
    }

    handleStartDateChange(date) {
        this.setState({
            selected: 'between',
            startDate: date,
        });

        if (moment(date).isAfter(moment(this.state.endDate))) {
            // This could be smarter -- first iteration
            this.setState({endDate: date});
        }
    }

    handleEndDateChange(date) {
        this.setState({
            selected: 'between',
            endDate: date,
        });

        if (moment(date).isBefore(moment(this.state.startDate))) {
            // This could be smarter -- first iteration
            this.setState({startDate: date});
        }
    }
}
