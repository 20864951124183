/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import _ from 'underscore';
import {Popover} from 'shells/popover';
import {ListView} from 'shells/list-view';
import './autocomplete-results.css';

type Props = {
    anchor: HTMLElement,
    collection: Object[],
    popoverClass: 'entity' | 'location',
    loading?: boolean,
    header?: React.Element<any>,
    loadingView: React.Element<any>,
    emptyView: React.Element<any>,
    renderRow: Function,
    shouldClose: () => void,
    onSelect: (SyntheticEvent<>, Object) => void,
};

type State = {
    highlighted: number,
};

export class AutocompleteResults extends React.Component<Props, State> {
    keydownEventFunction: Function;
    list: ?HTMLElement;

    constructor(props: Props) {
        super(props);
        this.state = {
            highlighted: 0,
        };

        // Need to bind this here to avoid removing event problems.
        this.keydownEventFunction = this.handleKeydownEvent.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keydownEventFunction);
        this.killMousewheel();
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keydownEventFunction);
        this.unkillMousewheel();
    }

    render() {
        const styleNames = classnames({
            'entity-results': this.props.popoverClass === 'entity',
            'location-results': this.props.popoverClass === 'location',
        });

        return (
            <Popover location='custom' anchor={this.props.anchor} disableFocusTrapZone={true}>
                <div styleName={styleNames}>
                    <ListView
                        getListViewRef={(c) => {
                            this.list = c;
                        }}
                        collection={this.props.collection}
                        header={this.props.header}
                        renderRow={(item, index) =>
                            this.props.renderRow(item, index === this.state.highlighted)
                        }
                        isLoading={this.props.loading}
                        loadingState={{customComponent: this.props.loadingView}}
                        specialStates={[
                            {
                                shouldRender: () => this.props.collection.length === 0,
                                component: this.props.emptyView,
                            },
                        ]}
                    />
                </div>
            </Popover>
        );
    }

    handleKeydownEvent(e: SyntheticKeyboardEvent<>) {
        if (e.which === Nut.keys.ESC) this.props.shouldClose();

        if (!this.props.loading) {
            if (e.which === Nut.keys.RETURN) this.handleSelect(e);
            else if (e.which === Nut.keys.UP) this.scrollUp(e);
            else if (e.which === Nut.keys.DOWN) this.scrollDown(e);
        }
    }

    scrollUp(e: SyntheticEvent<>) {
        e.preventDefault();

        this.setState((prevState, props) => ({
            highlighted:
                prevState.highlighted === 0
                    ? props.collection.length - 1
                    : prevState.highlighted - 1,
        }));

        this.calculateAndScroll();
    }

    scrollDown(e: SyntheticEvent<>) {
        e.preventDefault();

        this.setState((prevState, props) => ({
            highlighted: (prevState.highlighted + 1) % props.collection.length,
        }));

        this.calculateAndScroll();
    }

    calculateAndScroll() {
        if (!this.props.collection.length || !this.list) return;

        const results = $(this.list).find('ul');
        const listItem = results.children()[this.state.highlighted];

        const mappedChildren = _.map(results.children(), function (child) {
            return $(child).innerHeight();
        });
        const previousChildren = _.first(mappedChildren, this.state.highlighted + 1);
        const heightInList = _.reduce(previousChildren, function (memo, height) {
            return memo + height;
        });

        if (
            $(listItem).position().top > results.innerHeight() - $(listItem).innerHeight() ||
            $(listItem).position().top < 0
        ) {
            results.scrollTop(heightInList - results.innerHeight());
        }

        this.forceUpdate();
    }

    killMousewheel() {
        $('body').on(
            'mousewheel',
            _.bind(function (e, delta, deltaX, deltaY) {
                const results = $(this.list).find('ul');
                const top = results.scrollTop();

                if ($(e.target).closest(results).length) {
                    if (deltaY > 0 && top - deltaY <= 0) {
                        results.scrollTop(0);
                        e.preventDefault();
                        e.stopPropagation();
                    } else if (
                        deltaY < 0 &&
                        results[0].scrollHeight - results.scrollTop() + deltaY <=
                            results.innerHeight()
                    ) {
                        results.scrollTop(results.get(0).scrollHeight - results.height());
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
            }, this)
        );
    }

    unkillMousewheel() {
        // $FlowIgnore
        $('body').unmousewheel();
    }

    handleSelect(e: SyntheticEvent<>) {
        e.stopPropagation();
        const selectedItem = this.props.collection[this.state.highlighted];

        if (selectedItem) {
            this.props.onSelect(e, selectedItem);
        }
    }
}
