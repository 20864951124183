/* @flow */

import * as React from 'react';

import type {FilterObject} from 'nutshell-core/types';

import {FilterCapsule} from 'shells/filter-capsule';
import {CheckboxButton, Button} from 'shells/button';
import {SearchBar} from 'shells/search-bar/search-bar';
import {convertToModels} from 'shells/filter-capsule/convert-to-models';

import {
    convertLastUpdatedOptionToRelativeTime,
    convertRelativeTimeToLastUpdatedOption,
} from './helpers';

import './expanded-filter-bar.css';

type Props = {
    ownerFilterProps?: {
        ownerFilter: ?string,
        collection: any[],
    },
    statusFilterProps?: {
        statusFilter: ?string,
        statusOptions: any[],
        statusDisabled: boolean,
    },
    lastUpdatedFilterProps?: {
        lastUpdatedFilter: ?string,
        lastUpdatedOptions: any[],
    },
    justMineFilterProps?: {
        isFilteringOnCreatorUserId: boolean,
        userId: string,
    },
    archivedFilterProps?: {
        isFilteringOnArchived: boolean,
    },
    ignoredFilterProps?: {
        isFilteringOnIgnored: boolean,
    },
    searchFilterProps?: {
        searchValue: string,
        onUpdateSearch: (newSearch: string) => void,
    },
    isFiltering: boolean,
    noun: 'broadcast' | 'newsletter' | 'drip sequence' | 'audience' | 'form' | 'template',
    count: number,
    onUpdateFilters: (FilterObject) => void,
    onRemoveAllFilters: () => void,
};

export function ExpandedFilterBar(props: Props) {
    const {
        ownerFilterProps,
        statusFilterProps,
        lastUpdatedFilterProps,
        justMineFilterProps,
        archivedFilterProps,
        ignoredFilterProps,
        searchFilterProps,
        isFiltering,
        noun,
        count,
        onUpdateFilters,
        onRemoveAllFilters,
    } = props;

    return (
        <React.Fragment>
            {ownerFilterProps ? (
                <div>
                    <FilterCapsule
                        key='owner'
                        size='big'
                        collection={convertToModels(ownerFilterProps.collection)}
                        filter={ownerFilterProps.ownerFilter}
                        filterName='owner'
                        schemaField={{
                            name: 'owner',
                            type: 'relationship',
                            title: 'Owner',
                            description: `A list of Nutshell users that a ${noun} must be owned by.`,
                            relationships: ['users', 'teams'],
                            isFilterable: true,
                            isDisplayable: true,
                            isSortable: true,
                            isAndable: true,
                            size: 'md',
                        }}
                        onSubmit={(newFilter: FilterObject) => {
                            onUpdateFilters({
                                owner: newFilter.owner,
                            });
                        }}
                        onRemove={() => {
                            onUpdateFilters({
                                owner: null,
                            });
                        }}
                    />
                </div>
            ) : undefined}
            {statusFilterProps ? (
                <div styleName='status'>
                    <FilterCapsule
                        hasSearch={false}
                        key='status'
                        size='big'
                        disabled={statusFilterProps.statusDisabled}
                        collection={convertToModels(statusFilterProps.statusOptions)}
                        filter={statusFilterProps.statusFilter}
                        filterName={'status'}
                        schemaField={{
                            type: 'enum',
                            cellType: 'description',
                            enum: statusFilterProps.statusOptions,
                            title: 'Status',
                            description: `Status of the ${noun}`,
                            isFilterable: true,
                            isDisplayable: true,
                            isSortable: true,
                            isAndable: false,
                            size: 'sm',
                            name: 'status',
                        }}
                        onSubmit={(newFilter: FilterObject) => {
                            onUpdateFilters({
                                status: newFilter.status,
                            });
                        }}
                        onRemove={() => {
                            onUpdateFilters({
                                status: '',
                            });
                        }}
                    />
                </div>
            ) : undefined}
            {lastUpdatedFilterProps ? (
                <div styleName='last-updated'>
                    <FilterCapsule
                        hasSearch={false}
                        key='status'
                        size='big'
                        collection={convertToModels(lastUpdatedFilterProps.lastUpdatedOptions)}
                        filter={convertRelativeTimeToLastUpdatedOption(
                            lastUpdatedFilterProps.lastUpdatedFilter
                        )}
                        filterName={'lastUpdated'}
                        schemaField={{
                            type: 'enum',
                            cellType: 'description',
                            enum: lastUpdatedFilterProps.lastUpdatedOptions,
                            title: 'Last updated',
                            description: `Last updated time`,
                            isFilterable: true,
                            isDisplayable: true,
                            isSortable: false,
                            isAndable: true,
                            size: 'sm',
                            name: 'lastUpdated',
                        }}
                        onSubmit={(newFilter: FilterObject) => {
                            const lastUpdatedRelativeTime =
                                convertLastUpdatedOptionToRelativeTime(newFilter);
                            onUpdateFilters({
                                lastUpdated: lastUpdatedRelativeTime,
                            });
                        }}
                        onRemove={() => {
                            onUpdateFilters({
                                lastUpdated: '',
                            });
                        }}
                    />
                </div>
            ) : undefined}
            <div className='flex'>
                {justMineFilterProps ? (
                    <div styleName='expanded-filter-checkbox'>
                        <CheckboxButton
                            label='Just mine'
                            checked={justMineFilterProps.isFilteringOnCreatorUserId}
                            onChange={() => {
                                if (justMineFilterProps.isFilteringOnCreatorUserId) {
                                    onUpdateFilters({creatorUserId: ''});
                                } else {
                                    onUpdateFilters({creatorUserId: justMineFilterProps.userId});
                                }
                            }}
                        />
                    </div>
                ) : undefined}
                {archivedFilterProps ? (
                    <div styleName='expanded-filter-checkbox'>
                        <CheckboxButton
                            label='Archived'
                            checked={archivedFilterProps.isFilteringOnArchived}
                            onChange={() => {
                                onUpdateFilters({
                                    archived: archivedFilterProps.isFilteringOnArchived
                                        ? null
                                        : true,
                                });
                            }}
                        />
                    </div>
                ) : undefined}
                {ignoredFilterProps ? (
                    <div styleName='expanded-filter-checkbox'>
                        <CheckboxButton
                            label='Ignored'
                            checked={ignoredFilterProps.isFilteringOnIgnored}
                            onChange={() => {
                                onUpdateFilters({
                                    ignored: !ignoredFilterProps.isFilteringOnIgnored,
                                });
                            }}
                        />
                    </div>
                ) : undefined}
            </div>
            {searchFilterProps ? (
                <div styleName='search'>
                    <SearchBar
                        value={searchFilterProps.searchValue}
                        placeholder={`Search ${noun}s…`}
                        isClearable={true}
                        hasBorder={true}
                        autoFocus={false}
                        onChange={searchFilterProps.onUpdateSearch}
                        styleOptions={{height: '29px'}}
                        hiddenLabel='Search'
                    />
                </div>
            ) : undefined}
            <div styleName='text-items'>
                <div styleName='count-text'>{getCountText(count, noun)}</div>
                {isFiltering ? (
                    <Button size='small' variant='text-primary' onClick={onRemoveAllFilters}>
                        Remove filters
                    </Button>
                ) : undefined}
            </div>
        </React.Fragment>
    );
}

function getCountText(count: number, noun: string) {
    if (count === 1) {
        return `${count} ${noun}`;
    }

    return `${count} ${noun}s`;
}
