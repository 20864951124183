/* @flow */

import * as React from 'react';

type Props = {
    width?: number,
};

export function ManMarketingOnAComputer(props: Props) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={props.width}
            viewBox='0 0 151 150'
            fill='none'
        >
            <path
                fill='#fff'
                d='M33.996 86.22a2.917 2.917 0 0 0-.198-.461c-.473-.914-1.018-1.872-1.47-2.858-1.076-2.348-2.327-4.613-3.387-6.968-.388-.863-.856-1.803-1.736-2.144-.882-.34-1.862.042-2.73.419-5.183 2.254-10.16 4.945-15.27 7.355-.806.38-2.698.823-3.07 1.618-.247.53.13 1.442.294 1.939.449 1.353 1.204 2.56 1.78 3.855 1.037 2.326 2.158 4.613 3.27 6.902.87 1.788 2.24.7 3.549.085 2.005-.944 4.017-1.875 6.025-2.812l12.048-5.62c.852-.398 1.072-.744.895-1.31Z'
            />
            <path
                fill='#5D7183'
                d='M26.766 73.942c-.688-.106-1.454.188-2.187.507-3.147 1.368-6.273 2.925-9.296 4.431a306.452 306.452 0 0 1-5.966 2.92c-.227.107-.532.217-.854.334-.787.284-1.864.675-2.09 1.157-.171.368.084 1.105.237 1.545.025.071.049.138.069.2.276.834.684 1.634 1.077 2.407.235.462.478.94.694 1.423.948 2.13 1.986 4.263 2.99 6.326l.276.568c.21.434.454.692.744.787.519.172 1.18-.172 1.881-.535.189-.098.384-.2.576-.29a947.634 947.634 0 0 1 4.011-1.873l2.015-.938 12.048-5.621c.561-.262.917-.473.755-.993a2.663 2.663 0 0 0-.181-.418c-.146-.282-.296-.562-.454-.86-.347-.65-.706-1.321-1.021-2.008a102.5 102.5 0 0 0-1.62-3.323c-.596-1.187-1.212-2.414-1.768-3.648-.383-.852-.822-1.709-1.592-2.006a1.655 1.655 0 0 0-.344-.092Zm-14.248 23.16a1.374 1.374 0 0 1-.224-.055c-.43-.142-.774-.488-1.05-1.055l-.277-.568c-1.005-2.067-2.045-4.204-2.997-6.342-.21-.472-.45-.943-.681-1.398-.403-.791-.82-1.61-1.109-2.482l-.066-.192c-.202-.581-.479-1.378-.216-1.94.319-.683 1.418-1.08 2.388-1.432.31-.112.604-.218.808-.314 1.998-.942 4.01-1.945 5.956-2.915 3.03-1.509 6.161-3.07 9.321-4.444.958-.416 1.974-.791 2.93-.422.954.37 1.452 1.328 1.88 2.279.55 1.224 1.165 2.446 1.758 3.627.546 1.088 1.112 2.212 1.628 3.34.309.674.664 1.338 1.007 1.981.152.286.31.58.457.866.1.192.17.357.216.502.274.878-.336 1.302-1.036 1.629l-12.047 5.621-2.016.938c-1.315.612-2.674 1.245-4.009 1.873-.183.086-.373.184-.557.28-.689.357-1.398.726-2.064.623Z'
            />
            <path
                fill='#96E296'
                d='M16.547 91.464c-.262-.488-.51-.968-.745-1.478-.702-1.525-1.404-3.049-2.107-4.572-.104-.226-.222-.468-.441-.584-.233-.124-.517-.074-.771-.006-.597.158-1.253.34-1.765.697-.334.232-.43.345-.309.725.362 1.142.83 2.276 1.337 3.366.165.354 1.958 4.08 2.134 4.005.846-.355 1.744-.667 2.59-1.022.478-.201.357-.616.157-.983l-.08-.148Z'
            />
            <path
                fill='#5D7183'
                d='M13.01 85.026c-.139-.021-.306.013-.46.054-.53.14-1.19.316-1.682.659-.281.196-.277.209-.207.429.326 1.03.76 2.12 1.324 3.334.364.784 1.578 3.246 1.958 3.807.376-.154.763-.303 1.14-.446.426-.164.868-.333 1.288-.509.089-.037.143-.082.16-.133.023-.065.019-.2-.133-.48l-.08-.15a26.427 26.427 0 0 1-.753-1.492l-2.107-4.572c-.096-.209-.187-.388-.327-.462a.373.373 0 0 0-.12-.039Zm.82 8.859c-.193-.03-.342-.293-.562-.68a34.02 34.02 0 0 1-.692-1.308c-.498-.98-.987-2-1.067-2.172a28.438 28.438 0 0 1-1.349-3.396c-.178-.56.058-.777.41-1.022.568-.396 1.307-.592 1.847-.736.3-.08.648-.138.961.028.307.163.457.49.557.706l2.106 4.572c.205.443.433.894.738 1.464l.08.147c.127.234.282.582.168.908-.07.197-.223.346-.455.443-.427.18-.873.35-1.304.516a42.78 42.78 0 0 0-1.286.507.278.278 0 0 1-.153.023Z'
            />
            <path
                fill='#fff'
                d='M20.046 90.26c-.195-.334-1.54-3.131-2.037-4.188-.073-.157-.159-.323-.355-.385-.207-.066-.482.002-.732.082-.585.185-1.229.394-1.756.717-.343.211-.447.307-.374.576.217.81.535 1.602.895 2.357.117.246 1.407 2.82 1.582 2.745.845-.36 1.733-.693 2.578-1.053.476-.203.407-.499.258-.75l-.06-.101Z'
            />
            <path
                fill='#5D7183'
                d='M17.533 85.924c-.164-.025-.428.059-.533.092-.526.166-1.18.373-1.697.69-.168.103-.258.17-.27.2 0 0-.005.02.012.083.194.723.481 1.48.879 2.312.218.457 1.078 2.128 1.392 2.554.378-.16.767-.315 1.143-.465.425-.17.865-.346 1.285-.525.113-.048.19-.105.206-.152.008-.024.01-.085-.071-.221l-.06-.103c-.203-.347-1.554-3.16-2.047-4.208-.071-.15-.116-.221-.198-.247a.27.27 0 0 0-.04-.01Zm-.317 6.5c-.187-.029-.332-.26-.474-.486-.135-.215-.309-.52-.517-.904a47.177 47.177 0 0 1-.775-1.504 13.992 13.992 0 0 1-.912-2.403c-.13-.487.186-.681.491-.869.53-.325 1.135-.53 1.814-.744.285-.09.613-.17.891-.082.313.1.438.365.512.523.508 1.077 1.838 3.845 2.026 4.168l.06.1c.052.089.212.36.116.654-.066.2-.234.36-.5.472-.425.181-.867.358-1.295.53-.424.17-.863.345-1.282.523a.278.278 0 0 1-.154.022ZM23.387 81.671a.264.264 0 0 1-.077-.496 31.353 31.353 0 0 1 4.086-1.708.261.261 0 0 1 .332.165.264.264 0 0 1-.166.334 30.756 30.756 0 0 0-4.017 1.68c-.05.025-.106.033-.158.025ZM25.883 82.841a.263.263 0 0 1-.064-.5l1.663-.734a.262.262 0 0 1 .346.134.265.265 0 0 1-.136.347l-1.503.663-.003.001-.157.07a.264.264 0 0 1-.146.02ZM25.454 85.878a.262.262 0 0 1-.091-.486 35.527 35.527 0 0 1 4.778-2.352.261.261 0 0 1 .34.146.264.264 0 0 1-.146.342c-1.62.65-3.203 1.43-4.707 2.317a.26.26 0 0 1-.174.033Z'
            />
            <path
                fill='#5D7183'
                d='M22.985 89.229a6.518 6.518 0 0 1-.37-.654l-.988-2.052c-.049-.101-.107-.208-.262-.236-.165-.03-.396.038-.607.111-.493.17-1.037.362-1.493.622-.298.17-.39.242-.35.42.117.537.319 1.055.558 1.543.078.158.944 1.818 1.093 1.754.719-.309 1.472-.603 2.192-.913.406-.174.371-.37.268-.531a7.726 7.726 0 0 1-.041-.064Z'
            />
            <path
                fill='#5D7183'
                d='M19.173 87.389c.103.467.278.95.536 1.477.154.314.673 1.266.9 1.567.316-.134.64-.267.955-.396.361-.148.734-.301 1.092-.455a.5.5 0 0 0 .173-.104.283.283 0 0 0-.023-.042l-.042-.066a6.662 6.662 0 0 1-.385-.68l-.988-2.052c-.041-.086-.05-.088-.072-.091-.122-.024-.388.07-.475.1-.445.154-.999.344-1.45.602a1.87 1.87 0 0 0-.221.14Zm1.337 3.615c-.196-.03-.386-.304-.744-.929-.251-.437-.49-.898-.528-.976a7.085 7.085 0 0 1-.58-1.6c-.084-.388.202-.55.478-.708.456-.26.966-.445 1.538-.642.251-.087.513-.163.738-.12.294.052.4.273.452.38l.988 2.05c.092.192.202.386.355.628l.04.064c.106.163.135.324.088.476-.056.183-.21.327-.474.44-.361.155-.737.309-1.1.457-.36.148-.734.3-1.092.455a.284.284 0 0 1-.16.025Z'
            />
            <path
                fill='#fff'
                d='M96.995 84.968a30.026 30.026 0 0 0-.57-.57c-2.896-3.076-6.072-5.877-9.18-8.742-1.994-2.893-2.946-6.416-3.291-9.893-.557-5.609-.685-11.53-4.627-16.067-2.062-2.372-5.205-3.995-8.386-3.96-3.333.037-5.335 2.87-6.341 5.862-1.428 4.246-.34 8.696.393 12.96.548 3.188 1.993 7.515.207 10.528-1.067 1.8-9.56 8.406-11.833 10.44-1.469 1.314-2.947 2.639-4.137 4.21-1.19 1.57-2.085 3.421-2.221 5.386-.226 3.243 1.596 6.246 3.474 8.9 1.521 2.15 3.126 4.24 4.81 6.263 1.216 1.462 2.51 2.716 4.37 3.253 2.002.578 3.889.096 5.93.098l10.981.014 16.526.019c1.603.002 3.203.05 4.796.098 1.444.044 1.93-.412 1.833-1.925-.034-.52.004-1.648.386-2.04a5.14 5.14 0 0 0 2.167-.574c2.25-1.04 3.481-3.367 3.78-6.014.746-6.59-4.757-13.803-9.067-18.246Z'
            />
            <path
                fill='#5D7183'
                d='M74.505 81.207c-3.45 0-6.854-1.617-9.286-4.461a.311.311 0 0 1 .473-.405c2.564 2.998 6.257 4.563 9.877 4.19 3.625-.377 7.033-2.682 9.115-6.167a.31.31 0 0 1 .534.319c-2.182 3.653-5.765 6.07-9.584 6.466-.376.039-.753.058-1.129.058Z'
            />
            <path
                fill='#5D7183'
                d='M78.567 85.977a.39.39 0 0 1-.326-.162l-2.476-3.51-2.808 3.273a.311.311 0 0 1-.352.087c-.5-.199-1.014-.393-1.511-.58-3.17-1.197-6.447-2.435-8.245-5.739a.31.31 0 1 1 .547-.297c1.687 3.1 4.714 4.243 7.918 5.453.432.163.876.33 1.315.503l2.923-3.409a.312.312 0 0 1 .49.023l2.61 3.699c.43-.207 1.752-1.234 4.294-3.595 2.037-1.892 3.876-3.734 4.098-4.055a.31.31 0 1 1 .512.353c-.284.41-2.279 2.39-4.27 4.236-4.008 3.716-4.523 3.719-4.717 3.72h-.002Z'
            />
            <path
                fill='#96E296'
                d='M80.138 43.5c.9 1.028 1.311 2.497.978 3.83 2.18-.888 4.938.004 6.183 1.999 1.244 1.995.832 4.86-.926 6.423.926-.824 1.492 4.188 1.432 4.572-.262 1.689-1.033 3.446-2.832 3.992-1.364.414-2.9-.147-3.894-1.166-1.572-1.613-1.712-3.383-1.233-5.4-2.875.039-5.114-.431-5.781-3.538.313.81-7.644 2.505-7.287-.509-.61 5.147-7.19 3.963-7.46-.768-.142-2.47 1.958-5.739 4.766-4.978-.928-.251.305-3.681.563-4.147.784-1.41 2.102-2.484 3.712-2.798 2.121-.413 4.448.592 5.598 2.419 1.266-1.346 3.466-1.697 5.088-.813.416.226.784.528 1.093.882Z'
            />
            <path
                fill='#EEE'
                d='m85.524 111.295.001.003c-.006-.001-.007-.002-.001-.003Zm12.718.607c-1.041-.579-2.403-.61-3.595-.618-2.756-.017-5.9.003-7.686.012-2.13-5.737-4.963-14.88-6.287-19.124-.384-1.229-1.518-1.7-2.807-1.717-2.765-.038-29.052-.293-31.52-.17a1.98 1.98 0 0 0-1.826 2.453c.316 1.274.64 2.545.971 3.814 1.052 4.037 2.14 8.071 3.427 12.039.494 1.523.956 3.159 1.717 4.577.873 1.626 2.98 1.47 4.535 1.45 0 0 22.724.016 30.745.091l13.522.125c.47-1.093-.154-2.355-1.196-2.932Z'
            />
            <path
                fill='#5D7183'
                d='M99.437 115.145h-.002l-13.522-.126c-7.928-.074-30.515-.09-30.743-.09l-.311.005c-1.516.027-3.593.065-4.497-1.619-.657-1.223-1.09-2.59-1.51-3.912-.077-.241-.153-.48-.23-.716-1.226-3.782-2.258-7.555-3.43-12.057a347.563 347.563 0 0 1-.974-3.818 2.278 2.278 0 0 1 .384-1.914 2.288 2.288 0 0 1 1.73-.924c2.49-.125 29.13.137 31.539.169 1.616.021 2.717.71 3.1 1.935l.217.698c1.61 5.165 4.962 15.914 7.008 20.829a.311.311 0 1 1-.574.239c-2.058-4.941-5.415-15.708-7.028-20.883l-.217-.697c-.38-1.216-1.577-1.486-2.514-1.499-2.407-.032-29.023-.293-31.5-.17a1.668 1.668 0 0 0-1.54 2.067c.305 1.228.622 2.474.97 3.812 1.17 4.491 2.2 8.253 3.422 12.021.077.237.153.478.23.72.411 1.295.836 2.634 1.465 3.805.725 1.35 2.432 1.319 3.937 1.292l.32-.005c.231 0 22.821.016 30.752.09l13.3.123c.213-.764-.125-1.791-1.128-2.347-.939-.52-2.166-.571-3.446-.579-2.836-.017-4.876.014-5.856.029l-.48.007c-.182.001-.454.004-.477-.289a.313.313 0 0 1 .275-.334.302.302 0 0 1 .094.001c.087.002.309-.003.579-.007a262.99 262.99 0 0 1 5.868-.029c1.365.008 2.678.065 3.745.657 1.25.693 1.835 2.155 1.33 3.327a.312.312 0 0 1-.286.189Z'
            />
            <path
                fill='#fff'
                d='M62.12 101.776c.09 1.216.826 2.409 1.95 2.95.969.465 2.183.477 3.082-.11 2.077-1.354 1.297-4.589-.703-5.582-2.108-1.046-4.509.326-4.33 2.742Z'
            />
            <path
                fill='#5D7183'
                d='M51.155 110.951a6.71 6.71 0 0 1-3.608-1.035c-2.251-1.441-3.727-4.245-3.853-7.32-.064-1.571.145-3.17.621-4.748a.312.312 0 0 1 .596.18 13.78 13.78 0 0 0-.595 4.543c.117 2.875 1.484 5.488 3.566 6.821 2.31 1.478 5.54 1.172 7.2-.684a.312.312 0 0 1 .464.416c-.928 1.037-2.286 1.678-3.824 1.804-.19.015-.379.023-.567.023ZM91.032 110.266a.313.313 0 0 1-.294-.414c.358-1.025 1.514-1.496 2.436-1.5h.017c.823 0 1.597.267 2.346.526l.322.11c1.358.459 2.397.647 3.368.609 1.407-.053 3.14-.873 4.414-2.087 1.173-1.118 1.923-2.656 2.113-4.332.326-2.879-.549-6.156-2.599-9.739-1.933-3.378-4.537-6.354-6.382-8.255a31.845 31.845 0 0 0-.574-.574c-.666-.707-2.207-2.137-3.838-3.652-2.365-2.195-4.81-4.465-5.525-5.376-2.251-2.87-2.888-6.739-3.191-9.79-.058-.585-.112-1.183-.165-1.762-.453-4.986-.921-10.141-4.388-14.13-2.07-2.383-5.148-3.854-8.052-3.854h-.095c-1.555.017-4.41.774-6.05 5.65-1.238 3.683-.536 7.57.142 11.329.088.486.179.988.263 1.48.1.575.227 1.184.362 1.829.623 2.973 1.328 6.342-.194 8.91-.779 1.314-5.12 4.888-8.608 7.76-1.405 1.157-2.618 2.156-3.285 2.753a60.701 60.701 0 0 0-3.69 3.651.31.31 0 1 1-.46-.418 61.303 61.303 0 0 1 3.735-3.697c.677-.606 1.895-1.608 3.305-2.77 3.268-2.69 7.744-6.376 8.468-7.596 1.398-2.36.72-5.604.12-8.465a53.242 53.242 0 0 1-.366-1.852c-.084-.489-.174-.99-.262-1.475-.693-3.836-1.409-7.803-.12-11.637.572-1.7 1.39-3.136 2.369-4.155 1.204-1.256 2.639-1.901 4.264-1.92h.101c3.08 0 6.337 1.553 8.523 4.068 3.598 4.141 4.076 9.398 4.538 14.483.052.577.107 1.175.164 1.757.295 2.967.91 6.724 3.063 9.467.683.872 3.22 3.227 5.458 5.304 1.635 1.518 3.18 2.953 3.863 3.676.213.207.4.395.571.572 3.762 3.878 9.952 11.438 9.153 18.498-.206 1.82-1.023 3.494-2.302 4.713-1.046.996-2.935 2.186-4.82 2.259-1.347.052-2.626-.316-3.59-.642l-.327-.112c-.7-.241-1.423-.492-2.142-.492h-.014c-.71.003-1.593.343-1.85 1.083a.313.313 0 0 1-.295.209Z'
            />
            <path
                fill='#5D7183'
                d='M101.841 85.93c-3.1 0-5.896-1.395-8.763-4.305a.31.31 0 1 1 .444-.436c1.865 1.892 3.671 3.098 5.524 3.686 1.838.584 3.778.575 5.928-.03 2.072-.58 4.04-1.83 5.693-3.61 1.631-1.758 2.876-3.945 3.601-6.326 1.435-4.712.926-10.231-1.361-14.763a.31.31 0 1 1 .555-.28c2.359 4.67 2.882 10.363 1.402 15.225-.752 2.47-2.046 4.741-3.741 6.568-1.731 1.864-3.799 3.174-5.981 3.786-1.145.322-2.242.485-3.301.485Z'
            />
            <path
                fill='#5D7183'
                d='M113.955 92.66h-.21c-3.447-.039-7.092-1.009-10.538-2.807-3.025-1.577-5.8-3.746-7.817-6.108a.31.31 0 0 1 .473-.404c1.967 2.302 4.677 4.419 7.632 5.96 3.36 1.753 6.907 2.7 10.257 2.736 2.542.027 4.981-.461 7.246-1.454 2.506-1.097 4.82-2.822 6.875-5.125a.312.312 0 0 1 .465.414c-2.117 2.37-4.502 4.147-7.091 5.282a18.062 18.062 0 0 1-7.292 1.506ZM35.92 95.93c-.54 0-1.014-.037-1.409-.1-1.388-.226-3.212-.567-5.015-1.203-1.805-.636-3.385-1.49-4.83-2.609a.311.311 0 0 1 .38-.492c3.066 2.374 6.409 3.176 9.565 3.689 2.56.416 6.189-.537 8.65-1.667a.311.311 0 1 1 .26.567c-2.947 1.351-5.687 1.815-7.602 1.815Z'
            />
            <path
                fill='#5D7183'
                fillRule='evenodd'
                d='M16.12 65.265a7.61 7.61 0 0 1 1.975.027.317.317 0 1 0 .09-.626 8.237 8.237 0 0 0-2.146-.029c-.796.104-1.651.373-2.26.99-.534.154-1.285.503-1.983.997-.827.585-1.644 1.42-1.905 2.454a.316.316 0 1 0 .615.154c.207-.82.879-1.542 1.657-2.092.383-.27.776-.49 1.125-.65a1.281 1.281 0 0 0-.024.258c.004.355.158.665.38.894.426.441 1.192.648 1.78.208a1.04 1.04 0 0 0 .441-.803 1.299 1.299 0 0 0-.265-.8 1.785 1.785 0 0 0-.778-.604c.385-.2.834-.317 1.299-.378Zm-1.998.927.047-.01c.316-.057.697.132.916.435.106.145.151.29.147.406-.005.104-.049.216-.19.321-.263.198-.674.136-.942-.141a.674.674 0 0 1-.201-.462c-.002-.15.051-.338.223-.549ZM12.684 54.583c1.013.259 2.05.81 2.579 1.175a.316.316 0 1 0 .36-.52c-.577-.4-1.682-.987-2.782-1.269-.55-.14-1.123-.21-1.638-.126-.4.066-.771.228-1.06.523a3.554 3.554 0 0 0-2.041.183 4.315 4.315 0 0 0-1.543 1.019.316.316 0 0 0 .017.447c.129.118.33.11.448-.017.24-.258.708-.627 1.307-.859a2.976 2.976 0 0 1 1.426-.197c-.394.84-.19 1.613.257 2.144.24.285.55.5.872.62.318.12.674.157.992.043.31-.112.541-.337.659-.627.116-.287.114-.618.005-.942-.197-.592-.748-1.175-1.67-1.574.128-.067.273-.112.434-.138.394-.065.87-.015 1.378.114Zm-2.294.512c.962.354 1.414.876 1.55 1.285a.73.73 0 0 1 .008.505.464.464 0 0 1-.285.268c-.136.05-.331.045-.554-.039a1.512 1.512 0 0 1-.61-.436c-.317-.375-.47-.929-.11-1.583ZM22.524 50.863a.316.316 0 0 0-.083.44c.727 1.064 2.48 2.777 3.253 3.491a.316.316 0 1 0 .431-.464c-.78-.72-2.48-2.388-3.16-3.384a.318.318 0 0 0-.44-.083Zm-1.73 3.648a.316.316 0 0 0 .17.414l4.539 1.91a.316.316 0 1 0 .246-.583l-4.539-1.91a.317.317 0 0 0-.415.169Zm1.24 4.923c.94.096 1.967.23 2.626.323a.317.317 0 1 0 .09-.626c-.665-.095-1.701-.23-2.65-.326a27.94 27.94 0 0 0-1.31-.108c-.366-.02-.695-.024-.893.012a.317.317 0 1 0 .113.623c.114-.02.37-.023.746-.003.364.019.809.057 1.279.105Z'
                clipRule='evenodd'
            />
            <path
                fill='#96E297'
                d='M50.994 69.457c.358-.969 1.144-3.074 1.705-4.407a.32.32 0 0 0-.29-.445l-3.418-.06a.313.313 0 0 1-.237-.108c-2.439-2.812-12.673-9.926-17.866-13.387a.314.314 0 0 0-.473.166l-4.73 14.276c-.064.197.074.401.28.415l22.63 1.542a.317.317 0 0 1 .224.115l1.631 1.983c.156.19.46.14.544-.09Z'
            />
            <path
                fill='#5D7183'
                fillRule='evenodd'
                d='M31.064 50.79a.632.632 0 0 0-.95.33l-4.73 14.276a.633.633 0 0 0 .56.83l22.63 1.542 1.63 1.983c.31.377.917.28 1.087-.182.359-.97 1.142-3.068 1.7-4.394a.637.637 0 0 0-.577-.884l-3.418-.059h-.003c-.63-.726-1.746-1.713-3.13-2.83-1.389-1.12-3.064-2.385-4.822-3.668a306.383 306.383 0 0 0-9.977-6.944Zm-.352.526.003.002h.001v-.002h-.004ZM50.696 69.35l-1.632-1.984a.635.635 0 0 0-.447-.23l-22.63-1.541 4.729-14.276a305.709 305.709 0 0 1 9.95 6.926 128.35 128.35 0 0 1 4.798 3.65c1.383 1.115 2.46 2.072 3.05 2.752l.24-.207-.24.207a.63.63 0 0 0 .47.218l3.42.06v-.001l.001.002a.007.007 0 0 1 .002.002l-.001.002c-.564 1.341-1.352 3.452-1.71 4.42h.002-.001v.001m-.001 0Z'
                clipRule='evenodd'
            />
            <path
                fill='#5D7183'
                d='M25.535 46.394c-.043.523.418.987 1.029 1.037.61.05 1.14-.334 1.184-.856.042-.523-.418-.986-1.029-1.037-.611-.05-1.141.334-1.184.856ZM14.122 60.57c.023.523.538.927 1.15.9.613-.026 1.09-.472 1.068-.996-.023-.524-.538-.927-1.15-.9-.612.026-1.09.472-1.068.996ZM13.404 50.232c.023.524.538.927 1.15.9.613-.026 1.09-.472 1.068-.996-.023-.524-.538-.927-1.15-.9-.613.026-1.09.472-1.068.996Z'
            />
            <path
                fill='#96E297'
                d='M19.811 39.42c.023.523.538.926 1.15.9.613-.027 1.09-.473 1.068-.997-.023-.523-.538-.926-1.15-.9-.613.026-1.09.473-1.068.996ZM6.88 46.27c.022.525.537.928 1.15.901.612-.026 1.09-.472 1.067-.996-.023-.524-.538-.927-1.15-.9-.613.026-1.09.472-1.068.996ZM20.187 64.437c.023.524.538.927 1.15.9.613-.026 1.09-.472 1.068-.996-.023-.524-.538-.927-1.15-.9-.613.026-1.09.472-1.068.996Z'
            />
            <path
                fill='#5D7183'
                d='M13.505 73.796c.07.52.621.873 1.228.79.608-.083 1.042-.572.97-1.09-.07-.52-.62-.874-1.228-.791-.607.083-1.042.571-.97 1.09Z'
            />
            <path
                fill='#96E297'
                d='M5.109 62.68c.071.52.622.874 1.229.79.607-.082 1.042-.57.97-1.09-.071-.52-.621-.873-1.228-.79-.608.083-1.042.571-.971 1.09Z'
            />
            <path
                fill='#5D7183'
                fillRule='evenodd'
                d='M30.802 40.264a.316.316 0 0 0 .093.438c1.47.95 1.798 2.014 1.7 2.819-.05.414-.215.777-.42 1.044-.061.078-.123.146-.184.203a5.684 5.684 0 0 0-.034-.124c-.132-.456-.322-.893-.596-1.166a.941.941 0 0 0-.538-.282.945.945 0 0 0-.632.156.859.859 0 0 0-.408.753c.001.277.111.555.274.789.295.424.835.797 1.465.756.097.659.111 1.347.042 1.83a.317.317 0 0 0 .628.09c.082-.574.061-1.37-.058-2.117.201-.124.387-.3.544-.503.271-.353.482-.822.546-1.353.133-1.086-.347-2.369-1.984-3.426a.318.318 0 0 0-.438.093Zm.598 4.755c-.325-.012-.632-.213-.822-.486a.8.8 0 0 1-.16-.43c0-.102.035-.167.11-.215.104-.065.17-.07.21-.065.043.006.101.03.175.103.157.156.31.464.434.893.019.065.037.132.053.2ZM15.394 43.295a.316.316 0 0 0-.158.419c.268.592.505 1.05.86 1.384.286.269.63.442 1.078.568.09.286.262.56.539.79.471.395 1.21.637 2.26.655a.317.317 0 1 0 .011-.633c-.97-.017-1.545-.24-1.864-.506a1.188 1.188 0 0 1-.161-.162c.758.036 1.312-.31 1.574-.79.147-.27.2-.582.127-.87a.974.974 0 0 0-.6-.678c-.492-.195-.978-.017-1.321.289a1.951 1.951 0 0 0-.627 1.22 1.635 1.635 0 0 1-.58-.343c-.254-.24-.448-.589-.718-1.185a.317.317 0 0 0-.42-.158Zm2.341 1.852.005-.072c.029-.326.196-.642.42-.842.225-.2.463-.253.666-.172.125.05.192.137.219.243a.584.584 0 0 1-.07.414c-.15.276-.542.56-1.24.43ZM42.232 67.549a.317.317 0 0 0-.311.322c.008.46.095 1.16.498 1.81.41.662 1.13 1.242 2.337 1.483 1.259.25 2.152-.244 2.767-.894.503-.53.835-1.182 1.048-1.599l.107-.207a.317.317 0 0 0-.56-.299l-.124.24c-.215.42-.499.974-.932 1.43-.503.532-1.186.907-2.181.708-1.047-.209-1.61-.691-1.923-1.195-.32-.515-.396-1.086-.403-1.488a.317.317 0 0 0-.323-.311Z'
                clipRule='evenodd'
            />
            <path
                fill='#5D7183'
                fillRule='evenodd'
                d='M43.892 75.6c.398 1.909 1.56 3.986 3.968 5.543 4.785 3.092 9.081.584 11.296-1.058a.316.316 0 1 1 .378.508c-2.245 1.665-6.869 4.41-12.018 1.081-2.559-1.653-3.814-3.879-4.245-5.946-.426-2.048-.046-3.97.714-5.011l.513.373c-.627.857-1.007 2.582-.606 4.51Z'
                clipRule='evenodd'
            />
            <path
                fill='#fff'
                d='M111.423 59.668c-5.98-2.184-11.227-1.537-12.144-.626.943-1.222 1.302-4.238 1.518-5.654.313-2.144.412-3.82.851-5.455.464-1.728 2.713-5.719 7.166-6.015 4.453-.297 14.101 2.613 17.652 5.05 3.551 2.435 3.135 7.116.596 11.56-2.539 4.442-7.268 4.197-15.639 1.14Z'
            />
            <path
                fill='#5D7183'
                fillRule='evenodd'
                d='M117.882 43.744c3.403.992 6.66 2.317 8.389 3.504 1.671 1.146 2.412 2.814 2.434 4.75.022 1.95-.69 4.17-1.941 6.36-.61 1.068-1.343 1.839-2.215 2.358-.873.52-1.906.798-3.135.852-2.473.109-5.693-.694-9.874-2.221-3.03-1.107-5.876-1.498-8.088-1.495-1.106.001-2.06.101-2.802.262a6.857 6.857 0 0 0-.56.144c.235-.578.413-1.247.554-1.9.192-.885.325-1.79.425-2.472l.066-.448v-.002c.101-.69.179-1.325.253-1.921.158-1.288.294-2.395.59-3.495.217-.81.868-2.19 2.006-3.399 1.132-1.203 2.732-2.223 4.852-2.364 2.164-.144 5.641.495 9.046 1.487Zm.192-.655c-3.42-.997-6.994-1.665-9.283-1.513-2.333.156-4.085 1.28-5.306 2.578-1.216 1.292-1.922 2.772-2.168 3.69-.308 1.147-.454 2.328-.616 3.643a84.59 84.59 0 0 1-.242 1.85l-.069.46c-.1.683-.228 1.557-.414 2.418-.235 1.085-.55 2.074-.969 2.618l.513.45c.07-.07.206-.158.43-.251.219-.09.502-.177.845-.251.685-.149 1.589-.245 2.657-.246 2.136-.003 4.902.375 7.853 1.452 4.19 1.53 7.52 2.378 10.139 2.263 1.317-.058 2.466-.36 3.455-.948.99-.59 1.801-1.454 2.459-2.607 1.288-2.254 2.054-4.595 2.031-6.705-.024-2.125-.85-4.015-2.73-5.305-1.821-1.25-5.163-2.598-8.585-3.596Z'
                clipRule='evenodd'
            />
            <ellipse
                cx='1.585'
                cy='1.424'
                fill='#96E297'
                rx='1.585'
                ry='1.424'
                transform='matrix(-.96607 -.25827 -.25937 .96578 122.084 52.361)'
            />
            <ellipse
                cx='1.585'
                cy='1.424'
                fill='#96E297'
                rx='1.585'
                ry='1.424'
                transform='matrix(-.96607 -.25827 -.25937 .96578 116.877 50.97)'
            />
            <ellipse
                cx='1.585'
                cy='1.424'
                fill='#96E297'
                rx='1.585'
                ry='1.424'
                transform='matrix(-.96607 -.25827 -.25937 .96578 111.669 49.576)'
            />
            <path
                fill='#5E7182'
                d='M72.041 66.151c.302 1.458 1.483 2.11 1.878 2.11 1.562-.909.892-3.123.64-3.12-.055.001-.171.092-.303.212-.302.277-.742.35-1.097.145-.549-.316-1.246.034-1.118.653Z'
            />
            <ellipse
                cx='135.597'
                cy='76.889'
                fill='#fff'
                rx='12.535'
                ry='11.627'
                transform='rotate(17.46 135.597 76.889)'
            />
            <path
                fill='#607182'
                fillRule='evenodd'
                d='M140.958 73.234c-.201 1.041-.647 2.127-.824 2.49-.081.166-.053.287.016.393a.8.8 0 0 0 .298.257l1.813.486c.766.205 1.243.59 1.489 1.066.243.47.236.984.123 1.404-.204.762-1.086 2.903-1.762 4.294-.365.749-.807 1.137-1.27 1.322-.404.161-.8.155-1.082.15l-.086-.001c-.096 0-.245-.03-.405-.066a16.912 16.912 0 0 1-.637-.16 74.172 74.172 0 0 1-1.825-.525c-1.373-.41-2.942-.901-3.967-1.228l.182-.578c1.023.327 2.588.816 3.957 1.225.685.204 1.319.388 1.811.52.246.066.454.12.614.156a3.724 3.724 0 0 0 .26.05l.018.001h.007l.084.001c.292.004.575.008.864-.108.301-.12.64-.385.951-1.023.673-1.383 1.533-3.48 1.721-4.181.083-.311.079-.66-.077-.962-.154-.297-.478-.598-1.127-.772l-1.862-.499-.022-.01a1.384 1.384 0 0 1-.56-.478.978.978 0 0 1-.05-.997c.16-.33.585-1.363.772-2.335.094-.49.121-.928.048-1.246a.74.74 0 0 0-.161-.343.504.504 0 0 0-.303-.154l-.013-.002-.013-.004a.812.812 0 0 0-.413.002.373.373 0 0 0-.271.286c-.015.058-.036.16-.062.295-.114.57-.345 1.732-.852 2.74-.319.632-.76 1.242-1.38 1.637-.629.401-1.413.564-2.374.349l.134-.591c.826.185 1.451.04 1.937-.27.494-.316.872-.82 1.162-1.396.46-.914.662-1.917.783-2.516.034-.17.062-.306.087-.4a.977.977 0 0 1 .679-.714c.265-.08.536-.056.714-.01.251.045.458.157.615.33.157.171.251.386.303.612.102.445.055.986-.044 1.503Z'
                clipRule='evenodd'
            />
            <path
                fill='#96E297'
                fillRule='evenodd'
                d='M134.716 74.914a.305.305 0 0 1 .223.367l-2.074 7.737a.303.303 0 0 1-.376.206l-5.632-1.509c-.188-.05-.286-.252-.205-.42.506-1.045 1.335-2.847 1.556-3.671.221-.828.486-2.794.624-3.936.021-.18.203-.296.385-.247l5.499 1.473Zm-2.823 7.536a.608.608 0 0 0 .753-.412.607.607 0 0 0-.446-.734.608.608 0 0 0-.753.412.608.608 0 0 0 .446.734Z'
                clipRule='evenodd'
            />
            <path
                fill='#5A6E80'
                fillRule='evenodd'
                d='M132.239 87.477c6.433 2.023 13.094-1.188 14.939-7.056 1.846-5.869-1.779-12.313-8.212-14.336-6.434-2.023-13.094 1.187-14.94 7.056-1.845 5.868 1.779 12.313 8.213 14.336Zm-.175.555c6.7 2.107 13.716-1.222 15.67-7.436 1.954-6.214-1.894-12.96-8.594-15.066-6.7-2.107-13.715 1.222-15.669 7.436-1.954 6.214 1.893 12.96 8.593 15.066Z'
                clipRule='evenodd'
            />
        </svg>
    );
}
