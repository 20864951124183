/* @flow */

import * as React from 'react';
import {TimesIcon} from 'shells/icon';

type Props = {
    locationString: string,
    onClear: (SyntheticEvent<>) => void,
};

export class LocationFound extends React.Component<Props> {
    render() {
        const {locationString, onClear} = this.props;

        return (
            <div className='ui-found-result ui-found-result--location-search'>
                <div className='ui-found-result__location'>{locationString}</div>
                <button onClick={onClear} className='ui-btn--icon clear-result'>
                    <TimesIcon />
                </button>
            </div>
        );
    }
}
