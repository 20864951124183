/* @flow */

import * as React from 'react';
import * as ramda from 'ramda';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import {Avatar} from '../avatar';
import {Checkbox} from '../checkbox';
import './selectable-list-row.css';

export type Model = {
    id: string,
    +type: string,
    name: string,
    subtitle?: string,
    thirdLine?: string,
    initials?: string,
    avatarUrl?: string,
    isEnabled?: boolean,
    numSelected?: number,

    // Tacked on for disabled styles and tooltips
    tooltipText?: string,
    isDisabled?: boolean,
};

type Props = {
    item: Model,
    hasCheckboxes?: boolean,
    isBorderless?: boolean,
    isDisabled?: boolean,
    isChecked: boolean,
    onSelect: (item: Model, isCurrentlySelected: boolean) => void,
    children?: React.Node,
    icon?: React.Element<*>,
    iconRight?: React.Element<*>,
    tooltipText?: string,
};

export class SelectableListRow extends React.Component<Props> {
    componentDidMount() {
        if (this.props.tooltipText) {
            ReactTooltip.rebuild();
        }
    }

    render() {
        const {item, isChecked, hasCheckboxes, isBorderless, icon, iconRight, tooltipText} =
            this.props;

        // Users and teams use the isEnabled property, but we want a different
        // display style for unusable items.
        const isDisabled =
            this.props.isDisabled || (!ramda.isNil(item.isEnabled) && !item.isEnabled);
        const disabledStyle = this.props.isDisabled
            ? 'name-disabled--ghost'
            : 'name-disabled--line-through';
        const classes = classnames('item-container', {
            // We don't want a background if we're using checkboxes
            'item-container-selected': isChecked && !hasCheckboxes,
            'item-container-borderless': isBorderless,
            'item-container-disabled': this.props.isDisabled,
        });

        return (
            <div key={item.id} styleName='selectable-list-row' data-tip={tooltipText}>
                <div styleName={classes} onClick={this.handleSelect}>
                    {hasCheckboxes ? (
                        <div styleName='checkbox-container'>
                            <Checkbox
                                checked={isChecked}
                                onClick={() => {}}
                                disabled={this.props.isDisabled}
                            />
                        </div>
                    ) : undefined}
                    {item.type === 'teams' ? <div styleName={'icon-teams'} /> : null}
                    {item.type === 'users' ||
                    item.type === 'leadStatus' ||
                    item.type === 'contacts' ||
                    item.type === 'accounts' ? (
                        <div styleName='icon'>
                            <Avatar
                                size={20}
                                avatarUrl={item.avatarUrl}
                                initials={item.initials}
                                type={item.type}
                            />
                        </div>
                    ) : null}
                    {icon ? <div styleName='icon'>{icon}</div> : undefined}
                    {getDisplay({
                        name: item.name,
                        subtitle: item.subtitle,
                        thirdLine: item.thirdLine,
                        isDisabled,
                        disabledStyle,
                    })}
                    {item.numSelected && item.numSelected > 0 ? (
                        <div styleName='num-selected'>{item.numSelected}</div>
                    ) : undefined}
                    {iconRight ? <div styleName='icon-right'>{iconRight}</div> : undefined}
                </div>
                {this.props.children}
            </div>
        );
    }

    handleSelect = (e: SyntheticEvent<>) => {
        e.stopPropagation();
        if (!this.props.isDisabled) {
            this.props.onSelect(this.props.item, this.props.isChecked);
        }
    };
}

const getDisplay = (input: {
    name: string,
    isDisabled: boolean,
    disabledStyle: string,
    subtitle?: string,
    thirdLine?: React.Node,
}) => {
    const {name, subtitle, thirdLine, isDisabled, disabledStyle} = input;

    return (
        <div className='flex flex-dir-col' styleName={isDisabled ? disabledStyle : 'name'}>
            <div styleName='name'>{name}</div>
            <div styleName='subtitle'>{subtitle}</div>
            {thirdLine}
        </div>
    );
};
