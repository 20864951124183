import * as React from 'react';
import PropTypes from 'prop-types';
import {withFormsy, propTypes as formsyPropTypes} from 'formsy-react';
import {CountrySelect} from 'shells/select';

/*
 * Helper component that acts as a translate layer between our FormsyComponent
 * styles and our standard CountrySelect shell.
 */
export class FormCountrySelectComponent extends React.Component {
    // ISO 3166-1 country codes taken from http://opencountrycodes.appspot.com/
    // includes a lot of defunct countries (e.g. Soviet Union)

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        statesOnly: PropTypes.bool,
        ...formsyPropTypes,
    };

    render() {
        return (
            <CountrySelect
                value={this.props.getValue()}
                onChange={this.handleChange}
                statesOnly={this.props.statesOnly}
            />
        );
    }

    // @TODO ccain create COUNTRY type instead of a liberal `id` string
    handleChange = (value) => {
        this.props.setValue(value.id);
        this.props.onChange(value.id);
    };
}

export const FormCountrySelect = withFormsy(FormCountrySelectComponent);
