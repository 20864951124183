/* @flow */

import type {Model} from '../selectable-list';

/*
 * This function helps ensure that we are providing model objects to lists that
 * expect that shape.  Realistically we should be ble to just pass objects
 * straight through, but I can't seem to get flow to play nicely with that.
 */
export function convertToModels(items?: any[]): Model[] | void {
    if (!items) return undefined;

    return items
        .map((item) => {
            if (typeof item === 'string') {
                return {
                    id: item,
                    type: 'enum',
                    name: item,
                };
            } else if (typeof item === 'object') {
                return {
                    id: item.id,
                    type: item.type,
                    name: item.name,
                    initials: item.initials,
                    avatarUrl: item.avatarUrl,
                    isEnabled: item.isEnabled,
                    numSelected: item.numSelected,
                };
            } else {
                return undefined;
            }
        })
        .filter(Boolean);
}

/**
 * This is a terrible hack, but we need to add these URLs to the objects.
 * Ideally we would add this from the backend schema, but that would require
 * changing our entire enum schmea to return objects, rather than strings.
 *
 * @param {Model} items The array of items to add. Should come from the method
 * above.
 */
export function addLeadStatusIconUrls(items?: Model[]): Model[] | void {
    if (!items) return undefined;

    const origin =
        typeof window !== undefined && window.location.origin
            ? window.location.origin
            : 'https://app.nutshell.com';

    return items.map((item) => {
        switch (item.name) {
            case 'Open':
                return {
                    ...item,
                    avatarUrl: `${origin}/avatar/leads/v1/0/1/1/0`,
                    type: 'leadStatus',
                };
            case 'Won':
                return {
                    ...item,
                    avatarUrl: `${origin}/avatar/leads/v1/10?asPng=true`,
                    type: 'leadStatus',
                };
            case 'Lost':
                return {
                    ...item,
                    avatarUrl: `${origin}/avatar/leads/v1/11?asPng=true`,
                    type: 'leadStatus',
                };
            case 'Cancelled':
                return {
                    ...item,
                    avatarUrl: `${origin}/avatar/leads/v1/12?asPng=true`,
                    type: 'leadStatus',
                };
            case 'Pending':
                return {
                    ...item,
                    avatarUrl: `${origin}/avatar/leads/v1/1?asPng=true`,
                    type: 'leadStatus',
                };
        }

        return item;
    });
}
