/* @flow */

import * as React from 'react';
import _ from 'underscore';

import {portal} from '../portal/portal';
import {ModalComponent} from './modal-component';

const MARGIN_COLLAPSE_THRESHOLD = 700;

type Props = {|
    /** This is called before animating out, but not if `isOpen` is set to `false` */
    onBeforeClose: () => any,
    onClose: () => any,
    onMinimize?: () => void,
    closeButton?: boolean,
    className?: string,
    children?: any,
    headerText?: string,
    headerSubtext?: string,
    headerBackButton?: boolean,
    onHeaderBackButtonPress?: () => any,
    trapFocus?: boolean,
    isOpen?: boolean,
    preventBodyScroll?: boolean,
    canClickOutsideToClose?: boolean,
    canEscapeToClose?: boolean,
    isLeftAligned?: boolean,
    /* Used somewhere */
    isWide?: boolean,
    /* Used for onboarding modals, which have solid-color left halves */
    noPadding?: boolean,
    maxHeight?: number,
    minHeight?: number,
    maxWidth?: number,
    minWidth?: number,
    getCloseButtonRef?: (?HTMLButtonElement) => void,
    actionButton?: React.Node,
    /** If true, the body will size vertically according to the content */
    fitHeight?: boolean,
|};
type State = {
    isClosing: boolean,
    isClosed: boolean,
};

// Generally you'll want to import `Modal`, which is wrapped in a portal.
// This is exported for testing/storybook
export class ModalWrapperComponent extends React.Component<Props, State> {
    bodyRef: ?HTMLElement;
    delay: ?Function;

    static displayName = 'Modal';

    static defaultProps = {
        closeButton: true,
        isOpen: false,
        trapFocus: true,
        canEscapeToClose: true,
        canClickOutsideToClose: true,
        preventBodyScroll: true,

        onBeforeClose: () => {},
        onClose: () => {},
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            isClosing: false,
            isClosed: !props.isOpen,
        };
    }

    componentDidMount() {
        // Handle auto-mounted/auto-opened instances.
        if (this.props.isOpen) {
            this.handleOpen();
            this.resize();
        }
    }

    componentDidUpdate() {
        if (this.bodyRef) this.resize();
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        // If modal isn't already closed or closing, then close it
        if (
            this.props.isOpen &&
            !nextProps.isOpen &&
            !this.state.isClosing &&
            !this.state.isClosed
        ) {
            this.performClose();
        }

        if (!this.props.isOpen && nextProps.isOpen) {
            if (typeof this.delay === 'number') {
                clearTimeout(this.delay);
            }

            this.handleOpen();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('resize', this.resize);

        const body = document.querySelector('body');

        if (body && this.props.preventBodyScroll) {
            body.style.overflow = '';
        }
    }

    render() {
        if (this.state.isClosed) return null;

        return (
            <ModalComponent
                getBodyRef={(c) => {
                    this.bodyRef = c;
                }}
                className={this.props.className}
                isLeftAligned={this.props.isLeftAligned}
                noPadding={this.props.noPadding}
                isWide={this.props.isWide}
                onOverlayClick={this.handleOverlayClick}
                onClose={this.handleClose}
                onMinimize={this.props.onMinimize ? this.handleMinimize : undefined}
                headerBackButton={this.props.headerBackButton}
                onHeaderBackButtonPress={this.props.onHeaderBackButtonPress}
                headerText={this.props.headerText}
                headerSubtext={this.props.headerSubtext}
                shouldTrapFocus={this.props.trapFocus}
                closeButton={this.props.closeButton}
                isClosing={this.state.isClosing}
                maxHeight={this.props.maxHeight}
                minHeight={this.props.minHeight}
                maxWidth={this.props.maxWidth}
                minWidth={this.props.minWidth}
                getCloseButtonRef={this.props.getCloseButtonRef}
                actionButton={this.props.actionButton}
                fitHeight={this.props.fitHeight}
            >
                {this.props.children}
            </ModalComponent>
        );
    }

    handleKeyDown: KeyboardEventListener = (e) => {
        // Not using Nut.keys here since it isn't in scope within shells
        // keyCode 27 = Esc
        if (e.keyCode === 27 && !e.defaultPrevented && this.props.canEscapeToClose)
            this.handleClose();
    };

    handleOverlayClick = () => {
        if (this.props.canClickOutsideToClose) {
            this.handleClose();
        }
    };

    handleOpen = () => {
        document.addEventListener('keydown', this.handleKeyDown);
        const body = document.querySelector('body');
        if (body && this.props.preventBodyScroll) {
            body.style.overflow = 'hidden';
        }
        window.addEventListener('resize', this.resize);
        this.setState({isClosed: false});
    };

    handleClose = () => {
        const beforeClose = this.props.onBeforeClose();
        if (_.isBoolean(beforeClose)) {
            if (beforeClose) {
                this.performClose();
            }
        } else if (beforeClose && beforeClose.then) {
            beforeClose.then((proceedWithClose) => {
                if (proceedWithClose) this.performClose();
            });
        } else {
            this.performClose();
        }
    };

    handleMinimize = () => {
        this.performClose(this.props.onMinimize);
    };

    performClose = (closeCallback: () => void = this.props.onClose) => {
        const body = document.querySelector('body');
        if (body && this.props.preventBodyScroll) {
            body.style.overflow = '';
        }
        document.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('resize', this.resize);
        this.setState({isClosing: true}, () => this.closeAfterAnimationDelay(closeCallback));
    };

    closeAfterAnimationDelay = (closeCallback: () => void) => {
        this.delay = _.delay(() => this.close(closeCallback), 250);
    };

    close = (closeCallback: () => void) => {
        this.delay = undefined;

        this.setState({
            isClosing: false,
            isClosed: true,
        });
        closeCallback();
    };

    resize = () => {
        const bodyNode = this.bodyRef;

        if (!bodyNode) return;

        // Reset back to css margins.
        bodyNode.style.marginTop = '';
        bodyNode.style.marginBottom = '';

        const collapseMargins = window.innerHeight < MARGIN_COLLAPSE_THRESHOLD;

        // Little bit of breathing room when margins are collapsed.
        const verticalMarginsCollapsed = 64;

        if (collapseMargins) {
            bodyNode.style.marginTop = `${verticalMarginsCollapsed / 2}px`;
            bodyNode.style.marginBottom = `${verticalMarginsCollapsed / 2}px`;
        } else {
            bodyNode.style.marginTop = '';
            bodyNode.style.marginBottom = '';
        }
    };
}

/**
 * This is the generic Nutshell modal.
 */
export const Modal = portal(ModalWrapperComponent);
