/* @flow */

import * as React from 'react';
import _ from 'underscore';
import classnames from 'classnames';

import {EntityPill} from 'shells/entity-pill';
import {SearchBar} from 'shells/search-bar';

import {ListViewScrollable} from 'shells/list-view';
import getClassesMixin from '../../mixins/get-classes';

type Item = {
    id: string,
    name: string,
    type: string,
    isEnabled?: boolean,
};

type Props = {
    animateIn?: boolean,
    type: string,
    collection: Item[],
    label: string,
    value: any[],
    emptyView: React.Element<any>,
    isSingleSelect?: boolean,
    onChange: Function,
};

type State = {
    searchValue: string,
};

export class SearchableList extends React.Component<Props, State> {
    focusMe: ?HTMLElement;
    getClasses = getClassesMixin;

    constructor() {
        super();

        this.state = {
            searchValue: '',
        };
    }

    componentDidMount() {
        const timeoutDuration = this.props.animateIn ? 250 : 0;

        setTimeout(() => {
            if (this.focusMe) {
                this.focusMe.focus();
            }
        }, timeoutDuration);
    }

    render() {
        const {collection} = this.props;
        const filteredList = collection
            .filter((item) => this.filterList(item))
            // Our list view expects our items to be wrapped in an object
            .map((item) => {
                return {
                    rowItem: item,
                };
            });
        const selectedItems = this.props.value && this.props.value.length;

        const classes = this.getClasses('ui-searchable-list', {
            'ui-searchable-list--count-1': this.props.value.length === 1,
            'ui-searchable-list--count-2': this.props.value.length === 2,
            'ui-searchable-list--count-3': this.props.value.length >= 3,
        });

        return (
            <div className={classes}>
                <div className='found'>
                    {selectedItems
                        ? this.renderSelectedItems(collection)
                        : this.renderEmptySection()}
                </div>
                <div className='searchable'>
                    <SearchBar
                        placeholder='Search…'
                        hasBorder={true}
                        searchBarRef={(c) => {
                            this.focusMe = c;
                        }}
                        value={this.state.searchValue}
                        onChange={this.handleSearchInputChange}
                    />
                    <div style={{marginBottom: 8}} />
                    <ListViewScrollable
                        collection={filteredList}
                        onSelect={this.handleToggleItem}
                        renderRow={this.renderRow}
                        specialStates={[
                            {
                                shouldRender: () => filteredList.length === 0,
                                component: this.props.emptyView,
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }

    renderRow = (item: Item, isSelected: boolean) => {
        const {id, name, type, isEnabled} = item;
        const isDisabledUser = type === 'users' && !isEnabled;
        const classes = classnames(
            `ui-searchable-list-item ui-searchable-list-item--type-${type}`,
            {'ui-searchable-list-item--disabled': isDisabledUser},
            {'ui-searchable-list-item--selected': isSelected}
        );

        return (
            <li className={classes} key={id} onClick={() => this.handleToggleItem(item)}>
                <div className='ui-searchable-list-item__icon' />
                <span className='ui-searchable-list-item__name'>{name}</span>
            </li>
        );
    };

    renderSelectedItems(collection: Item[]): React.Element<*>[][] {
        return this.props.value.map((id) => {
            return collection
                .filter((item) => item.id === id)
                .map((selected) => {
                    return (
                        <EntityPill
                            key={id}
                            name={selected.name}
                            type={selected.type}
                            isLinkable={false}
                            isSmall={true}
                            onRemove={() => this.handleToggleItem(selected)}
                        />
                    );
                });
        });
    }

    renderEmptySection() {
        const typeMap = {
            accountTypes: 'company type',
            activityTypes: 'activity types',
            users: 'users or teams',
            territories: 'territories',
            industries: 'industries',
            tags: 'tags',
            products: 'products',
            sources: 'sources',
            markets: 'markets',
            milestones: 'milestone',
            enum: this.props.label.toLowerCase(),
        }[this.props.type];

        return (
            <div>
                <span>
                    <i>No {typeMap ? typeMap : this.props.type} selected.</i>
                </span>
            </div>
        );
    }

    handleSearchInputChange = (val: string) => {
        this.setState({searchValue: val});
    };

    filterList(item: Item) {
        if (this.isItemAlreadySelected(item.id)) return false;
        if (this.isItemFilteredBySearch(item)) return false;

        return true;
    }

    isItemAlreadySelected(id: string) {
        return _.contains(this.props.value, id);
    }

    isItemFilteredBySearch(item: Item) {
        if (!this.state.searchValue || !item.name) return false;

        return item.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) === -1;
    }

    handleToggleItem = (item: Item) => {
        const newValue = this.isItemAlreadySelected(item.id)
            ? _.without(this.props.value, item.id)
            : this.addNewItem(item.id);

        this.props.onChange(newValue);
    };

    addNewItem = (id: string): any[] => {
        return this.props.isSingleSelect ? [id] : this.props.value.concat(id);
    };
}
