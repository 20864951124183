/* @flow */

import * as React from 'react';

import {getArticleFromChannel, getChannelNameFromChannel} from 'nutshell-core/channels/helpers';

import type {ChannelType} from '../../channels/types';
import {SectionAddItemButton} from '../section-add-item-button';
import {SourcesSelect} from './sources-select';

import {SourceTag} from './source-tag';
import type {Source} from './types';

import './sources-section-content.css';

type Props = {
    channel: ?ChannelType,
    sources: Source[],
    sourceOptions: Source[],
    onAddSource: (sourceId: string) => any,
    onRemoveSource: (sourceId: string) => any,
    canAccessSetup: boolean,
};

export function SourcesSectionContent(props: Props) {
    const {channel, sources, sourceOptions, onAddSource, onRemoveSource, canAccessSetup} = props;
    const [isAddingItem, setIsAddingItem] = React.useState<boolean>(false);

    return (
        <div
            styleName={channel ? 'sources-section-content' : 'sources-section-content--no-channel'}
        >
            {sources.length ? (
                <div styleName={channel ? 'sources-container' : 'sources-container--no-channel'}>
                    {sources.map((source) => (
                        <SourceTag
                            key={source.id}
                            source={source}
                            onRemoveSource={() => {
                                onRemoveSource(source.id);
                            }}
                        />
                    ))}
                </div>
            ) : undefined}
            {channel ? (
                <SectionAddItemButton
                    sectionEntity='sources'
                    isAddingItem={isAddingItem}
                    setIsAddingItem={setIsAddingItem}
                    addItemComponent={
                        <SourcesSelect
                            channel={channel}
                            sourceOptions={sourceOptions}
                            canAccessSetup={canAccessSetup}
                            onBlur={() => setIsAddingItem(false)}
                            onSelectSource={(id: string) => {
                                onAddSource(id);
                                // Fake delay while saving
                                setTimeout(() => {
                                    setIsAddingItem(false);
                                }, 1000);
                            }}
                        />
                    }
                    parentItemText={
                        <span>
                            {getArticleFromChannel(channel)}{' '}
                            <strong>{getChannelNameFromChannel(channel)}</strong>{' '}
                        </span>
                    }
                />
            ) : undefined}
        </div>
    );
}
