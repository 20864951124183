/* @flow */

import * as React from 'react';

import {ViewTabLink} from './view-tab-link';

import './view-tabs-links.css';

export type Props = {|
    tabs: {|
        Icon?: React.ComponentType<*> | string,
        label: ?string,
        isActive: boolean,
        size?: number,
        id: string,
        href?: string,
    |}[],
    currentView: string,
|};

export function ViewTabsLinks(props: Props) {
    const {tabs, currentView} = props;

    return (
        <div styleName='view-tabs' className='flex'>
            {tabs.map((tab) => {
                return <ViewTabLink key={tab.id} {...tab} currentView={currentView} />;
            })}
        </div>
    );
}
