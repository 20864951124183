/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import {getInstanceCurrencyPrefix} from '../../utils/currency';

import NavIconSalesPound from './nav-icon-sales-pound.svg';
import NavIconSalesDollar from './nav-icon-sales-dollar.svg';
import NavIconSalesEuro from './nav-icon-sales-euro.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    const defaultInstanceCurrencySymbol = getInstanceCurrencyPrefix();

    switch (defaultInstanceCurrencySymbol) {
        case '£':
            return <NavIconSalesPound {...validProps} />;
        case '€':
            return <NavIconSalesEuro {...validProps} />;
        case '$':
        default:
            return <NavIconSalesDollar {...validProps} />;
    }
}
SvgComponent.iconName = 'navIconSales';

export const NavIconSalesIcon = svgIconWrapper(SvgComponent);
