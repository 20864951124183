/* @flow */

import * as React from 'react';

type Props = {
    width?: number,
};

export function ABTest(props: Props) {
    return (
        <svg
            width={props.width}
            viewBox='0 0 176 130'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M4.21247 117.262C1.57472 116.851 0.605986 116.356 1.87428 115.763C10.6979 111.639 50.27 111.582 69.4637 111.499C93.608 111.394 118.731 111.172 140.469 112.066C149.567 112.442 170.564 111.994 173.707 114.094C183.43 120.594 117.161 118.98 106.513 119.08C88.7624 119.243 19.2764 119.609 4.21247 117.262Z'
                fill='#F7F7F7'
            />
            <path
                d='M163.238 115.79H9.85196C5.65413 115.79 2.23828 112.375 2.23828 108.18V107.723C2.23828 107.476 2.43864 107.275 2.68569 107.275H170.414C170.661 107.275 170.862 107.476 170.862 107.723V108.17C170.862 112.372 167.442 115.79 163.238 115.79Z'
                fill='#F7F7F7'
            />
            <path
                d='M156.298 110.523V25.9044C156.298 24.3723 155.091 23.1201 153.619 23.1201H19.5956C18.123 23.1201 16.917 24.3742 16.917 25.9044V110.525H156.298V110.523Z'
                fill='#F7F7F7'
            />
            <path
                d='M158.589 107.024V25.6754C158.589 24.2035 157.383 22.998 155.91 22.998H21.889C20.4165 22.998 19.2104 24.2035 19.2104 25.6754V107.024H158.589Z'
                fill='white'
            />
            <path
                d='M159.064 106.316V28.1815C159.064 27.0733 159.274 25.5625 158.77 24.5378C157.914 22.7957 156.128 22.8676 154.487 22.8676H147.005C146.484 22.8676 146.272 23.5151 146.912 23.5151H151.293C152.802 23.5151 154.354 23.4081 155.86 23.5151C158.305 23.6901 158.116 26.0272 158.116 27.7557V102.242C158.116 103.533 157.957 104.939 158.116 106.222C158.124 106.279 158.116 106.343 158.116 106.399C158.116 106.868 159.066 106.71 159.066 106.316H159.064Z'
                fill='#0C0F33'
            />
            <path
                d='M31.458 22.8677C28.3145 22.8677 25.1573 22.7743 22.0177 22.8677C20.265 22.9201 18.8061 24.149 18.7361 25.9495C18.7225 26.3053 18.7361 26.663 18.7361 27.0189V101.696C18.7361 103.037 18.5707 104.5 18.7361 105.833C18.7439 105.892 18.7361 105.954 18.7361 106.012C18.7361 106.481 19.6854 106.323 19.6854 105.929V28.5996C19.6854 27.678 19.6445 26.7486 19.6854 25.827C19.7437 24.4912 20.6385 23.5346 21.9944 23.5132C22.6733 23.5035 23.3521 23.5132 24.031 23.5132H31.3646C31.8859 23.5132 32.098 22.8657 31.458 22.8657V22.8677Z'
                fill='#0C0F33'
            />
            <path
                d='M165.588 115.79H12.2016C8.00374 115.79 4.58789 112.375 4.58789 108.18V107.723C4.58789 107.476 4.78825 107.275 5.0353 107.275H172.764C173.011 107.275 173.211 107.476 173.211 107.723V108.17C173.211 112.372 169.792 115.79 165.588 115.79Z'
                fill='white'
            />
            <path
                d='M165.775 115.543H13.4057C12.47 115.543 11.5635 115.556 10.6415 115.323C7.79753 114.602 5.61691 112.14 5.15589 109.261C5.07419 108.747 5.01972 108.191 5.05863 107.668C5.06446 107.594 4.81353 107.583 4.86605 107.524C4.88356 107.503 4.99054 107.534 5.01778 107.524C5.54299 107.345 6.44753 107.524 6.99609 107.524H170.686C171.155 107.524 172.029 107.349 172.474 107.524C173.188 107.806 172.579 109.91 172.408 110.466C171.507 113.398 168.856 115.488 165.775 115.543C165.448 115.549 164.771 116.048 165.399 116.037C167.905 115.992 170.321 114.944 171.922 112.988C172.754 111.971 173.328 110.738 173.559 109.443C173.684 108.751 174.044 107.207 173.054 107.036C172.659 106.968 172.211 107.029 171.813 107.029H7.34818C6.69263 107.029 6.0293 106.999 5.37376 107.029C4.93413 107.048 4.3953 107.122 4.18132 107.561C4.01792 107.898 4.11324 108.5 4.13853 108.847C4.18716 109.546 4.33694 110.243 4.57621 110.902C5.01972 112.119 5.78031 113.227 6.75488 114.081C9.25452 116.268 12.2638 116.035 15.3003 116.035H165.399C165.728 116.035 166.405 115.541 165.775 115.541V115.543Z'
                fill='#0C0F33'
            />
            <path
                d='M69.2364 108.427C70.2538 110.49 72.3702 110.204 74.2785 110.204H102.498C104.624 110.204 107.299 110.622 108.497 108.388C108.707 107.997 107.77 108.095 107.622 108.367C107.056 109.423 106.182 109.669 105.126 109.669H75.8424C73.9672 109.669 71.1739 110.198 70.176 108.174C70.0164 107.851 69.1002 108.151 69.2364 108.425V108.427Z'
                fill='#0C0F33'
            />
            <path d='M152.109 29.6553H25.6919V103.362H152.109V29.6553Z' fill='#EBE8E8' />
            <path
                d='M145.311 103.405H32.52V12.815C32.52 11.9225 33.2436 11.1992 34.1365 11.1992H143.331C144.424 11.1992 145.309 12.0858 145.309 13.1766V103.405H145.311Z'
                fill='white'
            />
            <path
                d='M34.1169 11.1992H143.535C144.498 11.1992 145.282 11.9809 145.282 12.9453V19.2528H32.4907V12.8247C32.4907 11.9284 33.2202 11.1992 34.1169 11.1992Z'
                fill='white'
            />
            <path
                d='M40.4732 16.4339C41.1399 15.6475 41.0426 14.4696 40.2558 13.8031C39.469 13.1366 38.2906 13.2339 37.6238 14.0203C36.957 14.8068 37.0543 15.9846 37.8412 16.6511C38.628 17.3176 39.8064 17.2204 40.4732 16.4339Z'
                fill='#FF2E47'
            />
            <path
                d='M45.1145 17.0345C46.1135 16.7781 46.7154 15.7609 46.4589 14.7624C46.2024 13.7639 45.1847 13.1622 44.1857 13.4186C43.1868 13.675 42.5849 14.6923 42.8414 15.6908C43.0979 16.6893 44.1156 17.2909 45.1145 17.0345Z'
                fill='#FBC942'
            />
            <path
                d='M50.6795 17.0409C51.6834 16.805 52.306 15.8002 52.07 14.7967C51.8339 13.7931 50.8287 13.1709 49.8247 13.4068C48.8207 13.6427 48.1982 14.6475 48.4342 15.651C48.6703 16.6546 49.6755 17.2768 50.6795 17.0409Z'
                fill='#33C252'
            />
            <path
                d='M41.3863 15.1009C41.2327 12.2563 36.708 12.579 36.71 15.3147C36.7119 18.1904 41.2424 17.8813 41.3883 15.1767C41.4116 14.7509 40.4623 14.92 40.4448 15.2758C40.402 16.075 39.8574 16.9597 38.9295 16.7711C38.1728 16.6155 37.6612 15.8864 37.6631 15.1378C37.6651 14.2881 38.3479 13.4618 39.2621 13.7068C40.0169 13.909 40.4059 14.6206 40.4448 15.3517C40.4643 15.6997 41.4058 15.4975 41.3844 15.1009H41.3863Z'
                fill='#0C0F33'
            />
            <path
                d='M46.9879 15.1001C46.8381 12.3041 42.2999 12.728 42.3135 15.3509C42.3291 18.178 46.8479 17.688 46.9879 15.1001C46.9957 14.9387 46.0639 15.0573 46.0484 15.3509C45.9978 16.3075 45.1808 17.2642 44.1498 16.6731C43.5935 16.3542 43.2511 15.7378 43.2531 15.1001C43.255 14.2018 44.0487 13.2782 45.0038 13.7118C45.6671 14.0132 46.0095 14.6432 46.0484 15.3509C46.0581 15.5414 47.0035 15.3781 46.9879 15.1001Z'
                fill='#0C0F33'
            />
            <path
                d='M52.5964 15.1917C52.4408 12.2927 47.9434 12.4171 47.9084 15.2617C47.8734 18.1977 52.4447 17.9994 52.5964 15.1917C52.6237 14.7018 51.6666 14.834 51.6452 15.2617C51.6024 16.0395 51.0947 16.8289 50.2116 16.7395C49.449 16.6617 48.8713 15.9384 48.8616 15.1917C48.8518 14.4451 49.379 13.7413 50.1513 13.716C51.0013 13.6868 51.6024 14.4762 51.6452 15.2617C51.6725 15.7556 52.6198 15.6117 52.5964 15.1917Z'
                fill='#0C0F33'
            />
            <path d='M88.957 19.0234H34.7256V103.603H88.957V19.0234Z' fill='#3D3B8A' />
            <path
                d='M68.1095 73.6126L66.7051 69.9222H54.7477L53.3432 73.6924C52.7946 75.1623 52.3258 76.1559 51.9387 76.6692C51.5516 77.1844 50.9155 77.4411 50.0324 77.4411C49.2835 77.4411 48.6221 77.1669 48.0463 76.6186C47.4705 76.0703 47.1846 75.4481 47.1846 74.754C47.1846 74.3534 47.2507 73.9393 47.3849 73.5115C47.5192 73.0838 47.739 72.4888 48.0463 71.7266L55.5686 52.6369C55.7825 52.0886 56.0393 51.4314 56.3408 50.6614C56.6423 49.8934 56.9633 49.2557 57.3037 48.7462C57.6441 48.2388 58.0935 47.8265 58.6479 47.5135C59.2023 47.2005 59.8889 47.043 60.704 47.043C61.5191 47.043 62.2252 47.2005 62.7796 47.5135C63.334 47.8285 63.7833 48.2329 64.1237 48.7268C64.4642 49.2207 64.7521 49.7534 64.9855 50.3212C65.2189 50.8889 65.5165 51.6472 65.8783 52.598L73.5621 71.5672C74.1631 73.0118 74.4647 74.0598 74.4647 74.7151C74.4647 75.3703 74.1806 76.0217 73.6126 76.5895C73.0446 77.1572 72.358 77.4411 71.5565 77.4411C71.0877 77.4411 70.687 77.3575 70.3524 77.1903C70.0178 77.023 69.7377 76.7956 69.5101 76.5078C69.2825 76.22 69.0394 75.7787 68.7787 75.1837C68.518 74.5887 68.2943 74.0637 68.1076 73.6087L68.1095 73.6126ZM56.3136 65.4522H65.1003L60.667 53.3213L56.3136 65.4522Z'
                fill='white'
            />
            <path d='M145.309 19.0234H90.7236V103.603H145.309V19.0234Z' fill='#0C0F33' />
            <path
                d='M118.33 76.9417H109.343C108.045 76.9417 107.119 76.65 106.565 76.0687C106.011 75.4873 105.732 74.5618 105.732 73.2921V51.1945C105.732 49.8976 106.016 48.9662 106.584 48.3966C107.152 47.8288 108.073 47.5449 109.343 47.5449H118.873C120.277 47.5449 121.495 47.6324 122.524 47.8055C123.553 47.9785 124.477 48.3129 125.292 48.8088C125.986 49.2229 126.603 49.7479 127.138 50.3837C127.673 51.0195 128.081 51.7214 128.361 52.4894C128.642 53.2575 128.784 54.0702 128.784 54.9257C128.784 57.8675 127.313 60.0199 124.37 61.3829C128.235 62.6137 130.167 65.0053 130.167 68.5615C130.167 70.2064 129.744 71.6861 128.902 73.0024C128.06 74.3187 126.922 75.2929 125.492 75.9209C124.595 76.2962 123.566 76.5586 122.403 76.7122C121.24 76.8659 119.882 76.9436 118.33 76.9436V76.9417ZM111.689 52.0169V59.6174H117.145C118.629 59.6174 119.777 59.4775 120.586 59.1955C121.396 58.9155 122.014 58.3808 122.442 57.5914C122.777 57.0295 122.944 56.4015 122.944 55.7073C122.944 54.2238 122.415 53.238 121.359 52.75C120.302 52.2619 118.69 52.0189 116.523 52.0189H111.689V52.0169ZM117.888 63.8873H111.689V72.4697H118.089C122.113 72.4697 124.127 71.0192 124.127 68.1182C124.127 66.6346 123.605 65.5575 122.563 64.8905C121.52 64.2217 119.962 63.8873 117.888 63.8873Z'
                fill='white'
            />
            <path
                d='M32.9961 102.288V13.5419C32.9961 12.663 32.9417 11.6597 34.1711 11.5625C34.7974 11.5119 35.451 11.5625 36.0793 11.5625H137.209C139.265 11.5625 141.44 11.3583 143.488 11.5625C144.686 11.6811 144.832 12.6125 144.832 13.5457V101.765C144.832 102.277 145.787 102.154 145.787 101.705V21.8812C145.787 18.9919 145.809 16.1006 145.787 13.2094C145.777 11.8717 145.034 10.8703 143.593 10.8353C142.957 10.8198 142.317 10.8353 141.679 10.8353H39.8842C37.9954 10.8353 36.0774 10.74 34.1905 10.8353C32.6635 10.9111 32.043 12.0661 32.043 13.398V102.349C32.043 102.86 32.9981 102.737 32.9981 102.288H32.9961Z'
                fill='#0C0F33'
            />
            <path
                d='M143.961 18.9918H47.7397C43.2909 18.9918 38.811 18.8323 34.3642 18.9918C34.3039 18.9937 34.2436 18.9918 34.1833 18.9918C33.837 18.9918 33.1834 19.5128 33.8156 19.5128H130.035C134.484 19.5128 138.964 19.6723 143.411 19.5128C143.471 19.5109 143.531 19.5128 143.591 19.5128C143.938 19.5128 144.591 18.9918 143.959 18.9918H143.961Z'
                fill='#0C0F33'
            />
            <path
                d='M31.6888 29.3848H25.8726C25.7208 29.3848 25.2209 29.4742 25.2209 29.7134V100.389C25.2209 101.285 25.0381 102.405 25.2209 103.288C25.2287 103.329 25.2209 103.377 25.2209 103.42C25.2209 103.578 25.3649 103.634 25.5108 103.634H136.329C141.451 103.634 146.598 103.788 151.718 103.634C151.788 103.632 151.856 103.634 151.926 103.634C152.078 103.634 152.578 103.545 152.578 103.305V32.6299C152.578 31.7335 152.761 30.6136 152.578 29.7309C152.57 29.69 152.578 29.6414 152.578 29.5986C152.578 29.4411 152.434 29.3848 152.288 29.3848H146.943C146.583 29.3848 145.949 29.9292 146.581 29.9292H151.926C151.829 29.8572 151.734 29.7853 151.636 29.7153V102.3C151.636 102.563 151.541 103.041 151.636 103.288C151.65 103.321 151.636 103.383 151.636 103.42C151.854 103.311 152.07 103.2 152.288 103.092H27.7867C27.2751 103.092 26.5806 102.967 26.0807 103.092C26.0185 103.107 25.9368 103.092 25.8726 103.092C25.9698 103.163 26.0652 103.235 26.1624 103.305V30.7186C26.1624 30.4561 26.2577 29.9778 26.1624 29.7309C26.1488 29.6978 26.1624 29.6356 26.1624 29.5986C25.9445 29.7075 25.7286 29.8183 25.5108 29.9272H31.327C31.6869 29.9272 32.3211 29.3828 31.6888 29.3828V29.3848Z'
                fill='#0C0F33'
            />
            <path
                d='M88.3087 20.8021V100.525C88.3087 100.822 88.2095 101.328 88.3087 101.612C88.3224 101.649 88.3087 101.709 88.3087 101.75C88.3087 102.001 89.2483 101.837 89.2483 101.499V21.7743C89.2483 21.4768 89.3475 20.9713 89.2483 20.6874C89.2347 20.6505 89.2483 20.5902 89.2483 20.5494C89.2483 20.2985 88.3087 20.4619 88.3087 20.8002V20.8021Z'
                fill='#0C0F33'
            />
        </svg>
    );
}
