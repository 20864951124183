import PropTypes from 'prop-types';
import * as React from 'react';
import Formsy from 'formsy-react';

import {Popover} from 'shells/popover';
import {FormRadioGroup} from '../../base/form-components/form-radio-group';
import {FormSearchableList} from '../../base/form-components/form-searchable-list';
import {CheckmarkSubmitButton} from '../../base/form-components/checkmark-submit-button';
import {RelationshipEmptyViewDeprecated} from './relationship-empty-view-deprecated';
import {RadioButtonDeprecated} from './radio-button-deprecated';

export class EnumFormDeprecated extends React.Component {
    static propTypes = {
        value: PropTypes.any,
        name: PropTypes.string,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
        onDirty: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        animateIn: PropTypes.bool,
        isAndable: PropTypes.bool.isRequired,
        isSingleSelect: PropTypes.bool,
        nullOptionText: PropTypes.string,
    };

    static defaultProps = {
        value: {anyAll: 'any', data: []},
    };

    constructor(props) {
        super(props);
        this.state = {canSubmit: true};
    }

    render() {
        const {options, title, type, name, value, animateIn, isAndable, isSingleSelect} =
            this.props;

        const normalizedValue = value.data.map((item) => item.data);

        const searchableEnumOptions = (Array.isArray(options) ? options : Object.keys(options))
            .map((opt) => {
                return opt && opt.id && opt.name
                    ? {
                          id: opt.id,
                          type: 'enum',
                          name: opt.name,
                      }
                    : {
                          id: opt,
                          type: 'enum',
                          name: opt,
                      };
            })
            .concat(this.getNullOptions());

        return (
            <Formsy
                className='ui-form ui-form--relationship'
                onChange={(data, changed) => this.props.onDirty(changed)}
                onValidSubmit={(data) => this.serializeAndSubmitData(data)}
                onValid={() => this.setState({canSubmit: true})}
                onInvalid={() => this.setState({canSubmit: false})}
            >
                {isAndable ? (
                    <div className='ui-form--relationship__form-controls'>
                        <FormRadioGroup
                            className='ui-radio-group--inline'
                            name={`${name}.anyAll`}
                            value={value.anyAll || 'any'}
                        >
                            <RadioButtonDeprecated
                                className='ui-form--relationship__form-controls__control'
                                type='standard'
                                key='any'
                                index={0}
                                value='any'
                            >
                                Any
                            </RadioButtonDeprecated>
                            <RadioButtonDeprecated
                                className='ui-form--relationship__form-controls__control'
                                type='standard'
                                key='all'
                                index={1}
                                value='all'
                            >
                                All
                            </RadioButtonDeprecated>
                        </FormRadioGroup>
                    </div>
                ) : undefined}
                <FormSearchableList
                    name={`${name}.data`}
                    emptyView={<RelationshipEmptyViewDeprecated />}
                    animateIn={animateIn}
                    label={title}
                    type={type}
                    isSingleSelect={isSingleSelect}
                    validations='minLength:1'
                    value={normalizedValue}
                    collection={searchableEnumOptions}
                />
                <CheckmarkSubmitButton
                    className='ui-popover__submit-button'
                    getButtonRef={(c) => {
                        this.submitButton = c;
                    }}
                    disabled={!this.state.canSubmit}
                    onMouseOver={() => {
                        this.setState({submitHover: true});
                    }}
                    onMouseOut={() => {
                        this.setState({submitHover: false});
                    }}
                />
                {this.state.submitHover && !this.state.canSubmit ? (
                    <Popover anchor={this.submitButton} bodyColor='error' isDialog={true}>
                        <span>Must select one or more option!</span>
                    </Popover>
                ) : undefined}
            </Formsy>
        );
    }

    serializeAndSubmitData(data) {
        const fieldName = this.props.name; // Owner

        const dataToSubmit = {
            [fieldName]: {
                anyAll: data[fieldName].anyAll,
                data: data[fieldName].data.map((value) =>
                    // I'm not sure why, but including the `isNot` value breaks
                    // the filter for badge types
                    this.props.type === 'badge' ? {data: value} : {isNot: false, data: value}
                ),
            },
        };

        this.props.onSubmit(dataToSubmit);
    }

    getNullOptions = () => {
        return this.props.nullOptionText
            ? [{id: '-', type: 'enum', name: this.props.nullOptionText}]
            : [];
    };
}
