import * as React from 'react';
import PropTypes from 'prop-types';
import {withFormsy, propTypes as formsyPropTypes} from 'formsy-react';
import getClasses from '../../mixins/get-classes';

class FormRadioButtonComponent extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        ...formsyPropTypes,
    };

    static defaultProps = {
        onChange: () => {},
    };

    getClasses = getClasses;

    render() {
        const {name, index} = this.props;
        const id = `${name}-${index}`;

        return (
            <div>
                <input
                    id={id}
                    name={name}
                    type='radio'
                    className={this.getClasses('ui-input ui-radio-btn')}
                    checked={this.props.getValue()}
                    onChange={(e) => {
                        this.props.onChange(name, e.target.checked);
                        this.props.setValue(e.target.checked);
                    }}
                />
                <label htmlFor={id}>{this.props.children}</label>
            </div>
        );
    }
}

export const FormRadioButton = withFormsy(FormRadioButtonComponent);
