import * as React from 'react';
import PropTypes from 'prop-types';
import {withFormsy, propTypes as formsyPropTypes} from 'formsy-react';
import _ from 'underscore';
import getClassesMixin from '../../mixins/get-classes';
import {EntitySelectedItem} from '../../core/entity-selected-item';
import {EntitySearch} from '../../core/search/entity-search';

class EntitySearchableListComponent extends React.Component {
    getClasses = getClassesMixin;

    static propTypes = {
        types: PropTypes.array,
        ...formsyPropTypes,
    };

    static defaultProps = {
        value: [],
        validationError: '',
    };

    render() {
        const {types} = this.props;
        const classes = this.getClasses('ui-entity-searchable-list');

        return (
            <div className={classes}>
                <div className='found'>{this.renderSelectedItems(this.props.getValue())}</div>
                <EntitySearch
                    types={types}
                    focusOnMount={true}
                    placeholder={this.renderPlaceholderText(types)}
                    onSelect={this.handleSelect}
                    hierarchyTypeFilter={this.props.hierarchyTypeFilter}
                />
            </div>
        );
    }

    renderPlaceholderText(types) {
        if (types.length === 1) {
            const entityText = {
                leads: 'leads',
                accounts: 'companies',
                contacts: 'people',
            }[types[0]];

            return `Search ${entityText}…`;
        }

        return 'Search participants…';
    }

    renderSelectedItems(selectedIds) {
        return selectedIds.map((id) => {
            return <EntitySelectedItem key={id} id={id} onRemove={() => this.handleRemove(id)} />;
        });
    }

    isItemAlreadySelected(id) {
        return _.contains(this.props.getValue(), id);
    }

    handleSelect = (searchResult) => {
        const id = searchResult.getEntity().id;

        if (this.isItemAlreadySelected(id)) return;

        const newValue = this.props.getValue().concat(id);
        this.props.setValue(newValue);
    };

    handleRemove(id) {
        const newValue = _.without(this.props.getValue(), id);

        this.props.setValue(newValue);
    }
}

export const EntitySearchableList = withFormsy(EntitySearchableListComponent);
