/* @flow */

// Flow DOM type declarations – such as KeyboardEvent, HTMLElement and etc – can be
// found in the source[1].
//
// We should try our best to use the provided DOM type declarations and
// avoid the smell of creating your own before looking one up in the docs or source.
//
// [1]: https://github.com/facebook/flow/blob/master/lib/dom.js

import * as React from 'react';
import classnames from 'classnames';
import _ from 'underscore';
import {FocusTrapZone} from '@fluentui/react/lib/FocusTrapZone';
import {ESC} from '../utils/keys';

import {portal} from '../portal';

import styles from './popover.css';

export const NUB_OFFSET: number = 10;

type LocationValidator = {
    top: boolean,
    right: boolean,
    bottom: boolean,
    left: boolean,
    bottomLeft: boolean,
    bottomRight: boolean,
    verticalCenter: boolean,
    horizontalCenter: boolean,
};

// This is just a flow helper, to convince it that an object literal is actually
// a ClientRect.
function coerseToClientRect(rect: any): ClientRect {
    return rect;
}

function getRectCenterPositon(rect: ClientRect): {x: number, y: number} {
    return {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
    };
}

function getLocationValidator(
    popupRect: ClientRect,
    anchorRect: ClientRect,
    offset: number
): LocationValidator {
    const anchorRectCenter = getRectCenterPositon(anchorRect);
    const popupRectHeight = popupRect.height + offset;
    const popupRectWidth = popupRect.width + offset;

    const horizontalCenterRight = popupRectWidth / 2 + anchorRectCenter.x <= window.innerWidth;
    const horizontalCenterLeft = popupRectWidth / 2 <= anchorRectCenter.x;
    const verticalCenterRight = popupRectHeight / 2 + anchorRectCenter.y <= window.innerHeight;
    const verticalCenterLeft = popupRectHeight / 2 <= anchorRectCenter.y;

    const locations = {
        top: popupRectHeight <= anchorRect.top,
        right: popupRectWidth + anchorRect.right <= window.innerWidth,
        bottomRight: popupRectWidth + anchorRect.left <= window.innerWidth,
        bottom: anchorRect.bottom + popupRectHeight <= window.innerHeight,
        bottomLeft: popupRectWidth <= anchorRect.right,
        left: popupRectWidth <= anchorRect.left,
        verticalCenter: verticalCenterRight && verticalCenterLeft,
        horizontalCenter: horizontalCenterRight && horizontalCenterLeft,
    };

    return locations;
}

export const LOCATIONS = [
    {
        name: 'top',
        valid: (validator: LocationValidator): boolean =>
            validator.top && validator.horizontalCenter,
    },
    {
        name: 'top-left',
        valid: (validator: LocationValidator): boolean => validator.top && validator.left,
    },
    {
        name: 'left',
        valid: (validator: LocationValidator): boolean =>
            validator.left && validator.verticalCenter,
    },
    {
        name: 'bottom-left',
        valid: (validator: LocationValidator): boolean => validator.bottom && validator.bottomLeft,
    },
    {
        name: 'bottom',
        valid: (validator: LocationValidator): boolean =>
            validator.bottom && validator.horizontalCenter,
    },
    {
        name: 'bottom-right',
        valid: (validator: LocationValidator): boolean => validator.bottom && validator.bottomRight,
    },
    {
        name: 'right',
        valid: (validator: LocationValidator): boolean =>
            validator.right && validator.verticalCenter,
    },
    {
        name: 'top-right',
        valid: (validator: LocationValidator): boolean => validator.top && validator.right,
    },
    {
        name: 'custom',
        valid: (): boolean => true,
    },
];

type Location = {
    name: LocationEnum,
    valid: (validator: LocationValidator) => boolean,
};

/**
 * getLocation - Validate a given location. This will auto-correct the location
 * based on any collisions with the viewport.
 *
 * @param  {Object} popupRect: ClientRect  The popover ClientRect.
 * @param  {Object} anchorRect: ClientRect The anchor ClientRect that the popover will use be positioned by.
 * @param  {string} location: LocationEnum A given location to attempt positioning the popover.
 * @param  {number} offset                 The nub offset position.
 * @return {Object}                        A filtered and valiated from the LOCATIONS Array.
 */
export function getLocation(
    popupRect: ClientRect,
    anchorRect: ClientRect,
    location: LocationEnum,
    offset: number
): Location {
    const locationValidation = getLocationValidator(popupRect, anchorRect, offset);
    const validLocations = LOCATIONS.filter((loc: Location): boolean =>
        loc.valid(locationValidation)
    );

    const givenLocation = _.findWhere(validLocations, {name: location});

    return givenLocation || _.first(validLocations);
}

/**
 * calculatePopoverPosition - Calculate popover position.
 *
 * @param  {Object} popupRect: ClientRect  The popover ClientRect.
 * @param  {Object} anchorRect: ClientRect The anchor ClientRect that the popover will use be positioned by.
 * @param  {String} location: LocationEnum The validated location returned from a getLocation() call.
 * @param  {Number} offset: number         The nub offset position.
 * @return {Object}                        Style properties in this shape: {top: Number, left: Number}.
 */
export function calculatePopoverPosition(
    popupRect: ClientRect,
    anchorRect: ClientRect,
    location: LocationEnum,
    offset: number
): {left: number, top: number} {
    const anchorRectCenter = getRectCenterPositon(anchorRect);
    let popoverX: number;
    let popoverY: number;

    switch (location) {
        case 'top':
            popoverX = anchorRectCenter.x - popupRect.width / 2;
            popoverY = anchorRect.top - offset - popupRect.height;
            break;
        case 'top-left':
            popoverX = anchorRectCenter.x - popupRect.width + anchorRect.width / 2 + offset;
            popoverY = anchorRect.top - offset - popupRect.height;
            break;
        case 'left':
            popoverX = anchorRect.left - popupRect.width - offset;
            popoverY = anchorRectCenter.y - popupRect.height / 2;
            break;
        case 'bottom-left':
            popoverX = anchorRectCenter.x - popupRect.width + anchorRect.width / 2 + offset;
            popoverY = anchorRect.bottom + offset;
            break;
        case 'bottom':
            popoverX = anchorRectCenter.x - popupRect.width / 2;
            popoverY = anchorRect.bottom + offset;
            break;
        case 'bottom-right':
            popoverX = anchorRectCenter.x - anchorRect.width / 2 - offset;
            popoverY = anchorRect.bottom + offset;
            break;
        case 'right':
            popoverX = anchorRect.right + offset;
            popoverY = anchorRectCenter.y - popupRect.height / 2;
            break;
        case 'top-right':
            popoverX = anchorRectCenter.x - anchorRect.width / 2 - offset;
            popoverY = anchorRect.top - offset - popupRect.height;
            break;
        case 'custom':
            popoverX = anchorRect.left;
            popoverY = anchorRect.bottom + offset;
            break;
        default:
            popoverX = 0;
            popoverY = 0;
    }

    return {
        left: popoverX,
        top: popoverY,
    };
}

export type LocationEnum =
    | 'top'
    | 'top-left'
    | 'left'
    | 'bottom-left'
    | 'bottom'
    | 'bottom-right'
    | 'right'
    | 'top-right'
    | 'custom';

export type Props = {|
    /** This should nearly always be provided.
    If it is null, we'll show the popover in the center of the screen.
    It's only nullable so we can avoid checks every time, because refs are always nullable. */
    anchor: ?HTMLElement,
    // This should only be used in situations where the anchor ref may become stale during the
    // lifecycle of the popover. This is a rare case and 'anchor' should be prioritized.
    anchorElementId?: string,
    anchorMaskOverlay?: boolean,
    children?: React.Node,
    className?: string,
    /** Class name to use instead of `window` to determine location */
    container?: string,
    location: LocationEnum,
    customStyles?: any,
    preventBodyScroll?: boolean,
    /** This will affect the body and nub color */
    bodyColor?: 'light' | 'dark' | 'error',
    /** Special case override, for date pickers which have dark header */
    isNubDark?: boolean,
    /** Hide the nub/arrow of the popover */
    hideNub?: boolean,
    isDialog?: boolean,
    noDefaultStyling?: boolean,
    disableOverlayClickToBlur: boolean,
    disableFocusTrapZone?: boolean,
    offsetPosition: number,
    onBlur?: () => void,
    overlayStyles?: Object,
    zIndex?: number,
|};

type State = {|
    location: string,
    style: any,
    maskOverlayStyles: any,
|};

type DefaultProps = {|
    location: LocationEnum,
    disableOverlayClickToBlur: boolean,
    offsetPosition: number,
    bodyColor: 'light',
|};

export class PopoverComponent extends React.Component<Props, State> {
    static displayName = 'Popover';
    props: Props;
    state: State;
    bodyRef: ?HTMLElement;
    overlayRef: ?HTMLElement;
    offsetPosition: number;

    static defaultProps: DefaultProps = {
        location: 'top',
        disableOverlayClickToBlur: false,
        offsetPosition: 0,
        bodyColor: 'light',
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            location: props.location,
            style: {},
            maskOverlayStyles: {},
        };

        this.offsetPosition = props.noDefaultStyling
            ? props.offsetPosition
            : props.offsetPosition + NUB_OFFSET;
    }

    componentDidMount() {
        this.calculatePosition();
        const container = this.getAnchorContainer();
        container.addEventListener('scroll', this.calculatePosition, false);
        container.addEventListener('resize', this.calculatePosition);
        document.addEventListener('keyup', this.handleKeyUp);

        if (this.props.preventBodyScroll) {
            this.startKillingMousewheel();
        }
    }

    componentWillUnmount() {
        const container = this.getAnchorContainer();
        container.removeEventListener('scroll', this.calculatePosition);
        container.removeEventListener('resize', this.calculatePosition);

        document.removeEventListener('keyup', this.handleKeyUp);

        if (this.props.preventBodyScroll) {
            this.stopKillingMousewheel();
        }
    }

    componentDidUpdate() {
        this.calculatePosition();
    }

    render() {
        const classNames = classnames(
            this.props.className,
            styles.body,
            styles[`location-${this.state.location}`],
            {[styles['body--dialog']]: this.props.isDialog},
            {[styles['body--dark']]: this.props.bodyColor === 'dark'},
            {[styles['body--error']]: this.props.bodyColor === 'error'},
            {[styles['body--nub-dark']]: this.props.isNubDark},
            {[styles['body--hide-nub']]: this.props.hideNub},
            {[styles['no-default-styling']]: this.props.noDefaultStyling}
        );

        return (
            <div className={styles.container} style={{zIndex: this.props.zIndex}}>
                {this.props.disableOverlayClickToBlur || !this.props.onBlur ? undefined : (
                    <div
                        ref={(node) => {
                            this.overlayRef = node;
                        }}
                        onClick={this.props.onBlur}
                        className={styles.overlay}
                        style={this.props.overlayStyles}
                    />
                )}
                {this.props.anchorMaskOverlay ? this.renderAnchorMaskOverlay() : undefined}
                <div
                    className={classNames}
                    style={this.state.style}
                    ref={(node) => {
                        this.bodyRef = node;
                    }}
                >
                    {this.props.disableFocusTrapZone ? (
                        this.props.children
                    ) : (
                        <FocusTrapZone
                            isClickableOutsideFocusTrap={true}
                            style={{height: '100%', width: '100%'}}
                        >
                            {this.props.children}
                        </FocusTrapZone>
                    )}
                </div>
            </div>
        );
    }

    renderAnchorMaskOverlay = () => {
        return (
            <div className={styles['anchor-mask-overlay']} style={this.state.maskOverlayStyles} />
        );
    };

    startKillingMousewheel = () => {
        window.addEventListener('wheel', this.preventBodyScroll, {passive: false});
    };

    stopKillingMousewheel = () => {
        window.removeEventListener('wheel', this.preventBodyScroll, {passive: false});
    };

    preventBodyScroll = (e: SyntheticWheelEvent<*>) => {
        // $FlowIgnore this is an EventTarget, but works as an HTMLElement
        const closestScrollParent = this.getClosestScrollParent(e.target);
        if (closestScrollParent && closestScrollParent !== document.body) {
            if (closestScrollParent.scrollTop === 0 && e.deltaY <= 0) {
                e.preventDefault();
            } else if (
                closestScrollParent.scrollTop + closestScrollParent.clientHeight + e.deltaY >=
                closestScrollParent.scrollHeight
            ) {
                e.preventDefault();
            }

            return;
        }

        e.preventDefault();
    };

    getClosestScrollParent = (node: ?HTMLElement) => {
        if (node && node.scrollHeight > node.clientHeight) {
            return node;
        } else if (node) {
            // node.parentNode is a Node, not an HTMLElement,
            // but we don't actually care, it all works out
            // $FlowIgnore
            return this.getClosestScrollParent(node.parentNode);
        }

        return null;
    };

    calculatePosition = () => {
        window.requestAnimationFrame(() => {
            if (!this.bodyRef) return;
            const popupRect: ClientRect = this.bodyRef.getBoundingClientRect();
            const fallbackWindowRect = coerseToClientRect({
                width: 0,
                height: 0,
                top: window.innerHeight / 2,
                left: window.innerWidth / 2,
                right: window.innerWidth / 2,
                bottom: window.innerHeight / 2,
            });

            let anchor = null;
            if (this.props.anchor) {
                anchor = this.props.anchor;
            } else if (this.props.anchorElementId) {
                anchor = document.getElementById(this.props.anchorElementId);
            }

            const anchorRect = anchor ? anchor.getBoundingClientRect() : fallbackWindowRect;
            const location: LocationEnum = getLocation(
                popupRect,
                anchorRect,
                this.props.location,
                this.offsetPosition
            ).name;
            const style = {
                ...calculatePopoverPosition(popupRect, anchorRect, location, this.offsetPosition),
                ...this.props.customStyles,
            };
            if (_.isEqual(this.state.style, style)) return;

            if (this.props.anchorMaskOverlay) {
                const maskOverlayStyles = {
                    width: anchorRect.width,
                    height: anchorRect.height,
                    top: anchorRect.top,
                    left: anchorRect.left,
                };
                this.setState({location, style, maskOverlayStyles});

                return;
            }
            this.setState({location, style});
        });
    };

    getAnchorContainer = (): HTMLElement => {
        if (!this.props.container) return window;

        const elements = document.getElementsByClassName(this.props.container); // prettier-ignore

        // $FlowIgnore It seems the flow-typed def is not robust enough here
        return _.first(elements) || window;
    };

    handleKeyUp = (e: KeyboardEvent) => {
        if (e.keyCode === ESC && this.props.onBlur) {
            this.props.onBlur();
        }
    };
}

export const Popover = portal(PopoverComponent);

// Variant of the popover that isn't portalled. Allows for easier unmounting
// when the popover needs to always-be-present. RangeInput is the best example–
// we want to sometimes always show a popover above the range selection, but if
// it's in a portal it won't unmount when swapping pages via the nav or links, etc.
export const PopoverNoPortal = PopoverComponent;
