/* @flow */

import * as React from 'react';

import Select, {
    components,
    type SelectBaseProps,
    type ControlProps,
    type InputProps,
    type MenuListComponentProps,
    type PlaceholderProps,
    type NoticeProps,
} from 'react-select';

import {SearchIcon} from '../icon';
import {colors} from '../colors';

type Props = {|
    ...$Exact<SelectBaseProps>,
    minHeight?: number,
    noPadding?: boolean,
    specialOptions?: React.Node,
    // Prevents the window from scrolling down whenever the select menu is opened
    preventScrollOnMenuOpen?: boolean,
    // Override the default placeholder prop type to also accept a component
    placeholder?: string | React.Node,
    // Override the default noOptionsMessage prop type to also accept a component
    noOptionsMessage?: string | React.Node,
|};

export function SelectPickerSearch(props: Props) {
    const {
        specialOptions,
        minHeight,
        noPadding,
        preventScrollOnMenuOpen,
        placeholder,
        noOptionsMessage,
        ...restProps
    } = props;

    const preventScrollStyles = {
        container: (base) => ({
            ...base,
            // Redefine the wrapping context so fixed position can be used for menu
            transform: 'translateZ(0)',
            // Relatively positioned so z-index can be increased
            position: 'relative',
            // Needed to ensure select appears over any other relatively positioned elements
            zIndex: 10,
        }),
        menu: (base) => ({
            ...base,
            // We can now use a fixed position on the menu within the wrapping context
            position: 'fixed',
        }),
    };

    const styles = {
        ...(preventScrollOnMenuOpen ? preventScrollStyles : {}),
        control: (base, state) => ({
            ...base,
            borderColor: state.selectProps.value ? colors.blue : colors.offWhiteDk,
            opacity: state.selectProps.isDisabled ? '0.7' : '1',
            backgroundColor: '#fff',
            minHeight: minHeight ? `${minHeight}px` : '38px', // use default 38px
        }),
        singleValue: (base) => ({
            ...base,
            position: 'relative',
            transform: 'none',
            translate: 'none',
            top: 'initial',
            padding: noPadding ? undefined : '8px',
        }),
        option: (base, state) => {
            let backgroundColor = base.backgroundColor;
            let color = base.color;
            if (state.isSelected) {
                backgroundColor = colors.listSelected;
                color = colors.greyDk;
            } else if (state.isFocused && !state.isDisabled) {
                backgroundColor = colors.listHovered;
            }

            return {
                ...base,
                backgroundColor,
                color,
                ':active': {backgroundColor},
            };
        },
    };

    return (
        <Select
            {...restProps}
            specialOptions={specialOptions}
            customPlaceholder={placeholder}
            customNoOptionsMessage={noOptionsMessage}
            styles={styles}
            isClearable={true}
            blurInputOnSelect={true}
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                Placeholder,
                Control,
                Input,
                MenuList,
                NoOptionsMessage,
            }}
        />
    );
}

const Control = ({children, ...props}: {...ControlProps}) => (
    <components.Control {...props}>
        {!props.selectProps.value ? (
            <div style={{color: colors.greyLt, marginLeft: 12}}>
                <SearchIcon size={15} />
            </div>
        ) : undefined}
        {children}
    </components.Control>
);

const MenuList = ({children, ...props}: {...MenuListComponentProps}) => (
    <components.MenuList {...props}>
        {props.selectProps.specialOptions}
        {children}
    </components.MenuList>
);

const Input = ({...props}: {...InputProps}) => (
    // $FlowIgnore - hide input if value is selected and input has no value
    <components.Input {...props} isHidden={props.selectProps.value && !props.value} />
);

const Placeholder = ({children, ...props}: {...PlaceholderProps}) => (
    <components.Placeholder {...props}>
        {props.selectProps.customPlaceholder ? props.selectProps.customPlaceholder : children}
    </components.Placeholder>
);

const NoOptionsMessage = ({children, ...props}: {...NoticeProps}) => (
    <components.NoOptionsMessage {...props}>
        {props.selectProps.customNoOptionsMessage
            ? props.selectProps.customNoOptionsMessage
            : children}
    </components.NoOptionsMessage>
);
