/* @flow */

import * as React from 'react';

import {Body} from 'shells/typography';
import type {MergeField} from 'shells/merge-fields';

export const RECIPIENT_MERGE_FIELD_OPTIONS: MergeField[] = [
    {
        value: 'recipientFirstName',
        label: 'Recipient first name',
        fallbackText: {
            defaultValue: 'Friend',
            description: getFallbackDescription('RECIPIENT', 'first name'),
        },
        description: getDescription('RECIPIENT', 'first name'),
    },
    {
        value: 'recipientLastName',
        label: 'Recipient last name',
        fallbackText: {
            defaultValue: '',
            description: getFallbackDescription('RECIPIENT', 'last name'),
        },
        description: getDescription('RECIPIENT', 'last name'),
    },
    {
        value: 'recipientFullName',
        label: 'Recipient full name',
        fallbackText: {
            defaultValue: '',
            description: getFallbackDescription('RECIPIENT', 'full name'),
        },
        description: getDescription('RECIPIENT', 'full name'),
    },
];

export const COMPANY_MERGE_FIELD_OPTIONS: MergeField[] = [
    {
        value: 'recipientCompanyName',
        label: 'Recipient company name',
        fallbackText: {
            defaultValue: 'Your company',
            description: getFallbackDescription('RECIPIENT', 'company name'),
        },
        description: getDescription('RECIPIENT', 'company name'),
    },
];

export const SENDER_MERGE_FIELD_OPTIONS: MergeField[] = [
    {
        value: 'senderFirstName',
        label: 'Sender first name',
        // Sender must/will always exist, doesn’t warrant a fallback
        fallbackText: null,
        description: getDescription('SENDER', 'first name'),
    },
    {
        value: 'senderLastName',
        label: 'Sender last name',
        // Sender must/will always exist, doesn’t warrant a fallback
        fallbackText: null,
        description: getDescription('SENDER', 'last name'),
    },
    {
        value: 'senderFullName',
        label: 'Sender full name',
        // Sender must/will always exist, doesn’t warrant a fallback
        fallbackText: null,
        description: getDescription('SENDER', 'full name'),
    },
];

function getDescription(personType: 'RECIPIENT' | 'SENDER', fieldName: string) {
    return (
        <Body size='small' color='light'>
            Displays the{' '}
            <Body isBold={true} size='small' color='light'>
                {fieldName}
            </Body>{' '}
            of the {personType === 'RECIPIENT' ? 'recipient' : 'sender'} of the text message.
        </Body>
    );
}

function getFallbackDescription(personType: 'RECIPIENT' | 'SENDER', fieldName: string) {
    return (
        <Body size='small' color='light'>
            If {personType === 'RECIPIENT' ? 'a recipient' : 'the sender'} does not have a{' '}
            <Body isBold={true} size='small' color='light'>
                {fieldName}
            </Body>
            , this text will be used.
        </Body>
    );
}
