/* @flow */

import * as React from 'react';
import './location-empty-view.css';

export function LocationEmptyView() {
    return (
        <div styleName='location-empty-view'>
            <h2 styleName='title'>Nothing was found.</h2>
            <img styleName='image' src='/include/images/empty-states/location.png' />
        </div>
    );
}
