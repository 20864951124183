/* @flow */

import type {SourceType} from './types';

export function getSourceTypeFromValue(typeValue: number): SourceType {
    switch (typeValue) {
        case 1:
            return 'CAMPAIGN';
        case 2:
            return 'SOURCE';
        case 3:
            return 'MEDIUM';
        case 4:
            return 'CONTENT';
        case 5:
            return 'TERM';
        case 6:
            return 'REFERRER';
        case 0:
        default:
            return 'NONE';
    }
}

export function getDisplayableSourceType(type: SourceType): ?string {
    switch (type) {
        case 'CAMPAIGN':
            return 'utm_campaign';
        case 'CONTENT':
            return 'utm_content';
        case 'MEDIUM':
            return 'utm_medium';
        case 'SOURCE':
            return 'utm_source';
        case 'TERM':
            return 'utm_term';
        case 'NONE':
            return null;
    }
}
