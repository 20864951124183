import PropTypes from 'prop-types';
import * as React from 'react';
import classnames from 'classnames';
import './location-result.css';

export class LocationResult extends React.Component {
    static propTypes = {
        isSelected: PropTypes.bool,
        locationResult: PropTypes.object.isRequired,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: () => {},
    };

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    render() {
        const selected = this.props.isSelected;
        const result = this.props.locationResult;
        const classes = classnames('location-result', {selected});

        return (
            <li styleName={classes} onClick={this.handleClick} onMouseDown={this.stop}>
                <a href='#' key={result.id}>
                    {result.locationString}
                </a>
            </li>
        );
    }

    stop = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleClick(e) {
        this.props.onClick(e, this.props.locationResult);
    }
}
