/* @flow */

import * as React from 'react';
import {InlineTabsContext} from './inline-tabs';

import './inline-tab.css';

type Props = {
    disabled?: boolean,
    value: string,
    children?: React.Node,
};

export class InlineTab extends React.Component<Props> {
    render() {
        return (
            <InlineTabsContext.Consumer>
                {({name, selectedTab, onChange, isDark, tabMinWidth}) => {
                    const isSelected = this.props.value === selectedTab;
                    const tabId = `${name}-${this.props.value}`;

                    const darkStyleName = isSelected ? 'tab-dark--active' : 'tab-dark';

                    return (
                        <button
                            styleName={isDark ? darkStyleName : 'inline-tab'}
                            /* Add min-width so tabs don't jump when selected bold text is applied, default is 70px */
                            style={{minWidth: tabMinWidth ? `${tabMinWidth}px` : '70px'}}
                            name={name}
                            role='tab'
                            id={`tab:${tabId}`}
                            tabIndex={isSelected ? -1 : 0}
                            aria-selected={isSelected}
                            aria-controls={`panel:${tabId}`}
                            data-selected={isSelected ? 1 : undefined}
                            disabled={this.props.disabled}
                            onClick={(e) => this.handleChange(e, onChange)}
                        >
                            {this.props.children}
                        </button>
                    );
                }}
            </InlineTabsContext.Consumer>
        );
    }

    handleChange = (e: SyntheticInputEvent<*>, onChange: Function) => {
        e.stopPropagation();

        if (!this.props.disabled) {
            onChange(this.props.value);
        }
    };
}
