/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ArrowLeftThinIconSvg from './icon-arrow-left-thin.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ArrowLeftThinIconSvg {...validProps} />;
}
SvgComponent.iconName = 'arrowLeftThinIcon';

export const ArrowLeftThinIcon = svgIconWrapper(SvgComponent);
