/* @flow */

import * as React from 'react';
import {FrownyIcon, SidebarIconSearchIcon} from 'shells/icon';

import styles from './empty-view.css';

type Props = {
    hasQuery?: boolean,
    noResultsSubtitle: string,
    emptyActionLink?: React.Element<any>,
};

export class SearchResultsEmptyView extends React.Component<Props> {
    static defaultProps = {
        noResultsSubtitle: 'No companies, people, or leads were found.',
    };

    render() {
        return this.props.hasQuery ? (
            <div styleName='search-results-empty-view'>
                <FrownyIcon className={styles.icon} />
                <h2 styleName='title'>No search results.</h2>
                <h2 styleName='subtitle'>{this.props.noResultsSubtitle}</h2>
                {this.props.emptyActionLink ? this.props.emptyActionLink : undefined}
            </div>
        ) : (
            <div styleName='search-results-empty-view'>
                <SidebarIconSearchIcon className={styles.icon} />
                <h2 styleName='title'>Nothing to see… yet!</h2>
                <h2 styleName='subtitle'>
                    As soon as you start looking around Nutshell, your recently viewed companies,
                    people, and leads will appear.
                </h2>
            </div>
        );
    }
}
