/* @flow */

import type {
    GetAccountBilling_accountBilling_currentPlan as AccountPlan,
    SubscriptionFragment,
} from 'nutshell-graphql-types';

export const getAddOnsIncludedInPlan = (plan: ?AccountPlan) => {
    const includedAddOns = [];
    const addOns = ['marketing', 'landingPages', 'nutshellIq', 'revenueBooster'];
    addOns.forEach((addOn) => {
        if (plan && plan[addOn]) {
            includedAddOns.push(plan[addOn]);
        }
    });

    return includedAddOns;
};

export const getPlanDetailText = (subscription: SubscriptionFragment) => {
    switch (subscription.product) {
        case 'CRM':
            return `${subscription.quantity.toLocaleString()} ${
                subscription.quantity === 1 ? 'user' : 'users'
            }`;
        case 'MARKETING':
            return `${subscription.productQuantity.toLocaleString()} ${
                subscription.productQuantity === 1 ? 'contact' : 'contacts'
            }`;
        case 'NUTSHELL_IQ':
            return `${subscription.productQuantity.toLocaleString()} ${
                subscription.productQuantity === 1 ? 'credit' : 'credits'
            }`;
        case 'INBOX':
            return `${subscription.quantity.toLocaleString()} ${
                subscription.quantity === 1 ? 'seat' : 'seats'
            }`;
        case 'REVENUE_BOOSTER':
        case 'LANDING_PAGES':
        default:
            return null;
    }
};

export const getMarketingSuitePrice = (
    marketingSuitePlanPrice: string,
    smsCampaignsPlanPrice: ?string
) => {
    if (!smsCampaignsPlanPrice) {
        return marketingSuitePlanPrice;
    }

    // Remove $ and commas before parsing because parseFloat doesn't support
    // them
    const marketingTotal = parseFloat(marketingSuitePlanPrice.replace(/[$,]/g, ''));
    const smsTotal = parseFloat(smsCampaignsPlanPrice.replace(/[$,]/g, ''));
    const total = `$${(marketingTotal + smsTotal).toFixed(2).toLocaleString()}`;

    return total;
};
