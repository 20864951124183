/* @flow */

import * as React from 'react';
import {CloseButton, Button} from 'shells/button';
import {Popover, type LocationEnum} from 'shells/popover';
import {Dialog} from 'shells/dialog';
import type {UnserializedFilterObject} from 'nutshell-core/types';

import getClassesMixin from '../mixins/get-classes';
import {FilterFormDeprecated} from './filter-form-deprecated';

type Props = {
    anchor: ?HTMLElement,
    onSubmit: (UnserializedFilterObject<*>) => void,
    onRemove: (e: SyntheticEvent<>) => void,
    onClose: () => void,
    field: Object,
    value?: Object | string,
    filter: ?(Object | string),
    location?: LocationEnum,
};

type State = {
    isDirty: boolean,
    confirmClose: boolean,
};

export class PopoverForm extends React.Component<Props, State> {
    getClasses = getClassesMixin;
    closeButton: ?HTMLElement;

    static defaultProps = {
        location: 'bottom',
        // $FlowFixMe upgrading Flow to v0.92.1 on web
        onRemove: (e) => e,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            isDirty: false,
            confirmClose: false,
        };
    }

    render() {
        const classes = this.getClasses('ui-popover ui-popover-form');

        return (
            <Popover
                className={classes}
                anchor={this.props.anchor}
                location={this.props.location}
                onBlur={this.handleClose}
            >
                <div className='toolbar'>
                    <h1 className='ui-header ui-header--md'>{this.props.field.title}</h1>
                </div>
                <div className='inner-wrapper'>
                    <CloseButton
                        getRef={(c) => {
                            this.closeButton = c;
                        }}
                        className='ui-btn__popover-close'
                        onClick={this.handleClose}
                    />
                    <div className='ui-popover-form__form-container'>
                        <FilterFormDeprecated
                            className='ui-form--popover-form'
                            field={this.props.field}
                            value={this.props.value}
                            onDirty={(isDirty) => this.setState({isDirty: isDirty})}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                    {this.props.filter && !this.props.field.unclearable ? (
                        <Button variant='danger' size='big' onClick={this.props.onRemove}>
                            Remove filter
                        </Button>
                    ) : undefined}
                </div>
                {this.state.confirmClose ? this.renderCloseDialog() : undefined}
            </Popover>
        );
    }

    handleClose = () => {
        if (this.state.isDirty) {
            this.setState({confirmClose: true});
        } else {
            this.props.onClose();
        }
    };

    handleSubmit = (data: UnserializedFilterObject<*>) => {
        this.props.onSubmit(data);
        this.props.onClose();
    };

    renderCloseDialog() {
        return (
            <Dialog
                anchor={this.closeButton}
                location='right'
                question='Discard changes?'
                onPrimary={this.props.onClose}
                onSecondary={() => this.setState({confirmClose: false})}
            />
        );
    }
}
