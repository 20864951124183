/* @flow */

import type {CampaignStatus, EditionStatus, FormStatus} from 'nutshell-graphql-types';

export type RelativeTimeFilter = '-d7' | '-d30' | '-d90' | '-d365' | 'w' | 'm' | 'all';
export type lastUpdatedOptions =
    | 'Last 7 days'
    | 'Last 30 days'
    | 'Last 90 days'
    | 'Last 365 days'
    | 'Last week'
    | 'Last month'
    | 'All time';

// This gets the archived value from the active filters for use in querying
// broadcastst, drip sequences, and newsletters
export const getArchivedValue = (activeFilters: ?Object): boolean => {
    const archivedValueFilterItem =
        activeFilters &&
        activeFilters.find((filter) => {
            return Object.keys(filter)[0] === 'archived';
        });

    const archivedValue = Boolean(
        archivedValueFilterItem && archivedValueFilterItem.archived === 'true'
    );

    return archivedValue;
};

// This gets the ignored value from the active filters for use in querying
// connectedForms
export const getIgnoredValue = (activeFilters: ?Object): boolean => {
    const ignoredValueFilterItem =
        activeFilters &&
        activeFilters.find((filter) => {
            return Object.keys(filter)[0] === 'ignored';
        });

    return Boolean(ignoredValueFilterItem && ignoredValueFilterItem.ignored === 'true');
};

// This gets the lastUpdatedValue from the active filters for use in querying
// broadcastst, drip sequences, and newsletters
export const getLastUpdatedValue = (activeFilters: ?Object): ?RelativeTimeFilter => {
    const lastUpdatedFilterItem =
        activeFilters &&
        activeFilters.find((filter) => {
            return Object.keys(filter)[0] === 'lastUpdated';
        });

    const lastUpdatedValue =
        lastUpdatedFilterItem && lastUpdatedFilterItem.lastUpdated
            ? lastUpdatedFilterItem.lastUpdated
            : undefined;

    if (lastUpdatedValue === 'all') {
        return undefined;
    }

    return lastUpdatedValue;
};

/**
 * This takes the active filters on the audience page and returns a boolean
 * determining whether to return the archived audiences
 *
 * @param {Object} activeFilters  These are the active filters on the audience page
 * @returns boolean  Returns true to get archived audiences, false otherwise
 */
export const getArchivedForAudienceQuery = (activeFilters: ?Object): ?boolean => {
    // if there is no status filter, isArchived filter defaults to false
    if (!activeFilters || !activeFilters.find((filter) => Object.keys(filter)[0] === 'status')) {
        return undefined;
    }

    const archivedStatusFilterItem =
        activeFilters && activeFilters.find((filter) => Object.keys(filter)[0] === 'status');

    const archivedStatusValue =
        archivedStatusFilterItem &&
        archivedStatusFilterItem.status &&
        archivedStatusFilterItem.status.data
            ? archivedStatusFilterItem.status.data
            : undefined;

    const statusesArray = [];
    if (archivedStatusValue) {
        archivedStatusValue.forEach((value) => statusesArray.push(value.data));
    }

    // if they are including both archived and active audiences, isArchived filter is true
    if (statusesArray && statusesArray.length === 2) {
        return true;
    }

    // if they are including only archived audiences, isArchived filter is true
    if (statusesArray && statusesArray[0] === 'Archived') {
        return true;
    }

    // if they are not explicitly including archived audiences, isArchived filter defaults to false
    return undefined;
};

/**
 * This takes the active filters on the audience page and returns a boolean
 * determining whether to return the unarchived (active) audiences. Because of the
 * way our query works, if getArchivedForAudienceQuery and getUnarchivedForAudienceQuery
 * return false, we will get only unarchived audiences.
 *
 * @param {Object} activeFilters  These are the active filters on the audience page
 * @returns boolean  Returns true to get unarchived, false otherwise
 */
export const getUnarchivedForAudienceQuery = (activeFilters: ?Object): ?boolean => {
    // if there is no status filter, isUnarchived filter defaults to false
    if (!activeFilters || !activeFilters.find((filter) => Object.keys(filter)[0] === 'status')) {
        return undefined;
    }

    const archivedStatusFilterItem =
        activeFilters && activeFilters.find((filter) => Object.keys(filter)[0] === 'status');

    const archivedStatusValue =
        archivedStatusFilterItem &&
        archivedStatusFilterItem.status &&
        archivedStatusFilterItem.status.data
            ? archivedStatusFilterItem.status.data
            : undefined;

    const statusesArray = [];
    if (archivedStatusValue) {
        archivedStatusValue.forEach((value) => statusesArray.push(value.data));
    }

    // if they are including both archived and active audiences, isUnarchived filter is true
    if (statusesArray && statusesArray.length === 2) {
        return true;
    }

    // if they are including only active audiences, isUnarchived filter is true
    if (statusesArray && statusesArray[0] === 'Active') {
        return true;
    }

    // if they are not explicitly including active audiences, isUnarchived filter defaults to false
    return undefined;
};

const getStatusesArray = (activeFilters: ?Object) => {
    const statusFilterItem =
        activeFilters &&
        activeFilters.find((filter) => {
            return Object.keys(filter)[0] === 'status';
        });

    const statusValueObjectArray =
        statusFilterItem && statusFilterItem.status && statusFilterItem.status.data
            ? statusFilterItem.status.data
            : undefined;

    const statusesArray = [];
    if (statusValueObjectArray) {
        statusValueObjectArray.forEach((value) => statusesArray.push(value.data));
    }

    return statusesArray;
};

// This maps the active filters to an array of statuses for querying broadcasts
export const getEditionStatusesForQuery = (activeFilters: ?Object): EditionStatus[] => {
    const statusesArray = getStatusesArray(activeFilters);
    const statusesForQuery = [];

    statusesArray.forEach((status) => {
        switch (status) {
            case 'Draft':
                statusesForQuery.push('NEW');
                break;
            case 'Scheduled':
                statusesForQuery.push('SCHEDULED');
                break;
            case 'Halted':
                statusesForQuery.push('HALTED');
                break;
            case 'InReview':
                statusesForQuery.push('IN_REVIEW');
                break;
            case 'Sent':
                statusesForQuery.push('SENT', 'SENDING');
                break;
            case 'Archived':
                statusesForQuery.push('ARCHIVED');
                break;
        }
    });

    return statusesForQuery;
};

// This maps the active filters to an array of statuses for querying drip sequences and newsletters
export const getCampaignStatusesForQuery = (activeFilters: ?Object): CampaignStatus[] => {
    const statusesArray = getStatusesArray(activeFilters);
    const statusesForQuery = [];

    statusesArray.forEach((status) => {
        switch (status) {
            case 'Draft':
                statusesForQuery.push('DRAFT');
                break;
            case 'Active':
                statusesForQuery.push('ACTIVE');
                break;
            case 'Paused':
                statusesForQuery.push('PAUSED');
                break;
            case 'Retired':
                statusesForQuery.push('RETIRED');
                break;
            case 'Canceled':
                statusesForQuery.push('CANCELLED');
                break;
            case 'Archived':
                statusesForQuery.push('ARCHIVED');
                break;
        }
    });

    return statusesForQuery;
};

// This maps the active filters to an array of statuses for querying drip sequences and newsletters
export const getFormStatusesForQuery = (activeFilters: ?Object): FormStatus[] => {
    const statusesArray = getStatusesArray(activeFilters);
    const statusesForQuery = [];

    statusesArray.forEach((status) => {
        switch (status) {
            case 'Draft':
                statusesForQuery.push('NOT_ACTIVATED');
                break;
            case 'Active':
                statusesForQuery.push('ACTIVATED');
                break;
        }
    });

    return statusesForQuery;
};

export const convertLastUpdatedOptionToRelativeTime = (newFilter: ?Object): ?RelativeTimeFilter => {
    let lastUpdatedSelection;

    // The newFilter is an array, but since we only want to allow one lastUpdated filter,
    // we grab the last one in the array and use that value
    const selection = newFilter ? newFilter.lastUpdated.data.at(-1).data : undefined;

    switch (selection) {
        case 'Last 7 days':
            lastUpdatedSelection = '-d7';
            break;
        case 'Last 30 days':
            lastUpdatedSelection = '-d30';
            break;
        case 'Last 90 days':
            lastUpdatedSelection = '-d90';
            break;
        case 'Last 365 days':
            lastUpdatedSelection = '-d365';
            break;
        case 'Last week':
            lastUpdatedSelection = 'w';
            break;
        case 'Last month':
            lastUpdatedSelection = 'm';
            break;
        case 'All time':
            lastUpdatedSelection = 'all';
            break;
    }

    return lastUpdatedSelection;
};

export const convertRelativeTimeToLastUpdatedOption = (filter: ?Object): ?lastUpdatedOptions => {
    let lastUpdatedOption;

    const filterData = filter ? filter : undefined;

    switch (filterData) {
        case '-d7':
            lastUpdatedOption = 'Last 7 days';
            break;
        case '-d30':
            lastUpdatedOption = 'Last 30 days';
            break;
        case '-d90':
            lastUpdatedOption = 'Last 90 days';
            break;
        case '-d365':
            lastUpdatedOption = 'Last 365 days';
            break;
        case 'w':
            lastUpdatedOption = 'Last week';
            break;
        case 'm':
            lastUpdatedOption = 'Last month';
            break;
        case 'all':
            lastUpdatedOption = 'All time';
    }

    return lastUpdatedOption;
};
