/* @flow */

import * as React from 'react';
import _ from 'underscore';

import {SearchIcon, CloseIcon as Clear} from '../icon';
import {TextField} from '../textfield/index';
// eslint-disable-next-line no-unused-vars
import styles from './search-bar.css';

const DEFAULT_HEIGHT = '35px';
const ACTIVE_BORDER_COLOR = '#1ba0e1'; // This is `var(--blue)`

type Props = {
    onChange: (string) => any,
    value: string,
    placeholder: string,
    isClearable?: boolean,
    autoFocus?: boolean,
    onBlur?: () => any,
    onFocus?: () => any,
    searchBarRef?: (?HTMLInputElement) => any,
    hasBorder?: boolean,
    styleOptions?: {
        height?: string | number,
        borderColor?: string,
    },
    // Renders with less padding
    isSmall?: boolean,
    // This is the accessibility label string
    hiddenLabel?: string,
};

type State = {
    isFocused: boolean,
};

export class SearchBar extends React.Component<Props, State> {
    inputRef: ?HTMLInputElement;

    static defaultProps = {
        placeholder: 'Search…',
        value: '',
    };

    constructor() {
        super();
        this.state = {
            isFocused: false,
        };
    }

    componentDidMount() {
        if (this.props.autoFocus) {
            _.defer(this.focusSearchBar);
        }
    }

    render() {
        const {styleOptions, value, isClearable} = this.props;

        const containerStyle = {
            height: styleOptions ? styleOptions.height : DEFAULT_HEIGHT,
            borderColor:
                this.state.isFocused && this.props.hasBorder ? ACTIVE_BORDER_COLOR : undefined,
        };

        return (
            <div
                styleName={this.props.hasBorder ? 'styles.bordered' : 'styles.no-border'}
                style={containerStyle}
            >
                <div
                    styleName={
                        this.props.isSmall
                            ? 'styles.search-container--small'
                            : 'styles.search-container'
                    }
                >
                    <SearchIcon styleName='styles.search-icon' />
                </div>
                <div styleName={this.props.isSmall ? 'styles.input--small' : 'styles.input'}>
                    {this.props.hiddenLabel ? (
                        <label htmlFor='search' className='display-none'>
                            {this.props.hiddenLabel}
                        </label>
                    ) : undefined}
                    <TextField
                        id='search'
                        value={value}
                        placeholder={this.props.placeholder}
                        textFieldRef={this.handleRefAssignment}
                        noBorder={true}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                    />
                </div>
                {value && isClearable ? (
                    <button
                        styleName={this.props.isSmall ? 'styles.clear--small' : 'styles.clear'}
                        onClick={this.handleClear}
                    >
                        <Clear
                            size={10}
                            className={this.props.isSmall ? 'styles.clear--small' : 'styles.clear'}
                        />
                    </button>
                ) : undefined}
            </div>
        );
    }

    handleChange = (value: string) => {
        this.props.onChange(value);
    };

    handleFocus = () => {
        this.setState({isFocused: true});
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    handleBlur = () => {
        this.setState({isFocused: false});
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    handleClear = () => {
        this.props.onChange('');
    };

    focusSearchBar = () => {
        if (this.inputRef) {
            this.inputRef.focus();
        }
    };

    handleRefAssignment = (node: ?HTMLInputElement) => {
        this.inputRef = node;

        if (this.props.searchBarRef) {
            this.props.searchBarRef(node);
        }
    };
}
