import * as React from 'react';
import PropTypes from 'prop-types';
import {withFormsy, propTypes as formsyPropTypes} from 'formsy-react';

import InputAjaxSearch from '../../base/input-ajax-search';
import getClassesMixin from '../../mixins/get-classes';
import {LocationResult} from '../../core/search/location-result';
import {LocationFound} from '../../core/search/location-found';
import {LocationEmptyView} from '../../core/search/location-empty-view';

class FormLocationSearchComponent extends React.Component {
    getClasses = getClassesMixin;

    static propTypes = {
        placeholder: PropTypes.string,
        popoverClass: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
        ...formsyPropTypes,
    };

    static defaultProps = {
        value: {},
    };

    render() {
        const classes = this.getClasses('ui-location-search');

        const {placeholder, popoverClass} = this.props;

        return (
            <div className={classes}>
                <InputAjaxSearch
                    placeholder={placeholder}
                    popoverClass={popoverClass}
                    shouldOpenOnFocus={false}
                    emptyView={LocationEmptyView}
                    updateQueryOnSelect={true}
                    onSelect={(e, result) => this.handleSelect(result)}
                    renderRow={(result, highlighted) => this.renderLocation(result, highlighted)}
                    search={(q, callback) => this.search(q, callback)}
                />
                {this.props.getValue().locationString ? (
                    <LocationFound
                        locationString={this.props.getValue().locationString}
                        onClear={(e) => this.handleClear(e)}
                    />
                ) : undefined}
            </div>
        );
    }

    search(q, callback) {
        const endpoint = 'https://api.tiles.mapbox.com/v4/geocode/mapbox.places';
        const uri = `${endpoint}/${encodeURIComponent(q)}.json?access_token=${
            this.props.accessToken
        }`;

        $.getJSON(uri, null).then(({features}) => {
            const locations = features
                .filter((feature) => feature.geometry && feature.geometry.coordinates)
                .map((feature) => ({
                    locationString: feature.place_name,
                    coordinates: feature.geometry.coordinates.join(','),
                }));

            callback(locations);
        });
    }

    renderLocation(result, highlighted) {
        return (
            <LocationResult
                key={result.locationString}
                locationResult={result}
                isSelected={highlighted}
            />
        );
    }

    handleSelect(result) {
        this.props.setValue(result);
        this.props.onSelect(result);
    }

    handleClear(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.setValue('');
    }
}

export const FormLocationSearch = withFormsy(FormLocationSearchComponent);
