/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import ReactQuill, {Quill} from 'react-quill';
import 'quill-mention';

import type {GetTeams_teams as Team} from 'nutshell-graphql-types';

import {colors} from '../colors';
import {Popover} from '../popover';
import {MODULES} from '../email-editor/configuration';
import type {Placeholder} from '../email-editor/placeholder-blot';
import {type State as PlaceholderState} from '../email-editor/email-editor';
import {PlaceholderPopover} from '../email-editor/placeholder-popover';

import {makeTypedTextLinkifier, linkifyPastedText} from './linkify';
import {getTextValueWithCustomEmbeds} from './helpers';
import {configureQuill} from './configure-quill';

// Need this import for quill styling
// eslint-disable-next-line no-unused-vars
import styles from './rich-textarea.css';

// eslint-disable-next-line import/no-commonjs
require('react-quill/dist/quill.snow.css');

export type MentionConfig = {
    enabled: boolean,
    getMentions: (searchTerm: string, teams: ?(Team[])) => Promise<*>,
};

type RichTextareaConfig = {
    modules: Object,
    formats: string[],
    images: boolean,
    formatLinks: boolean,
    useDivs: boolean,
    placeholders: boolean,
    toolbarId?: string,
    mention?: MentionConfig,
};

type Props = {|
    id: string,
    value: string,
    // onChange should be optional in case of readOnly usage
    onChange?: (htmlValue: string, textValue: string) => void,
    placeholder?: string,
    onFocus?: () => void,
    onBlur?: () => void,
    onKeyPress?: (event: SyntheticKeyboardEvent<>) => void,
    onKeyDown?: (event: SyntheticKeyboardEvent<>) => void,
    onKeyUp?: (event: SyntheticKeyboardEvent<>) => void,
    onEnter?: () => any,
    onTabPropagateToQuill?: boolean,
    readOnly?: boolean,
    errorMessage?: string,
    isFullHeight?: boolean,
    isBodyFocused?: boolean,
    overflowVisible?: boolean,
    forceFocusOnClick?: boolean,
    autoFocus?: boolean,
    forceFocus?: boolean,
    getQuillRef?: (?HTMLElement) => void,
    children?: React.Node,
    notificationBanner?: React.Node,
    toolbarComponent?: React.Node,
    config: RichTextareaConfig,
    noPadding?: boolean,
    widePadding?: boolean,
    hasTranscription?: boolean,
    // Sets font-size to 15px;
    largerText?: boolean,
    // State used in parent to maintain placeholders
    setPlaceholderState?: (newState: PlaceholderState) => void,
    placeholderState?: PlaceholderState,
    teams?: Team[],
|};

const getInitials = (item) => {
    let backgroundColor;

    switch (item.type) {
        case 'contacts':
            backgroundColor = colors.navyDk;
            break;
        case 'accounts':
            backgroundColor = colors.plumDk;
            break;
        case 'leads':
            backgroundColor = colors.greenDk;
            break;
        case 'users':
        default:
            backgroundColor = colors.orangeDk;
    }

    if (item.initials) {
        return `<div style="background-color: ${backgroundColor}">${item.initials}</div>`;
    } else {
        return '';
    }
};

const getModules = (config: RichTextareaConfig, teams: ?(Team[])) => {
    const quillModules = config.modules;
    if (config.mention) {
        quillModules.mention = {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@'],
            isolateCharacter: true,
            defaultMenuOrientation: 'bottom',
            source: (searchTerm, renderList) => {
                if (config.mention && config.mention.getMentions) {
                    config.mention
                        .getMentions(searchTerm, teams)
                        .then((response) => {
                            const matches = response.matches;
                            if (!matches.length) {
                                renderList([
                                    {
                                        id: 'EMPTY_STATE',
                                        value: 'Nothing was found',
                                        // Built in key from quill-mention to make
                                        // this item not selectable
                                        disabled: true,
                                    },
                                ]);
                            } else {
                                renderList(matches);
                            }
                        })
                        .catch(() => {
                            renderList([
                                {
                                    id: 'EMPTY_STATE',
                                    value: 'Something went wrong.',
                                    // Built in key from quill-mention to make
                                    // this item not selectable
                                    disabled: true,
                                },
                            ]);
                        });
                }
            },
            renderLoading: () => {
                return '<div>Loading…</div>';
            },
            renderItem: (item) => {
                // Couldn't find an official way to show an empty state or
                // error message, so we're passing it in this way.
                if (item.id === 'EMPTY_STATE') {
                    return `
                        <div class="ql-mention-empty-state">${item.value}</div>
                    `;
                }

                return `
                    <div class="ql-mention-entity">
                        <div class="ql-mention-entity-avatar">
                            ${getInitials(item)}
                            ${
                                item.avatarUrl && item.avatarUrl.length
                                    ? `<img src=${item.avatarUrl} />`
                                    : ''
                            }
                        </div>
                        <div class="ql-mention-entity-info">
                            <div class="ql-mention-entity-name">
                                ${item.value}
                            </div>
                            <div class="ql-mention-entity-detail">
                                ${item.description}
                            </div>
                            ${
                                item.tertiaryInfo
                                    ? `<div class="ql-mention-entity-detail">
                                    ${item.tertiaryInfo}
                                </div>`
                                    : ''
                            }
                        </div>
                    </div>
                `;
            },
            onSelect: (item, insertItem) => {
                const mentionItem = {
                    id: item.id,
                    mentionText: item.value,
                };

                insertItem(mentionItem);
            },
        };
    }

    return quillModules;
};

/**
 * The WYSIWYG editor that Nutshell uses for rich text. This is used for both
 * simple HTML email composition and fields that need @mentions like timeline
 * comments
 */
export class RichTextarea extends React.PureComponent<Props> {
    quillRef: ?ReactQuill;
    placeholderRef: ?ReactQuill;
    modules: Object;

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.quillRef) {
            // If we have the quill editor reference, and either an error message or
            // placeholder, we need to set the quill placeholder. This is because
            // quill doesn't support setting the placeholder dynamicly in the props.
            // See https://github.com/zenoamaro/react-quill/issues/340#issuecomment-376176878
            if (nextProps.errorMessage || nextProps.placeholder) {
                this.quillRef.editor.root.dataset.placeholder = nextProps.errorMessage
                    ? nextProps.errorMessage
                    : nextProps.placeholder;
            }
        }
    }

    constructor(props: Props) {
        super(props);

        // Configures quill - until this component mounts and runs this, any bound usages of this.quillRef will be undef.
        configureQuill({
            images: props.config.images,
            formatLinks: props.config.formatLinks,
            useDivs: props.config.useDivs,
            placeholders: props.config.placeholders,
            mention: props.config.mention,
        });

        // We set the toolbar module here because we use a dynamic toolbarId and this
        // ensures that we are binding the correct toolbar to the corresponding quill editor
        this.modules = {
            ...getModules(this.props.config, this.props.teams),
            toolbar: this.props.config.toolbarId
                ? {container: `#${this.props.config.toolbarId}`}
                : false,
            // Override the default behavior of 'enter'. Similar to toolbar, this
            // does not work if done in configureQuill() with rest of modules.
            keyboard: {
                bindings: {
                    enter: this.props.onEnter
                        ? {
                              key: 13,
                              shiftKey: false,
                              handler: () => {
                                  if (this.props.onEnter) {
                                      this.props.onEnter();
                                  }
                              },
                          }
                        : undefined,
                    tab: this.props.onTabPropagateToQuill
                        ? {
                              key: 9,
                              handler: () => {
                                  return true;
                              },
                          }
                        : undefined,
                },
            },
        };
    }

    componentDidMount() {
        if (this.quillRef) {
            const quill = this.quillRef.getEditor();
            if (this.quillRef && this.props.autoFocus) {
                this.quillRef.focus();
            }
            // This setImmediate forceFocus seems to be the only way to autofocus
            setImmediate(() => {
                if (this.props.isBodyFocused) {
                    this.forceFocus();
                }
            });

            // Convert urls into actual links when pasting text
            quill.clipboard.addMatcher(Node.TEXT_NODE, linkifyPastedText);

            // Detect typed-in urls, and convert them to links
            quill.on('text-change', makeTypedTextLinkifier(quill));

            // Due to a bug in quill, pasting results in blurring the editor, which
            // breaks us because we don't register changes to the editor when it is not focused.
            // Instead, we will manually detect focus and blur, as demonstrated in:
            // https://codepen.io/DmitrySkripkin/pen/eeXpZB?editors=0010
            // bug: https://github.com/zenoamaro/react-quill/issues/276
            quill.on('selection-change', (range, oldRange) => {
                if (range === null && oldRange !== null) {
                    this.handleBlur();
                } else if (range !== null && oldRange === null) {
                    this.handleFocus();
                }
            });

            if (this.props.toolbarComponent) {
                const toolbar = quill.getModule('toolbar');

                if (toolbar) {
                    toolbar.addHandler('placeholder', this.toolbarPlaceholderHandler);
                }
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.forceFocus) {
            this.forceFocus();
        }

        if (prevProps.teams !== this.props.teams) {
            this.modules = {...this.modules, ...getModules(this.props.config, this.props.teams)};
        }
    }

    render() {
        const styleNames = classnames({
            'styles.quill-container': true,
            'styles.quill-container--error': Boolean(this.props.errorMessage),
            'quill-container--no-padding': this.props.noPadding,
            'quill-container--wide-padding': this.props.widePadding,
            'quill-container--larger-text': Boolean(this.props.largerText),
            'quill-container--with-transcription': Boolean(this.props.hasTranscription),
        });

        const editorStyleNames = classnames({
            editor: true,
            'editor--full-height': Boolean(this.props.isFullHeight),
            'editor--overflow-visible': Boolean(this.props.overflowVisible),
        });

        return (
            <div styleName={styleNames}>
                <div
                    styleName={editorStyleNames}
                    onClick={this.props.forceFocusOnClick ? this.forceFocus : undefined}
                >
                    <ReactQuill
                        id={this.props.id}
                        ref={(ref) => {
                            this.quillRef = ref;

                            if (this.props.getQuillRef) {
                                this.props.getQuillRef(ref);
                            }
                        }}
                        readOnly={this.props.readOnly}
                        value={this.props.value}
                        defaultValue={this.props.value}
                        onKeyPress={this.props.onKeyPress}
                        onKeyDown={this.props.onKeyDown}
                        onKeyUp={this.props.onKeyUp}
                        placeholder={this.props.placeholder}
                        modules={this.modules}
                        formats={this.props.config.formats}
                        onChange={this.handleChange}
                    />
                    {this.props.children}
                </div>
                {this.props.notificationBanner}
                {this.props.toolbarComponent}
                {this.props.placeholderState &&
                this.props.placeholderState.customPlaceholderData ? (
                    <React.Fragment>
                        <div
                            style={{
                                position: 'absolute',
                                ...this.props.placeholderState.customPlaceholderData
                                    .popoverLocation,
                            }}
                            ref={(ref) => {
                                if (this.props.setPlaceholderState) {
                                    this.props.setPlaceholderState({
                                        placeholderAnchor: ref ? ref : undefined,
                                    });
                                }
                            }}
                        />
                        {this.props.placeholderState &&
                        this.props.placeholderState.placeholderAnchor ? (
                            <Popover
                                location='bottom'
                                anchor={
                                    this.props.placeholderState
                                        ? this.props.placeholderState.placeholderAnchor
                                        : this.placeholderRef
                                }
                            >
                                <PlaceholderPopover
                                    position={
                                        this.props.placeholderState.customPlaceholderData
                                            .popoverLocation
                                    }
                                    onSave={this.handlePlaceholderNameSave}
                                    onDismiss={this.handlePlaceholderNameDismiss}
                                    shouldOnlyShowOnboarding={
                                        this.props.placeholderState.customPlaceholderData
                                            .didClickBlot
                                    }
                                />
                            </Popover>
                        ) : null}
                    </React.Fragment>
                ) : null}
            </div>
        );
    }

    /*
     * This method exists to provide an imperative method for other components to forcibly focus
     * the quill editor.
     */
    forceFocus = () => {
        if (this.quillRef) this.quillRef.focus();
    };

    handleFocus = () => {
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    handleBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    handleChange = (newValue: string, delta: Object, source: string, editor: Object) => {
        const textValue = getTextValueWithCustomEmbeds(editor);
        if (this.props.onChange) {
            this.props.onChange(newValue, textValue);
        }
    };

    /*
     * Handler for the toolbar placeholder
     */
    toolbarPlaceholderHandler = (identifier: string) => {
        const quill = this.quillRef ? this.quillRef.getEditor() : undefined;

        if (!quill) {
            return;
        }
        const selection = quill.getSelection();

        if (identifier === 'placeholder') {
            // Our custom placeholder option
            quill.deleteText(selection.index, selection.length);

            quill.insertEmbed(
                selection.index,
                'placeholder-custom-placeholder',
                {id: 'New placeholder', label: 'New placeholder'},
                Quill.sources.USER
            );

            const bounds = quill.getBounds(selection.index);

            if (this.props.setPlaceholderState) {
                this.props.setPlaceholderState({
                    customPlaceholderData: {
                        popoverLocation: {
                            top: bounds.top + bounds.height,
                            left: bounds.left,
                        },
                        selectionIndex: selection.index,
                        didClickBlot: false,
                    },
                });
            }

            return;
        }

        const placeholder = MODULES.placeholder.placeholders.filter(
            (pl: Placeholder) => pl.id === identifier
        )[0];
        if (!placeholder) throw new Error(`Missing placeholder for ${identifier}`);

        quill.deleteText(selection.index, selection.length);
        quill.insertEmbed(selection.index, 'placeholder', placeholder, Quill.sources.USER);
        quill.focus();
        quill.setSelection(selection.index + 1, 0);
    };

    /*
     * Handler for dismissing placeholder name popup
     */
    handlePlaceholderNameDismiss = () => {
        // Checks to make flow happy
        if (
            this.quillRef &&
            this.props.placeholderState &&
            this.props.placeholderState.customPlaceholderData &&
            !this.props.placeholderState.customPlaceholderData.didClickBlot
        ) {
            const selectionIndex = this.props.placeholderState.customPlaceholderData.selectionIndex;
            this.quillRef.getEditor().deleteText(selectionIndex, 1);
        }

        if (this.props.setPlaceholderState) {
            this.props.setPlaceholderState({customPlaceholderData: undefined});
        }
    };

    /*
     * Handler for saving placeholder name
     */
    handlePlaceholderNameSave = (value: string) => {
        const quill = this.quillRef ? this.quillRef.getEditor() : undefined;
        if (
            !quill ||
            !(this.props.placeholderState && this.props.placeholderState.customPlaceholderData)
        ) {
            return;
        }

        const selectionIndex = this.props.placeholderState.customPlaceholderData.selectionIndex;

        if (this.props.setPlaceholderState) {
            this.props.setPlaceholderState({customPlaceholderData: undefined});
        }

        quill.deleteText(selectionIndex, 1);

        setTimeout(() => {
            quill.insertEmbed(
                selectionIndex,
                'placeholder-custom',
                {id: value, label: value},
                Quill.sources.USER
            );

            quill.focus();
            quill.setSelection(selectionIndex + 1, 0);
        });
    };
}
