/* @flow */

import * as React from 'react';

import {AddingItemContainer} from '../adding-item-container';
import type {ChannelType} from '../../channels/types';
import {ChannelPicker} from '../../channels/channel-picker';

type Props = {
    onClickRemove: () => void,
    onSelectChannel: (channel: ChannelType) => void,
    onBlur: () => void,
    existingChannels?: ChannelType[],
};

export function AddChannelSelect(props: Props) {
    const {onClickRemove, onBlur, onSelectChannel, existingChannels} = props;

    return (
        <AddingItemContainer onClickRemove={onClickRemove} sectionEntity='sources'>
            <ChannelPicker
                onSelectChannel={onSelectChannel}
                onBlur={onBlur}
                existingChannels={existingChannels}
            />
        </AddingItemContainer>
    );
}
