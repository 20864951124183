/* @flow */

import * as React from 'react';
import {Link as RoutedLink} from 'react-router-dom';

import {Link} from 'shells/link/link';

import {colors} from '../../colors';

import './view-tab-link.css';

type Props = {
    Icon?: React.ComponentType<*> | string,
    label: ?string,
    isActive: boolean,
    size?: number,
    href: string,
};

export function ViewTabLink(props: Props) {
    const {Icon, label, isActive, size, href} = props;

    const iconColor = isActive ? colors.greyDk : colors.greyLt;
    const iconSize = size ? size : '17';

    return (
        <div styleName={isActive ? 'view-tab-active' : 'view-tab'}>
            <Link
                as={RoutedLink}
                variant='secondary'
                to={href}
                preventUnderline={true}
                className='flex'
            >
                <div styleName='icon' style={{color: iconColor}}>
                    {Icon ? (
                        <Icon height={`${iconSize}px`} size={iconSize} fill={iconColor} />
                    ) : undefined}
                </div>
                {label ? <span styleName='label'>{label}</span> : undefined}
            </Link>
        </div>
    );
}
