/* @flow */

export const LAST_UPDATED_VALUE_OPTIONS = [
    'Last 7 days',
    'Last 30 days',
    'Last 90 days',
    'Last 365 days',
    'Last week',
    'Last month',
    'All time',
];

export const SORT_VALUE_OPTIONS = [
    {
        id: 'MODIFIED_TIME_DESC',
        label: 'Sort: Most recently updated',
    },
    {
        id: 'MODIFIED_TIME_ASC',
        label: 'Sort: Least recently updated',
    },
    {
        id: 'NAME_ASC',
        label: 'Sort: Name A-Z',
    },
    {
        id: 'NAME_DESC',
        label: 'Sort: Name Z-A',
    },
];
