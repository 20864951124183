/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {MergeFieldsButton, type MergeField} from '../merge-fields';
import type {Props as TextFieldProps} from './textfield';
import {TextField} from './textfield';
import {CharacterLimit} from './character-limit';

import './textfield-with-character-limit.css';

type Props = {
    ...TextFieldProps,
    id?: string,
    maximumCharacters: number,
    minimumCharacters?: number,
    isDisabled?: boolean,
    mergeFieldOptions?: MergeField[],
    hasNoSideBorders?: boolean,
};

export function TextfieldWithCharacterLimit(props: Props) {
    const {
        mergeFieldOptions,
        maximumCharacters,
        minimumCharacters,
        isDisabled,
        onChange,
        value,
        hasNoSideBorders = true,
        ...textFieldProps
    } = props;

    const [isFocused, setIsFocused] = React.useState(false);

    const textFieldRef = React.useRef<?HTMLInputElement>(null);

    const handleAddMergeField = (mergeFieldValue: string, mergeFieldFallbackText: ?string) => {
        if (onChange) {
            const mergeFieldToInsert = mergeFieldFallbackText
                ? `{${mergeFieldValue}|"${mergeFieldFallbackText}"}`
                : `{${mergeFieldValue}}`;

            if (textFieldRef && textFieldRef.current) {
                const textField = textFieldRef.current;

                const cursorPosition = textField.selectionStart;
                textField.setRangeText(mergeFieldToInsert, cursorPosition, cursorPosition);
                onChange(textField.value);

                const newCursorPosition = cursorPosition + mergeFieldToInsert.length;
                textField.setSelectionRange(newCursorPosition, newCursorPosition);
                textField.focus();

                return;
            }

            // Fallback for when we don't have a ref to the textfield
            onChange(`${value}${mergeFieldToInsert}`);
        }
    };

    const styleName = classnames({
        'textfield-with-character-limit': !hasNoSideBorders,
        'textfield-with-character-limit--no-side-borders': hasNoSideBorders,
    });

    return (
        <div styleName={styleName}>
            <TextField
                multiline={true}
                noBorder={true}
                disabled={isDisabled}
                value={value}
                onChange={onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                {...textFieldProps}
                textFieldRef={textFieldRef}
            />
            {/* If there are merge field options, always show footer (unless disabled) */}
            {(isFocused || mergeFieldOptions) && !isDisabled ? (
                <div styleName='character-limit'>
                    <CharacterLimit
                        maximumCharacters={maximumCharacters}
                        minimumCharacters={minimumCharacters}
                        length={value.length}
                    />
                    {mergeFieldOptions && (
                        <MergeFieldsButton
                            onAddMergeField={handleAddMergeField}
                            options={mergeFieldOptions}
                        />
                    )}
                </div>
            ) : (
                /* Prevents height jumping when showing character limit */
                <div styleName={isDisabled ? 'character-limit--disabled' : 'character-limit'}>
                    &nbsp;
                </div>
            )}
        </div>
    );
}
