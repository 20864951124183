/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ChevronDownSvg from './icon-chevron-down.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ChevronDownSvg {...validProps} />;
}
SvgComponent.iconName = 'chevronDown';

export const ChevronDown = svgIconWrapper(SvgComponent);
