/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {AccentBorder} from 'shells/accent-border';
import {Card} from 'shells/card';
import {RemoveButton} from 'shells/button';
import {colors} from 'shells/colors';
import {LoadingText} from 'shells/loading-placeholder/loading-text';

type Props = {
    name: string,
    onRemove?: () => void,
    isLoading?: boolean,
    borderColor?: string,
    onClick?: () => void,
    isDisabled?: boolean,
};

export function SelectedFieldCard(props: Props) {
    const classNames = classnames({
        disabled: props.isDisabled,
        'pad-8': true,
        flex: true,
        'justify-sb': true,
        'align-center': true,
        'font-size-normal': true,
    });

    return (
        <div className='pb-8'>
            <Card hasBorder={true} onClick={props.onClick}>
                <AccentBorder location='left' color={props.borderColor || colors.lead}>
                    <div className={classNames}>
                        {props.isLoading ? (
                            <LoadingText height={27} />
                        ) : (
                            <>
                                {props.name}
                                {props.onRemove ? (
                                    <RemoveButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // $FlowIgnore checked above
                                            props.onRemove();
                                        }}
                                        size={12}
                                    />
                                ) : null}
                            </>
                        )}
                    </div>
                </AccentBorder>
            </Card>
        </div>
    );
}
