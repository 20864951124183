import PropTypes from 'prop-types';
import * as React from 'react';
import {TimesIcon} from 'shells/icon';
import getClassesMixin from '../mixins/get-classes';
import FetchedBackboneModel from './fetched-backbone-model';

/*
 * This could _theoretically_ be used by popover forms, for entities.
 *
 * For the moment it's not being used in the app.  But we shouldn't delete it just yet until
 * we rework the popover forms entirely.
 */

export class EntitySelectedItem extends React.Component {
    getClasses = getClassesMixin;

    static propTypes = {
        id: PropTypes.string,
        onRemove: PropTypes.func.isRequired,
    };

    render() {
        const classes = this.getClasses('ui-selected-item ui-selected-item--entity');

        return (
            <div className={classes}>
                <TimesIcon className='ui-btn__close' onClick={() => this.props.onRemove()} />
                <FetchedBackboneModel id={this.props.id} />
            </div>
        );
    }
}
