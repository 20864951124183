import * as React from 'react';
import PropTypes from 'prop-types';
import {withFormsy, propTypes as formsyPropTypes} from 'formsy-react';

import {Select} from 'shells/select';

class FormSelectComponent extends React.Component {
    static propTypes = {
        selectValues: PropTypes.array.isRequired,
        onChange: PropTypes.func,
        styleOptions: PropTypes.object,
        ...formsyPropTypes,
    };

    static defaultProps = {
        onChange: () => {},
    };

    render() {
        const formsyValue = this.props.getValue();
        const selectValue = this.props.selectValues.find((val) => {
            return val.id === formsyValue;
        });

        return (
            <Select
                selectValues={this.props.selectValues}
                styleOptions={this.props.styleOptions}
                value={selectValue}
                onChange={this.handleChange}
            />
        );
    }

    handleChange = (value) => {
        this.props.setValue(value.id);
        this.props.onChange(value.id);
    };
}

export const FormSelect = withFormsy(FormSelectComponent);
