/* @flow */

import * as React from 'react';
import {LoadingIcon} from 'shells/icon';
import {colors} from 'shells/colors';
import './loading-view.css';

export class LoadingView extends React.Component<{}> {
    render() {
        return (
            <div styleName='loading-view'>
                <h2 styleName='heading'>Searching…</h2>
                <div styleName='icon'>
                    <LoadingIcon fill={colors.offWhiteDk} />
                </div>
            </div>
        );
    }
}
