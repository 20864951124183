/* @flow */

import React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';

import UpgradeArrowNewNav from './icon-upgrade-arrow-new-nav.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <UpgradeArrowNewNav {...validProps} />;
}
SvgComponent.iconName = 'upgradeArrowNewNav';

export const UpgradeArrowIconNewNav = svgIconWrapper(SvgComponent);
