/* @flow */

import * as React from 'react';

import {Stack} from '../layout';
import {StatusBadge, type StatusBadgeProps} from '../status-badge/status-badge';

import './status-bar.css';

export type StatusBarProps = {
    isLoading?: boolean,
    status?: ?StatusBadgeProps,
    primaryText: ?string | React.Node,
    secondaryText?: string | React.Node,
};

export function StatusBar(props: StatusBarProps) {
    if (props.isLoading) {
        return null;
    }

    return (
        <Stack isHorizontal={true} spacing={16} alignCenter={true}>
            {props.status ? <StatusBadge {...props.status} /> : undefined}
            <div className='flex flex-dir-col'>
                <div styleName='primary-text'>{props.primaryText}</div>
                {props.secondaryText ? (
                    <div styleName='secondary-text'>{props.secondaryText}</div>
                ) : undefined}
            </div>
        </Stack>
    );
}
