/* @flow */

import * as React from 'react';

import {SavedSettingToast} from '../saved-setting-toast';
import {LoadingPage} from '../loading-page';
import {Card} from './card';

import './card-content-with-header.css';

type Props = {
    title: string,
    subtitle: string | React.Node,
    isLoading?: boolean,
    content: React.Node,
    banner?: React.Node,
    hasBorder?: boolean,
    fitContent?: boolean,
    saveToastState?: 'saving' | 'complete' | 'initial',
};

export function CardContentWithHeader(props: Props) {
    const {
        title,
        subtitle,
        isLoading,
        content,
        banner,
        hasBorder = true,
        fitContent = true,
        saveToastState = 'initial',
    } = props;

    return (
        <Card hasBorder={hasBorder} fitContent={fitContent}>
            <div className='pad-16'>
                <div styleName='header'>
                    <SavedSettingToast state={saveToastState}>
                        <div styleName='title'>{title}</div>
                    </SavedSettingToast>
                    {banner}
                    <div styleName='subtitle'>{subtitle}</div>
                </div>
                {isLoading ? <LoadingPage padding={0} /> : content}
            </div>
        </Card>
    );
}
