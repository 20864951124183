/* @flow */

import * as React from 'react';

import {ViewTabsRadioGroup} from './view-tabs-radio/view-tabs-radio-group';
import {ViewTabsLinks} from './view-tabs-links/view-tabs-links';

type Props = {
    tabs: {|
        Icon?: React.ComponentType<*> | string,
        label: ?string,
        isActive: boolean,
        size?: number,
        id: string,
        href?: string,
    |}[],
    currentView: string,
    onChange?: (string) => any,
    isWide?: boolean,
};

export function ViewTabs(props: Props) {
    if (props.onChange && typeof props.onChange === 'function') {
        return (
            <ViewTabsRadioGroup
                tabs={props.tabs}
                currentView={props.currentView}
                onChange={props.onChange}
                isWide={props.isWide}
            />
        );
    }

    return <ViewTabsLinks tabs={props.tabs} currentView={props.currentView} />;
}
