/* @flow */
import {useQuery} from '@apollo/react-hooks';
import type {
    GetContactFormValidation as GetContactFormValidationQuery,
    GetContactFormValidationVariables,
    ContactCreateValidationInput,
    GetContactFormValidation_contactCreateValidation as ContactCreateValidation,
} from 'nutshell-graphql-types';
import {makeDataSafe, safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import GET_CONTACT_FORM_VALIDATION from '../queries/get-contact-form-validation.graphql';

export const useGetContactFormValidation = (
    input?: ContactCreateValidationInput
): ({
    contactCreateValidation: ?ContactCreateValidation,
    isLoading: boolean,
    error: ?string,
    refetch: (input: ContactCreateValidationInput) => Promise<*>,
}) => {
    const {
        data,
        error,
        loading: isLoading,
        refetch,
    } = useQuery<GetContactFormValidationQuery, GetContactFormValidationVariables>(
        GET_CONTACT_FORM_VALIDATION,
        {
            variables: {
                // $FlowIgnore covered by skip
                input,
            },
            skip: !input,
        }
    );

    const {contactCreateValidation} = makeDataSafe(data);

    return {
        error: safelyGetGraphQLErrorMessage(error),
        isLoading,
        contactCreateValidation,
        refetch: (newInput: ContactCreateValidationInput) => refetch({input: newInput}),
    };
};
